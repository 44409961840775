/* eslint-disable react/no-did-update-set-state */
/* eslint-disable import/no-cycle */
import React from "react";
import StaticLabel from "components/customs/static-label-wrapper";
import { connect } from "react-redux";
import VerticalLayout from "../../layouts/VerticalLayout";
import FullLayout from "../../layouts/FullpageLayout";
import HorizontalLayout from "../../layouts/HorizontalLayout";
import { changeSiteDirection } from "../helpers";
import { changeLayout } from "../../redux/actions/customizer/index";
import themeConfig, { themeConfigDefault } from "../../configs/themeConfig";

const layouts = {
    vertical: VerticalLayout,
    full: FullLayout,
    horizontal: HorizontalLayout,
};

const ContextLayout = React.createContext();

export const texts = {
    ThemeSavedSuccessMessage: <StaticLabel id="ThemeSavedSuccessMessage" />,
    // "Theme Configuration Updated Successfully",
    ThemeCustomizerTitle: <StaticLabel id="ThemeCustomizerTitle" />,
    // "Theme Customizer",
    ThemeCustomizerSubtitle: <StaticLabel id="ThemeCustomizerSubtitle" />,
    // "Customize & Preview in Real Time",
    MenuColorTitle: <StaticLabel id="MenuColorTitle" />,
    // "Menu Colors",
    AccentColorTitle: <StaticLabel id="AccentColorTitle" />,
    //  "Accent Colors",
    ThemeLayoutTitle: <StaticLabel id="ThemeLayoutTitle" />,
    // "Theme Layout",
    ThemeModeTitle: <StaticLabel id="ThemeModeTitle" />,
    // "Theme Mode",
    NavbarColorTitle: <StaticLabel id="NavbarColorTitle" />,
    //  "Navbar Colors",
    NavbarTypeTitle: <StaticLabel id="NavbarTypeTitle" />,
    // "Navbar Type",
    FooterTypeTitle: <StaticLabel id="FooterTypeTitle" />,
    // "Footer Type",
    RTLTitle: <StaticLabel id="RTLTitle" />,
    //  "RTL",
    HideScrollToTopTitle: <StaticLabel id="HideScrollToTopTitle" />,
    // "Hide Scroll To Top",
    ApplyChangesBtn: <StaticLabel id="ApplyChangesBtn" />,
    // "Apply Changes",
};

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...themeConfigDefault,
            activeLayout: this.props.customizer.layout,
            width: window.innerWidth,
            lastLayout: null,
            direction: themeConfig.direction,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.customizer?.layout === "vertical") {
            if (prevProps.customizer.layout !== this.props.customizer.layout) {
                this.setState({ activeLayout: "vertical" });
            }
        } else if (this.props.customizer?.layout === "horizontal") {
            if (prevProps.customizer.layout !== this.props.customizer.layout) {
                this.setState({ activeLayout: "horizontal" });
            }
        }

        if (this.props.customizer.direction === "rtl") {
            if (
                prevProps.customizer.direction !==
                this.props.customizer.direction
            ) {
                this.setState({ direction: "rtl" });
            }
        } else if (this.props.customizer.direction === "ltr") {
            if (
                prevProps.customizer.direction !==
                this.props.customizer.direction
            ) {
                this.setState({ direction: "ltr" });
            }
        }
    }

    updateWidth = () => {
        this.setState({
            width: window.innerWidth,
        });
    };

    handleWindowResize = () => {
        this.updateWidth();

        if (
            this.state.activeLayout === "horizontal" &&
            this.state.width <= 1199
        ) {
            this.setState({
                activeLayout: "vertical",
                lastLayout: "horizontal",
            });
        }

        if (
            this.state.lastLayout === "horizontal" &&
            this.state.width >= 1199
        ) {
            this.setState({
                activeLayout: "horizontal",
                lastLayout: "vertical",
            });
        }
    };

    componentDidMount = () => {
        if (window !== "undefined") {
            window.addEventListener("resize", this.handleWindowResize);
        }

        if (
            this.props.customizer.layout === "vertical" &&
            this.state.width <= 1199
        ) {
            this.setState({
                activeLayout: "vertical",
            });
        } else if (
            this.props.customizer.layout === "horizontal" &&
            this.state.width >= 1200
        ) {
            this.setState({
                activeLayout: "horizontal",
            });
        } else {
            this.setState({
                activeLayout: "vertical",
            });
        }

        // set site direction on mount
        if (this.props.customizer.direction === "rtl") {
            this.setState({ direction: "rtl" }, () => {
                changeSiteDirection(this.state.direction);
            });
        } else if (this.props.customizer.direction === "ltr") {
            this.setState({ direction: "ltr" }, () => {
                changeSiteDirection(this.state.direction);
            });
        }
    };

    handleDirUpdate = () => {
        const dir = this.state.direction;

        if (dir === "rtl") {
            document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
        } else {
            document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
        }
    };

    render() {
        const { children } = this.props;

        return (
            <ContextLayout.Provider
                value={{
                    state: this.state,
                    fullLayout: layouts.full,
                    VerticalLayout: layouts.vertical,
                    horizontalLayout: layouts.horizontal,
                    switchLayout: (layout) => {
                        this.setState({ activeLayout: layout });
                    },
                    switchDir: (dir) => {
                        this.setState({ direction: dir });
                    },
                }}
            >
                {children}
            </ContextLayout.Provider>
        );
    }
}

export default connect(
    (state) => {
        return { customizer: { ...state.customizer.customizer } };
    },
    {
        changeLayout,
    },
)(Layout);

// const Layout = ({ children }) => {
//     const [previousLayout, setPreviousLayout] = useState(null);
//     const [direction, setDirection] = useState();

//     const { layout } = useSelector((_state) => _state.customizer.customizer);
//     const dispatch = useDispatch();

//     const state = {
//         ...themeConfigDefault,
//         activeLayout: layout,
//         direction,
//     };

//     const payload = {
//         ...layouts,
//         state,
//         switchLayout: (layout) => {
//             dispatch(changeLayout(layout, true));
//         },
//         switchDir: (dir) => {
//             setDirection(dir);
//         },
//     };

//     const handleDirUpdate = () => {
//         const dir = direction;

//         if (dir === "rtl") {
//             document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
//         } else {
//             document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
//         }
//     };

//     const handleWindowResize = () => {
//         console.log("a", layout === "horizontal", window.innerWidth < 1199);

//         console.log(
//             "b",
//             previousLayout === "horizontal",
//             window.innerWidth > 1199,
//         );
//         console.log("c", window.innerWidth, layout);

//         if (layout === "horizontal" && window.innerWidth < 1199) {
//             console.log("b");
//             dispatch(changeLayout("vertical", false));
//             setPreviousLayout("horizontal");
//         }

//         if (previousLayout === "horizontal" && window.innerWidth > 1199) {
//             console.log("c");
//             dispatch(changeLayout("horizontal", false));
//             setPreviousLayout("vertical");
//         }
//     };

//     useEffect(() => {
//         if (window !== "undefined") {
//             window.addEventListener("resize", handleWindowResize);
//         }
//         // changeSiteDirection(direction);

//         if (layout === "vertical" && window.innerWidth <= 1199) {
//             dispatch(changeLayout("vertical"));
//         } else if (layout === "horizontal" && window.innerWidth >= 1200) {
//             dispatch(changeLayout("horizontal"));
//         } else {
//             dispatch(changeLayout("vertical"));
//         }
//     }, []);

//     useEffect(() => {
//         if (layout === "vertical") {
//             if (previousLayout !== layout) {
//                 dispatch(changeLayout("vertical"));
//             }
//         } else if (layout === "horizontal") {
//             if (previousLayout !== layout) {
//                 dispatch(changeLayout("horizontal"));
//             }
//         }
//     });

//     return (
//         <ContextLayout.Provider value={payload}>
//             {children}
//         </ContextLayout.Provider>
//     );
// };

// export default Layout;
export { ContextLayout };
