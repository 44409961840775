import React, { ElementType } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { SweetAlertProps } from "react-bootstrap-sweetalert/dist/types";
import StaticLabel from "../static-label-wrapper";

interface Props extends SweetAlertProps {
    children?: any;
}

export default function CustomWarningSweetAlert({
    title,
    onConfirm,
    show,
    children,
    showConfirm,
}: Props) {
    const CustomSweetAlertProps: SweetAlertProps = {
        warning: true,
        title: title || <StaticLabel id="WarningText" />,
        closeOnClickOutside: true,
        onConfirm,
        show,
        showConfirm,
    };

    return <SweetAlert {...CustomSweetAlertProps}>{children}</SweetAlert>;
}
