const defaultState = {
    run: false,
    steps: [],
    isLoading: false,
    tutorialID: null,
};

const joyrideReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_JOYRIDE_STATUS":
            return { ...state, run: action.run };

        case "SET_JOYRIDE_STEPS": {
            return { ...state, steps: action.steps };
        }

        case "SET_TUTORIAL_ID": {
            return { ...state, tutorialID: action.tutorialID };
        }

        case "SET_JOYRIDE_LOADING": {
            return { ...state, isLoading: action.isLoading };
        }

        default:
            return state;
    }
};

export default joyrideReducer;
