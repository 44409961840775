import React from "react"
import "../../../assets/scss/components/app-loader.scss"
import {CustomSpinner} from "../../customs";
class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100">
        <div className="loading">
			<CustomSpinner size="lg"/>
		</div>
      </div>
    )
  }
}

export default SpinnerComponent
