import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { globalService } from "services";
import { Toast } from "utility/helpers";
import { CustomButtonRipple } from "..";
import StaticLabel from "../static-label-wrapper";

interface CustomSweetAlertProps {
    title?: string;
    toBeDeletedTitle?: string;
    show?: boolean;
    onClickProceedDeleteOverride?: Function | any;
    onClickCancelDelete?: Function | any;
    onBeforeDelete: Function | any;
    onSuccessfulDelete: Function | any;
    onFailDelete?: Function | any;
    RID: string | number;
}

const CustomDeleteSweetAlert = ({
    title,
    toBeDeletedTitle = "",
    show,
    onClickProceedDeleteOverride = null,
    onClickCancelDelete,
    onBeforeDelete,
    onSuccessfulDelete,
    onFailDelete = null,
    RID,
}: CustomSweetAlertProps) => {
    const toBeRenderedTitle = title ||
        (toBeDeletedTitle && (
            <StaticLabel
                id="SweetAlertTitleDefaultTokenized"
                values={{ toBeDeletedTitle }}
            />
        )) || <StaticLabel id="SweetAlertTitleDefault" />;

    const onClickProceedDelete = () => {
        if (RID) {
            if (onBeforeDelete) onBeforeDelete();

            globalService.delete({
                data: RID,
                callback: (responseJSON: any) => {
                    let isDeleteSuccessful = false;

                    if (responseJSON.status === 200) {
                        const { data } = responseJSON;
                        const { Status } = data;

                        isDeleteSuccessful = Status?.IsSuccess;

                        if (Status?.IsSuccess) {
                            if (onSuccessfulDelete) onSuccessfulDelete();
                        } else {
                            Toast({
                                type: 4,
                                content: Status?.Message,
                            });
                        }
                    }

                    if (!isDeleteSuccessful) {
                        if (onFailDelete) onFailDelete();
                    }
                },
            });
        }
    };

    return (
        <SweetAlert
            warning
            title={toBeRenderedTitle}
            onConfirm={() => {}}
            onCancel={onClickCancelDelete}
            showConfirm={false}
            closeOnClickOutside
            show={show}
        >
            <div className="d-flex flex-wrap justify-content-around">
                <CustomButtonRipple
                    onClick={
                        onClickProceedDeleteOverride || onClickProceedDelete
                    }
                >
                    <StaticLabel id="ButtonConfirm" />
                </CustomButtonRipple>
                <CustomButtonRipple
                    onClick={onClickCancelDelete}
                    color="danger"
                >
                    <StaticLabel id="ButtonCancel" />
                </CustomButtonRipple>
            </div>
        </SweetAlert>
    );
};

export default CustomDeleteSweetAlert;
