/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable consistent-return */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Badge } from "reactstrap";
import { ChevronRight } from "react-feather";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import cls from "views/pages/authentication/auth.module.scss";
import Logo from "assets/img/logo/logo.svg";
import StaticLabel from "components/customs/static-label-wrapper";
import navigationConfig from "../../../../../configs/navigationConfig";
import SideMenuGroup from "./SideMenuGroup";
import { history } from "../../../../../history";
import { partnerDetails, userMenu } from "../../../../../configs/config";
import "../../../../../assets/scss/custom.scss";

class SideMenuContent extends React.Component {
    constructor(props) {
        super(props);

        this.parentArr = [];

        this.collapsedPath = null;

        this.redirectUnauthorized = () => {
            history.push("/misc/not-authorized");
        };
        this.userMenu = userMenu();

        this.navigationConfig = navigationConfig.filter((_navigationConfig) => {
            return this.userMenu.find(
                (userMenu) =>
                    userMenu.ApplicationFunctionID == _navigationConfig.id,
            );
        });
    }

    state = {
        flag: true,
        isHovered: false,
        activeGroups: [],
        currentActiveGroup: [],
        tempArr: [],
    };

    handleGroupClick = (id, parent = null, type = "") => {
        let open_group = this.state.activeGroups;
        let active_group = this.state.currentActiveGroup;

        let temp_arr = this.state.tempArr;

        // Active group to apply sidebar-group-active class
        if (type === "item" && parent === null) {
            active_group = [];
            temp_arr = [];
        } else if (type === "item" && parent !== null) {
            active_group = [];

            if (temp_arr.includes(parent)) {
                temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length);
            } else {
                temp_arr = [];
                temp_arr.push(parent);
            }
            active_group = temp_arr.slice(0);
        } else if (type === "collapse" && parent === null) {
            temp_arr = [];
            temp_arr.push(id);
        } else if (type === "collapse" && parent !== null) {
            if (active_group.includes(parent)) {
                temp_arr = active_group.slice(0);
            }

            if (temp_arr.includes(id)) {
                // temp_arr.splice(temp_arr.indexOf(id), 1)
                temp_arr.splice(temp_arr.indexOf(id), temp_arr.length);
            } else {
                temp_arr.push(id);
            }
        } else {
            temp_arr = [];
        }

        if (type === "collapse") {
            // If open group does not include clicked group item
            if (!open_group.includes(id)) {
                // Get unmatched items that are not in the active group
                const temp = open_group.filter((obj) => {
                    return active_group.indexOf(obj) === -1;
                });

                // Remove those unmatched items from open group
                if (temp.length > 0 && !open_group.includes(parent)) {
                    open_group = open_group.filter((obj) => {
                        return !temp.includes(obj);
                    });
                }

                if (
                    open_group.includes(parent) &&
                    active_group.includes(parent)
                ) {
                    open_group = active_group.slice(0);
                }

                // Add group item clicked in open group
                if (!open_group.includes(id)) {
                    open_group.push(id);
                }
            } else {
                // If open group includes click group item, remove it from open group
                open_group.splice(open_group.indexOf(id), 1);
            }
        }

        if (type === "item") {
            open_group = active_group.slice(0);
        }

        this.setState({
            activeGroups: open_group,
            tempArr: temp_arr,
            currentActiveGroup: active_group,
        });
    };

    initRender = (parentArr) => {
        this.setState({
            activeGroups: parentArr.slice(0),
            currentActiveGroup: parentArr.slice(0),
            flag: false,
        });
    };

    componentDidMount() {
        this.initRender(this.parentArr[0] ? this.parentArr[0] : []);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activePath !== this.props.activePath) {
            if (this.collapsedMenuPaths !== null) {
                this.props.collapsedMenuPaths(this.collapsedMenuPaths);
            }

            this.initRender(
                this.parentArr[0]
                    ? this.parentArr[this.parentArr.length - 1]
                    : [],
            );
        }
    }

    resolveLogo = () => {
        const { sidebarState, collapsed } = this.props;
        const { logo } = this.props.customizer;

        if (logo) {
            if (collapsed) {
                if (sidebarState) {
                    return (
                        <div className="poweredByContainer">
                            <p>
                                <img
                                    title="Powered by Bundr"
                                    src={Logo}
                                    style={{ height: 30, marginRight: 0 }}
                                    className="mb-0"
                                    alt=""
                                />
                            </p>
                        </div>
                    );
                }

                return (
                    <div className="poweredByContainer">
                        <p>
                            <img
                                title="Powered by Bundr"
                                src={Logo}
                                style={{ height: 30 }}
                                className="mb-0"
                                alt=""
                            />
                            <div>
                                <p
                                    style={{
                                        fontSize: "11px",
                                        fontWeight: "400",
                                        paddingTop: "12.5px",
                                    }}
                                >
                                    {"Powered by Bundr"}
                                </p>
                            </div>
                        </p>
                    </div>
                );
            }

            return (
                <div className="poweredByContainer">
                    <p>
                        <img
                            title="Powered by Bundr"
                            src={Logo}
                            style={{ height: 30 }}
                            className="mb-0"
                            alt=""
                        />
                        <div>
                            <p
                                style={{
                                    fontSize: "11px",
                                    fontWeight: "400",
                                    paddingTop: "12.5px",
                                }}
                            >
                                {"Powered by Bundr"}
                            </p>
                        </div>
                    </p>
                </div>
            );
        }
    };

    render() {
        const { customizer, sidebarState } = this.props;

        // Loop over sidebar items
        // eslint-disable-next-line
        let { activeTheme } = this.props
        const { pathname } = this.props.location;

        const menuItems = this.navigationConfig.map((item) => {
            const CustomAnchorTag = item.type === "external-link" ? "a" : Link;

            // checks if item has groupheader
            // if (item.type === "groupHeader") {
            //     return (
            //         <li
            //             className="navigation-header"
            //             key={`group-header-${item.groupTitle}`}
            //         >
            //             <span>{item.groupTitle}</span>
            //         </li>
            //     );
            // }

            if (
                item.type === "item" &&
                item.navLink &&
                item.navLink === this.props.activePath
            ) {
                this.activeFlag = true;
                // this.updateParentItems(item.id, true)
            }

            const slashSplit = this.props.activeItemState?.split("/");

            const isActiveSubmenu =
                `/${slashSplit[1]}` === item.navLink || false;

            const renderItem = (
                <li
                    className={classnames("nav-item", {
                        "has-sub": item.type === "collapse",
                        open: this.state.activeGroups.includes(item.id),
                        "sidebar-group-active":
                            this.state.currentActiveGroup.includes(item.id),
                        hover: this.props.hoverIndex === item.id,
                        active:
                            (this.props.activeItemState.replace(
                                "/:id",
                                `/${partnerDetails().PartnerID}`,
                            ) === item.navLink &&
                                item.type === "item") ||
                            (item.parentOf &&
                                item.parentOf.includes(
                                    this.props.activeItemState,
                                )) ||
                            isActiveSubmenu,
                        disabled: item.disabled,
                    })}
                    key={item.id}
                    onClick={(e) => {
                        e.stopPropagation();

                        if (item.type === "item") {
                            this.props.handleActiveItem(item.navLink);

                            this.handleGroupClick(item.id, null, item.type);

                            if (
                                this.props.deviceWidth <= 1200 &&
                                item.type === "item"
                            ) {
                                this.props.toggleMenu();
                            }
                        } else {
                            this.handleGroupClick(item.id, null, item.type);
                        }
                    }}
                >
                    <CustomAnchorTag
                        style={{ "--color": activeTheme }}
                        to={
                            item.filterBase
                                ? item.filterBase
                                : item.navLink && item.type === "item"
                                    ? item.navLink
                                    : ""
                        }
                        href={item.type === "external-link" ? item.navLink : ""}
                        className={`d-flex ${item.badgeText
                                ? "justify-content-between"
                                : "justify-content-start"
                            }`}
                        onMouseEnter={() => {
                            this.props.handleSidebarMouseEnter(item.id);
                        }}
                        onMouseLeave={() => {
                            this.props.handleSidebarMouseEnter(item.id);
                        }}
                        key={item.id}
                        onClick={(e) => {
                            // document.title = item.title;

                            return item.type === "collapse"
                                ? e.preventDefault()
                                : "";
                        }}
                        target={item.newTab ? "_blank" : undefined}
                    >
                        <div className="menu-text">
                            {item.icon}
                            <span className="menu-item menu-title">
                                <StaticLabel id={item.key} />
                            </span>
                        </div>

                        {item.badge ? (
                            <div className="menu-badge">
                                <Badge color={item.badge} className="mr-1" pill>
                                    {item.badgeText}
                                </Badge>
                            </div>
                        ) : (
                            ""
                        )}
                        {item.type === "collapse" ? (
                            <ChevronRight
                                className="menu-toggle-icon"
                                size={13}
                            />
                        ) : (
                            ""
                        )}
                    </CustomAnchorTag>
                    {item.type === "collapse" ? (
                        <SideMenuGroup
                            group={item}
                            handleGroupClick={this.handleGroupClick}
                            activeGroup={this.state.activeGroups}
                            handleActiveItem={this.props.handleActiveItem}
                            activeItemState={this.props.activeItemState}
                            handleSidebarMouseEnter={
                                this.props.handleSidebarMouseEnter
                            }
                            activePath={this.props.activePath}
                            hoverIndex={this.props.hoverIndex}
                            initRender={this.initRender}
                            parentArr={this.parentArr}
                            triggerActive={undefined}
                            currentActiveGroup={this.state.currentActiveGroup}
                            permission={this.props.permission}
                            currentUser={this.props.currentUser}
                            redirectUnauthorized={this.redirectUnauthorized}
                            collapsedMenuPaths={this.props.collapsedMenuPaths}
                            toggleMenu={this.props.toggleMenu}
                            deviceWidth={this.props.deviceWidth}
                        />
                    ) : (
                        ""
                    )}
                </li>
            );

            if (
                item.navLink &&
                item.collapsed !== undefined &&
                item.collapsed === true
            ) {
                this.collapsedPath = item.navLink;
                this.props.collapsedMenuPaths(item.navLink);
            }

            if (
                item.type === "collapse" ||
                item.type === "external-link" ||
                (item.type === "item" &&
                    item.permissions &&
                    item.permissions.includes(this.props.currentUser)) ||
                item.permissions === undefined
            ) {
                return renderItem;
            }

            if (
                item.type === "item" &&
                item.navLink === this.props.activePath &&
                !item.permissions.includes(this.props.currentUser)
            ) {
                return this.redirectUnauthorized();
            }
        });

        return (
            <>
                <div className="mb-1">{this.resolveLogo()}</div>
                {menuItems}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        customizer: state.customizer.customizer,
        logo: state.customizer.customizer.logo,
    };
};

export default withRouter(connect(mapStateToProps)(SideMenuContent));
