import { combineReducers } from "redux";
import joyrideReducer from "components/react-joyride/redux/reducers";
import promptReducer from "views/pages/partner/Prompt/redux/reducers";
import emailReducer from "./email";
import chatReducer from "./chat";
import todoReducer from "./todo";
import customizer from "./customizer";
import auth from "./auth";
import navbar from "./navbar/Index";
import dataList from "./data-list";
import mediaFileLibrary from "./media-file-library";
import { globalReducer } from "../mixin/global";

const rootReducer = combineReducers({
    prompt: promptReducer,
    joyRide: joyrideReducer,
    emailApp: emailReducer,
    todoApp: todoReducer,
    chatApp: chatReducer,
    customizer,
    auth,
    navbar,
    dataList,
    globalConfig: globalReducer,
    mediaFileLibrary,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
