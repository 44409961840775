import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import useSelector from "hooks/useSelector";
import { setLoginDetails } from "../redux/mixin/global";
import {
    accessToken,
    parentAccessToken,
    parentRefreshToken,
    partnerDetails,
    refreshToken,
    removeAuthentication,
    userDetails,
    userRoles,
} from "./config";

const Initialization = () => {
    const dispatch = useDispatch();

    const globalConfig = useSelector((_state) => _state.globalConfig);

    useEffect(() => {
        if (
            refreshToken() &&
            accessToken() &&
            Object.keys(globalConfig.UserDetails).length < 1 &&
            Object.keys(globalConfig.PartnerDetails).length < 1
        ) {
            dispatch(
                setLoginDetails({
                    PartnerDetails: partnerDetails(),
                    UserDetails: userDetails(),
                    UserRoles: userRoles(),
                    AccessToken: accessToken(),
                    RefreshToken: refreshToken(),
                    ParentAccessToken: parentAccessToken(),
                    ParentRefreshToken: parentRefreshToken(),
                }),
            );
        }
    }, []);

    return <></>;
};

export default Initialization;
