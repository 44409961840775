/* eslint-disable class-methods-use-this */
/* eslint-disable no-alert */
import { Cookies } from "react-cookie";

const SESSION_KEY = process.env.REACT_APP_SESSION_KEY;
const PARENT_SESSION_KEY = "__react_parent_session__";
const PARENT_REFRESH_KEY = "__react_parent_refresh__";
const REFRESH_KEY = process.env.REACT_APP_REFRESH_KEY;
const API_URL = process.env.REACT_APP_API_URL;
const APP_URL = process.env.REACT_APP_URL;
const SOCRATES_API_URL = process.env.REACT_APP_SOCRATES_API_URL;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const SHOW_PARTNER_BTN = process.env.REACT_APP_SHOW_PARTNER_BTN;
const TINY_EDITOR_API = process.env.REACT_APP_TINY_EDITOR;
const COUPON_URL = process.env.REACT_APP_COUPON_URL;

const USER_DETAILS_KEY = "USER_DETAILS";
const USER_MENU_KEY = "USER_MENU";
const HAS_MULTIPLE_USERS_KEY = "HAS_MULTIPLE_USERS";
const FULL_LAYOUT_PARTNERLIST_KEY = "FULL_LAYOUT_PARTNERLIST";
const USER_ROLES_KEY = "USER_ROLES";
const PROMPT_PERSIST = "PROMPT_PERSIST";
const LANGUAGE_PERSIST = "LANGUAGE_PERSIST";
const LIST_OF_SUB_USER_KEY = "LIST_OF_SUB_USERS";
const cookies = new Cookies();

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

export const MEDIA_URL = process.env.REACT_APP_MEDIA_API_URL;

export const PARTNER_DETAILS_KEY = "PARTNER_DETAILS";

/**
 * URL that ends with `"/medialibrary"`
 */
export const MEDIA_LIBRARY = `${MEDIA_URL}/medialibrary`;

/**
 * Set refresh token.
 *
 * @param {string | undefined} newToken
 * @returns {string | undefined | null} `token`
 */
const refreshToken = (newToken = undefined, remove = false) => {
    if (newToken) {
        localStorage.setItem(REFRESH_KEY, newToken);
    }

    if (remove) {
        localStorage.removeItem(REFRESH_KEY);
    }

    return localStorage.getItem(REFRESH_KEY);
    // if (newToken) {
    //     cookies.set(REFRESH_KEY, newToken, {
    //         // secure: true,
    //         // path: "/",
    //         // httpOnly: true,
    //         // domain: "https://bundr-dev.bpo.technology",
    //         // maxAge: 10000
    //     });
    // }
    // return cookies.get(REFRESH_KEY);
};

/**
 * Set access token.
 *
 * @param {string | undefined} newToken
 * @returns {string | undefined | null} `token`
 */
const accessToken = (newToken = undefined, remove = false) => {
    if (newToken) {
        localStorage.setItem(SESSION_KEY, newToken);
    }

    if (remove) {
        localStorage.removeItem(SESSION_KEY);
    }

    return localStorage.getItem(SESSION_KEY);
};

const parentAccessToken = (newToken = undefined, remove = false) => {
    if (newToken) {
        localStorage.setItem(PARENT_SESSION_KEY, newToken);
    }

    if (remove) {
        localStorage.removeItem(PARENT_SESSION_KEY);
    }

    return localStorage.getItem(PARENT_SESSION_KEY);
};

const parentRefreshToken = (newToken = undefined, remove = false) => {
    if (newToken) {
        localStorage.setItem(PARENT_REFRESH_KEY, newToken);
    }

    if (remove) {
        localStorage.removeItem(PARENT_REFRESH_KEY);
    }

    return localStorage.getItem(PARENT_REFRESH_KEY);
};

export const getAccessToken = accessToken;

const userDetails = (details, remove = false) => {
    if (details) {
        localStorage.setItem(USER_DETAILS_KEY, JSON.stringify(details));
    }

    if (remove) {
        localStorage.removeItem(USER_DETAILS_KEY);
    }

    return JSON.parse(localStorage.getItem(USER_DETAILS_KEY) || "{}");
};

const hasMultipleUsers = (hasMultiple, remove = false) => {
    if (hasMultiple !== undefined) {
        localStorage.setItem(HAS_MULTIPLE_USERS_KEY, hasMultiple);
    }

    if (remove) {
        localStorage.removeItem(HAS_MULTIPLE_USERS_KEY);
    }

    return JSON.parse(localStorage.getItem(HAS_MULTIPLE_USERS_KEY) || false);
};

const promptPersistState = (state, remove = false) => {
    if (state) {
        localStorage.setItem(PROMPT_PERSIST, JSON.stringify(state));
    }

    if (remove) {
        localStorage.removeItem(PROMPT_PERSIST);
    }

    return JSON.parse(localStorage.getItem(PROMPT_PERSIST) || "{}");
};

const languagePersistState = (state, remove = false) => {
    if (state) {
        localStorage.setItem(LANGUAGE_PERSIST, JSON.stringify(state));
    }

    if (remove) {
        localStorage.removeItem(LANGUAGE_PERSIST);
    }

    return JSON.parse(localStorage.getItem(LANGUAGE_PERSIST) || "{}");
};

const userRoles = (roles, remove = false) => {
    if (roles) {
        localStorage.setItem(USER_ROLES_KEY, JSON.stringify(roles));
    }

    if (remove) {
        localStorage.removeItem(USER_ROLES_KEY);
    }

    return JSON.parse(localStorage.getItem(USER_ROLES_KEY) || "[]");
};

const subUsers = (users, remove = false) => {
    if (users) {
        localStorage.setItem(LIST_OF_SUB_USER_KEY, JSON.stringify(users));
    }

    if (remove) {
        localStorage.removeItem(LIST_OF_SUB_USER_KEY);
    }

    return JSON.parse(localStorage.getItem(LIST_OF_SUB_USER_KEY) || "[]");
};

const userMenu = (menu, remove = false) => {
    if (menu) {
        localStorage.setItem(USER_MENU_KEY, JSON.stringify(menu));
    }

    if (remove) {
        localStorage.removeItem(USER_MENU_KEY);
    }

    return JSON.parse(localStorage.getItem(USER_MENU_KEY) || "[]");
};

const partnerDetails = (partnerDetail, remove = false) => {
    if (partnerDetail) {
        localStorage.setItem(
            PARTNER_DETAILS_KEY,
            JSON.stringify(partnerDetail),
        );
    }

    if (remove) {
        localStorage.removeItem(PARTNER_DETAILS_KEY);
    }

    return JSON.parse(localStorage.getItem(PARTNER_DETAILS_KEY) || "{}");
};

// const fullLayoutTheme = (theme, remove = false) => {
//     if (theme) {
//         localStorage.setItem(FULL_LAYOUT_THEME_KEY, JSON.stringify(theme));
//     }

//     if (remove) {
//         localStorage.removeItem(FULL_LAYOUT_THEME_KEY);
//     }

//     return JSON.parse(localStorage.getItem(FULL_LAYOUT_THEME_KEY) || "{}");
// };

const fullLayoutPartnerList = (partnerList, remove = false) => {
    if (partnerList) {
        localStorage.setItem(
            FULL_LAYOUT_PARTNERLIST_KEY,
            JSON.stringify(partnerList),
        );
    }

    if (remove) {
        localStorage.removeItem(FULL_LAYOUT_PARTNERLIST_KEY);
    }

    return JSON.parse(
        localStorage.getItem(FULL_LAYOUT_PARTNERLIST_KEY) || "[]",
    );
};

const socialMediaTypeRedirectURL = ({ name, type }) => {
    switch (type) {
        case "account":
            return `${window.location.origin}/account/?connect=${name}`;
        default:
            return "";
    }
};

const removeAuthentication = () => {
    cookies.remove(REFRESH_KEY);
    cookies.remove(SESSION_KEY);
    userDetails(null, true);
    userMenu(null, true);
    partnerDetails(null, true);
    subUsers(null, true);
    accessToken(null, true);
    refreshToken(null, true);
    parentAccessToken(null, true);
    parentRefreshToken(null, true);
    hasMultipleUsers(null, true);
};

export {
    APP_URL,
    API_URL,
    SOCRATES_API_URL,
    ENVIRONMENT,
    SHOW_PARTNER_BTN,
    TINY_EDITOR_API,
    INTERCOM_APP_ID,
    COUPON_URL,
    refreshToken,
    hasMultipleUsers,
    subUsers,
    promptPersistState,
    languagePersistState,
    accessToken,
    userDetails,
    userMenu,
    userRoles,
    partnerDetails,
    removeAuthentication,
    socialMediaTypeRedirectURL,
    fullLayoutPartnerList,
    parentAccessToken,
    parentRefreshToken,
};
