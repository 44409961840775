import React from "react"
import { CustomSpinner } from "components/customs"
import "../../../assets/scss/components/app-loader.scss"
class ComponentSpinner extends React.Component {
  render() {
    return (
      <div className="fallback-spinner">
        <div className="loading component-loader">
          <CustomSpinner size="lg"/>
        </div>
      </div>
    )
  }
}

export default ComponentSpinner
