/* eslint-disable import/no-mutable-exports */
// You can customize the theme with the help of this file
import _ from "lodash";
import { partnerDetails, userDetails } from "./config";

// Template config options
export const themeConfigDefault = {
    layout: "vertical", // options[String]: "vertical"(default), "horizontal"
    theme: "light", // options[String]: 'light'(default), 'dark', 'semi-dark'
    sidebarCollapsed: false, // options[Boolean]: true, false(default)
    navbarColor: "default", // options[String]: default / primary / success / danger / info / warning / dark
    navbarType: "floating", // options[String]: floating(default) / static / sticky / hidden
    footerType: "static", // options[String]: static(default) / sticky / hidden
    hideScrollToTop: false, // options[Boolean]: true, false(default)
    disableThemeTour: false, // options[Boolean]: true, false(default)
    accentColor: "primary", // options[String]: primary / success / danger / info / warning / dark
    menuTheme: "primary", // options[String]: primary / success / danger / info / warning / dark
    direction: "ltr", // options[String] : ltr / rtl
};

let themeConfig = { ...themeConfigDefault };

if (
    !_.isEmpty(partnerDetails()) &&
    userDetails()?.UsePersonalTheme?.Value === 0
) {
    themeConfig = {
        ...partnerDetails()?.TemplateConfiguration?.Value,
        ...{ layoutChanged: false },
    };
} else if (userDetails()?.UsePersonalTheme?.Value === 1) {
    themeConfig = {
        ...userDetails()?.TemplateConfiguration?.Value,
        ...{ layoutChanged: false },
    };
}

export default themeConfig;
