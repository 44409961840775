import React from "react";
import StaticLabel from "components/customs/static-label-wrapper";
import useSelector from "hooks/useSelector";
import { useDispatch } from "react-redux";
import { changeNavbarType } from "redux/actions/customizer";
import Radio from "../radio/RadioVuexy";

export default function NavbarType() {
    const { navbarType } = useSelector((state) => state.customizer.customizer);

    const dispatch = useDispatch();

    return (
        <div id="customizer-navbar-type" className="navbar-type">
            <h5>
                <StaticLabel id="NavbarTypeTitle" />
            </h5>
            <div className="d-inline-block mr-1">
                <Radio
                    label={
                        // "Static"
                        <StaticLabel id="RadioStaticNavbar" />
                    }
                    color="primary"
                    checked={navbarType === "static"}
                    name="navbarType"
                    onChange={() => dispatch(changeNavbarType("static"))}
                />
            </div>
            <div className="d-inline-block mr-1">
                <Radio
                    label={
                        // "Sticky"
                        <StaticLabel id="RadioStickyNavbar" />
                    }
                    color="primary"
                    checked={navbarType === "sticky"}
                    name="navbarType"
                    onChange={() => dispatch(changeNavbarType("sticky"))}
                />
            </div>
            <div className="d-inline-block">
                <Radio
                    label={
                        // "Floating"
                        <StaticLabel id="RadioFloatingNavbar" />
                    }
                    color="primary"
                    checked={
                        navbarType === "floating" ||
                        !["floating", "hidden", "static", "sticky"].includes(
                            navbarType,
                        )
                    }
                    name="navbarType"
                    onChange={() => dispatch(changeNavbarType("floating"))}
                />
            </div>
        </div>
    );
}
