const initialState = {
    data: [],
    mediaFiles: [],
    selectedMediaFiles: [],
};

const MediaFileLibrary = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_SELECTED_MEDIA_FILES":
            // @ts-ignore
            return {
                ...state,
                selectedMediaFiles: [
                    ...state.selectedMediaFiles,
                    action.payload,
                ],
            };

        case "RESET_SELECTED_MEDIA_FILES":
            // @ts-ignore
            return {
                ...state,
                selectedMediaFiles: [],
            };

        case "SELECTED_MEDIA_FILE":
            // @ts-ignore
            return {
                ...state,
                selectedMediaFiles: [action.payload],
            };

        case "LIST_MEDIA_FILES":
            return { ...state, mediaFiles: [...action.payload] };

        case "UPDATE_LIST_MEDIA_FILES":
            if (Array.isArray(action.payload)) {
                return {
                    ...state,
                    mediaFiles: [...state.mediaFiles, ...[action.payload]],
                };
            }

            return {
                ...state,
                mediaFiles: [...[action.payload], ...state.mediaFiles],
            };

        default:
            return state;
    }
};

export default MediaFileLibrary;

// const "REMOVE_ALL_SELECTED_MEDIA_FILES":
// return
// const "LIST_MEDIA_FILES_PAGINATION":
// return
// const "LIST_MEDIA_FILES_METADATA":
// return
// const "SORT_SELECTED_MEDIA_FILES":
// return
