const changeJoyrideStatus = (run) => {
    return (dispatch) => dispatch({ type: "SET_JOYRIDE_STATUS", run });
};

const setJoyrideSteps = (steps) => {
    return (dispatch) => dispatch({ type: "SET_JOYRIDE_STEPS", steps });
};

const setTutorialID = (tutorialID) => {
    return (dispatch) => dispatch({ type: "SET_TUTORIAL_ID", tutorialID });
};

const setLoadingJoyride = (isLoading) => {
    return (dispatch) => dispatch({ type: "SET_JOYRIDE_LOADING", isLoading });
};

export {
    changeJoyrideStatus,
    setJoyrideSteps,
    setTutorialID,
    setLoadingJoyride,
};
