/* eslint-disable no-shadow */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { createContext, useContext, useState } from "react";
import {
    Row,
    Col,
    FormGroup,
    Form,
    Spinner,
    UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Mail, Lock, Check, Eye } from "react-feather";
import Checkbox from "components/@vuexy/checkbox/CheckboxesVuexy";
import "assets/scss/pages/authentication.scss";
import { deleteAllCookies, genericQuery, Toast } from "utility/helpers";
import {
    accessToken,
    hasMultipleUsers,
    partnerDetails,
    refreshToken,
    userDetails,
    userMenu,
    userRoles,
} from "configs/config";
import LoginSVG from "assets/img/pages/login/login-svg";
import LogoSVG from "assets/img/logo/logo-svg";
import { useHexCode } from "hooks/useHexCode";
import { CustomBasicInput, CustomButtonRipple } from "components/customs";
import StaticLabel from "components/customs/static-label-wrapper";
import { useIntl } from "react-intl";
import i18nText from "i18n/en-us.json";
import UnauthorizedLaguageSelector from "components/select-lang/UnauthorizedLaguageSelector";
import MultiAccount from "components/multi-account";
import { overideNotAuthorizedPage, setTokens } from "redux/mixin/global";
import cls from "./auth.module.scss";

export const LoginContext = createContext({});

const LoginProvider = ({ children }) => {
    const [loggedInUser, setLoggedInUser] = useState();
    const [hasMultipleAccount, setHasMultipleAccounts] = useState(false);

    const payload = {
        loggedInUser,
        setLoggedInUser,
        hasMultipleAccount,
        setHasMultipleAccounts,
    };

    return (
        <LoginContext.Provider value={payload}>
            {children}
        </LoginContext.Provider>
    );
};

const Login = () => {
    return (
        <LoginProvider>
            <Main />
        </LoginProvider>
    );
};

const Main = () => {
    const { FullLayoutTheme } = useSelector((state) => state.globalConfig);

    const { hasMultipleAccount } = useContext(LoginContext);

    const renderAccountManager = () => {
        if (hasMultipleAccount) {
            return <MultiAccount overrideSameAccountLogin />;
        }

        return <LoginForm />;
    };

    return (
        <Row className={`m-0  ${cls.authInner}`}>
            <a href="#" target="_self" className={`${cls.brandLogo}`}>
                {FullLayoutTheme?.logo && (
                    <img
                        src={FullLayoutTheme?.logo}
                        className={cls.imageLogoContainer}
                        alt="loginImg"
                    />
                )}
                {".\r"}
                <LogoSVG
                    color={useHexCode(
                        FullLayoutTheme?.accentColor || "primary",
                    )}
                />
            </a>
            <Col
                className={`d-none d-lg-flex align-items-center p-5 col-lg-8 ${cls.loginContainer}`}
            >
                <div className="bg-authentication login-card rounded-0 mb-0 w-100">
                    <div
                        lg="6"
                        className="d-lg-block d-none text-center align-self-center px-1 py-0"
                    >
                        <LoginSVG
                            color={
                                useHexCode(
                                    FullLayoutTheme?.accentColor || "primary",
                                ) || "#7367f0"
                            }
                        />
                    </div>
                </div>
            </Col>
            <Col
                className={`align-items-center auth-bg px-2 p-lg-3 col-lg-4 ${cls.authBg}`}
            >
                <Col className="d-flex justify-content-end">
                    <UnauthorizedLaguageSelector />
                </Col>
                <Col className="d-flex h-100 align-items-center">
                    <div
                        className={`px-xl-2 mx-auto col-sm-8 col-md-6 col-lg-12 ${cls.formContainer}`}
                    >
                        {renderAccountManager()}
                    </div>
                </Col>
            </Col>
        </Row>
    );
};

const handleLogin = ({
    formData,
    callback,
    AccessToken,
    RefreshToken,
    handleSubmitButtonStatus,
}) => {
    moduleQuery({
        type: 1,
        Token: AccessToken,
        Refresh: RefreshToken,
        formData,
        callBack: (responseJSON) => {
            try {
                if (responseJSON?.status || responseJSON?.status === 200) {
                    const { data } = responseJSON;
                    const { Status, Data } = data;

                    if (Status?.IsSuccess) {
                        const {
                            AccessToken,
                            HasMultipleUsers,
                            RefreshToken,
                            UserDetails,
                            Menu,
                            PartnerDetails,
                            Roles,
                        } = Data;

                        deleteAllCookies();

                        accessToken(AccessToken);
                        refreshToken(RefreshToken);

                        if (HasMultipleUsers) {
                            userDetails(UserDetails);
                            hasMultipleUsers(true);
                        } else {
                            userDetails(UserDetails);
                            userMenu(Menu);
                            partnerDetails(PartnerDetails);
                            userRoles(Roles);
                            hasMultipleUsers(false);
                            // window.location = "/"; must use everytime
                            window.location = "/";
                        }

                        callback(Data);
                    } else {
                        Toast({
                            type: 4,
                            content: Status?.Message,
                        });
                        throw responseJSON;
                    }
                } else {
                    throw responseJSON;
                }
            } catch (Exception) {
                handleSubmitButtonStatus(false);

                Toast({
                    type: 4,
                    content: Exception,
                });
            }
        },
    });
};

const LoginForm = () => {
    const { setLoggedInUser, setHasMultipleAccounts } =
        useContext(LoginContext);
    const intl = useIntl();

    const { FullLayoutTheme, AccessToken, RefreshToken } = useSelector(
        (state) => state.globalConfig,
    );
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [shouldRemember, setShouldRemember] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);

    const dispatch = useDispatch();

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();

        const _email = email?.trim();
        const _password = password?.trim();

        if (_email.length && _password.length) {
            formData.set("EmailAddress", email.trim());
            formData.set("Password", password.trim());
            setIsLoggingIn(true);

            handleLogin({
                formData,
                AccessToken,
                RefreshToken,
                handleSubmitButtonStatus,
                callback: ({
                    UserDetails,
                    HasMultipleUsers,
                    AccessToken,
                    RefreshToken,
                }) => {
                    setHasMultipleAccounts(HasMultipleUsers);
                    dispatch(overideNotAuthorizedPage(true));

                    dispatch(
                        setTokens({
                            RefreshToken: {
                                UpdateLocalStorage: true,
                                Token: RefreshToken,
                            },
                            AccessToken: {
                                UpdateLocalStorage: true,
                                Token: AccessToken,
                            },
                        }),
                    );
                    setIsLoggingIn(false);
                    setLoggedInUser(UserDetails);
                },
            });
        }
    };

    const handleSubmitButtonStatus = (status) => {
        setIsLoggingIn(status);
    };

    return (
        <>
            <h2 className="card-title font-weight-bold mb-1">
                {/* {"Login"} */}
                <StaticLabel id="AuthLoginPageTitle" />
            </h2>
            <p className="card-text mb-2">
                {/* {"Welcome back, please login to your account."} */}
                <StaticLabel id="AuthLoginPageSubtitle" />
            </p>
            <Form onSubmit={handleOnSubmit}>
                <FormGroup className="form-label-group position-relative has-icon-left">
                    <CustomBasicInput
                        type="email"
                        placeholder={
                            // "Email"
                            intl.formatMessage({
                                id: "EmailPlaceholder",
                                defaultMessage: i18nText.EmailPlaceholder,
                            })
                        }
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <div className="form-control-position">
                        <Mail size={15} />
                    </div>
                    {/* <Label>Email</Label> */}
                </FormGroup>
                <FormGroup className="form-label-group position-relative has-icon-left">
                    <CustomBasicInput
                        type={isShowPassword ? "text" : "password"}
                        placeholder={
                            // "Password"
                            intl.formatMessage({
                                id: "PasswordPlaceholder",
                                defaultMessage: i18nText.PasswordPlaceholder,
                            })
                        }
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    {isShowPassword ? (
                        <>
                            <div
                                id="hidePassword"
                                className="showPassword-container"
                            >
                                <Eye
                                    size={15}
                                    className="cursor-pointer"
                                    onClick={() =>
                                        setIsShowPassword(!isShowPassword)
                                    }
                                />
                            </div>{" "}
                            <UncontrolledTooltip
                                placement="top"
                                target="hidePassword"
                            >
                                {/* {"Hide Password"} */}
                                <StaticLabel id="TooltipHidePassword" />
                            </UncontrolledTooltip>{" "}
                        </>
                    ) : (
                        <>
                            <div
                                id="showPassword"
                                className="showPassword-container"
                            >
                                <Eye
                                    size={15}
                                    className="cursor-pointer"
                                    onClick={() =>
                                        setIsShowPassword(!isShowPassword)
                                    }
                                />
                            </div>
                            <UncontrolledTooltip
                                placement="top"
                                target="showPassword"
                            >
                                {/* {"Show Password"} */}
                                <StaticLabel id="TooltipShowPassword" />
                            </UncontrolledTooltip>
                        </>
                    )}

                    <div className="form-control-position">
                        <Lock size={15} />
                    </div>
                    {/* <Label>Password</Label> */}
                </FormGroup>
                <FormGroup className="d-flex justify-content-between align-items-center">
                    <Checkbox
                        color={FullLayoutTheme?.accentColor || "primary"}
                        icon={<Check className="vx-icon" size={16} />}
                        label={
                            // "Remember me"
                            <StaticLabel
                                className="text-wrap text-justify text-responsive"
                                id="AuthLoginRememberMePlaceholder"
                            />
                        }
                        checked={shouldRemember}
                        onChange={() => {
                            setShouldRemember(!shouldRemember);
                        }}
                    />
                    <div className="float-right text-right ml-1">
                        <Link
                            to="/forgot-password"
                            style={{
                                color:
                                    useHexCode(
                                        FullLayoutTheme?.accentColor ||
                                        "primary",
                                    ) || "#7367f0",
                            }}
                        >
                            {/* {"Forgot Password?\r"} */}
                            <StaticLabel
                                className="text-wrap text-justify text-responsive"
                                id="AuthLoginForgotPasswordLink"
                            />
                        </Link>
                    </div>
                </FormGroup>
                <div className="d-flex justify-content-between">
                    <CustomButtonRipple
                        color={FullLayoutTheme?.accentColor || "primary"}
                        type="submit"
                    >
                        {isLoggingIn ? (
                            <Spinner color="light" size="sm" />
                        ) : (
                            // "Login"
                            <StaticLabel id="AuthLoginBtn" />
                        )}
                    </CustomButtonRipple>
                </div>
            </Form>
        </>
    );
};

const moduleQuery = (params = {}) => {
    const { type, formData, callBack, Token, Refresh } = params;

    const config = {
        ResponseSuccessCallback:
            callBack ||
            ((responseJSON) => {
                // eslint-disable-next-line no-console
                console.log(responseJSON);
            }),
        ResponseFailCallback:
            callBack ||
            ((responseJSON) => {
                // eslint-disable-next-line no-console
                console.log(responseJSON);
            }),
    };

    switch (type) {
        case 1:
            config.Method = "POST";
            config.Data = { requestjson: formData };
            config.Url = "/login";
            config.Token = Token;
            config.Refresh = Refresh;
            break;

        default:
            break;
    }

    return genericQuery(config);
};

export default Login;
