import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Badge } from "reactstrap";
import { useSelector } from "react-redux";
import { useHexCode } from "../../../hooks/useHexCode";
import { MEDIA_URL } from "../../../configs/config";

const CustomAvatarComponent = ({
    color = "",
    className = "",
    size = "",
    badgeUp = "",
    content = "",
    icon = null,
    badgeColor = null,
    badgeText = "",
    imgHeight = null,
    imgWidth = null,
    status = null,
    mediaFileID = null,
}) => {
    const mediaWidth = 200;
    const mediaHeight = 200;

    const accentValue = useSelector(
        (state) => state.customizer.customizer.accentColor,
    );

    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
        if (mediaFileID) {
            setImageUrl(
                `${MEDIA_URL}/medialibrary/?MediaFileID=${mediaFileID}&width=${mediaWidth}&height=${mediaHeight}&crop=true`,
            );
        } else {
            setImageUrl(null);
        }
    }, [mediaFileID]);

    return (
        <div
            style={{ backgroundColor: useHexCode(accentValue) }}
            className={classnames(
                `avatar ${color ? `bg-${color}` : null}  ${className}`,
                {
                    "avatar-sm": size && size === "sm",
                    "avatar-lg": size && size === "lg",
                    "avatar-xl": size && size === "xl",
                },
            )}
        >
            {!imageUrl || false ? (
                <span
                    className={classnames("avatar-content", {
                        "position-relative": badgeUp,
                    })}
                >
                    {content || null}
                    {icon ? <div className="avatar-icon">{icon}</div> : null}
                    {badgeUp ? (
                        <Badge
                            style={{ height: imgHeight, width: imgWidth }}
                            color={badgeColor || "primary"}
                            className="badge-sm badge-up"
                            pill
                        >
                            {badgeText || "0"}
                        </Badge>
                    ) : null}
                </span>
            ) : (
                <img
                    src={imageUrl}
                    className={className}
                    alt={badgeText}
                    height={imgHeight && !size ? imgHeight : 32}
                    width={imgWidth && !size ? imgWidth : 32}
                />
            )}
            {status ? <span className={`avatar-status-${status}`} /> : null}
        </div>
    );
};

export default CustomAvatarComponent;
