import React from "react";
import { Spinner } from "reactstrap";
import PropTypes from "prop-types";

const WaypointLoading = ({ isNextPageLoading, hasNextPage, emptyTitle, isEmpty }) => (
    <div className="text-muted my-5 h-full flex text-center justify-center">
        {isNextPageLoading && (
            <>
                <p className="mr-2">{`Loading more ${emptyTitle}...`}</p>
                <Spinner />
            </>
        )}

        {!hasNextPage && isEmpty &&  <p className="mr-2">{`No more ${emptyTitle}.`}</p>}
    </div>
);

export default WaypointLoading;

WaypointLoading.propTypes = {
    isNextPageLoading: PropTypes.bool.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
};
