export const login = (state = { userRole: "admin" }, action) => {
    switch (action.type) {
        case "LOGIN": {
            return { ...state, values: action.payload }
        }
        case "LOGOUT": {
            return { ...state, values: action.payload }
        }
        default: {
            return state
        }
    }
};