import React from "react";
import CustomizerProvider from "./Context";
import Customizer from "./Main";

export default function CustomizerSidebar(props) {
    return (
        <CustomizerProvider>
            <Customizer {...props} />
        </CustomizerProvider>
    );
}
