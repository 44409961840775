import {
    partnerDetails,
    fullLayoutPartnerList,
    userDetails,
    userRoles,
    accessToken,
    refreshToken,
    parentAccessToken,
    parentRefreshToken,
} from "../../configs/config";

const globalReducer = (
    state = {
        FullLayoutTheme: {},
        PartnerList: [],
        PartnerDetails: {},
        UserDetails: {},
        UserRoles: [],
        AccessToken: null,
        RefreshToken: null,
        UserMenu: {},
        ParentAccessToken: null,
        ParentRefreshToken: null,
        OverrideNotAuthorizedPage: false,
    },
    action,
) => {
    switch (action.type) {
        case "SET_LOGIN_DETAILS": {
            const {
                PartnerDetails,
                UserDetails,
                AccessToken,
                RefreshToken,
                Roles,
                Menu,
                ParentAccessToken,
                ParentRefreshToken,
            } = action.payload;

            return {
                ...state,
                PartnerDetails,
                UserDetails,
                AccessToken,
                RefreshToken,
                UserRoles: Roles,
                UserMenu: Menu,
                ParentAccessToken,
                ParentRefreshToken,
            };
        }

        case "SET_PARTNER_DETAILS": {
            if (action.payload.UpdateLocalStorage) {
                partnerDetails(action.payload.PartnerDetails);
            }

            return {
                ...state,
                PartnerDetails: action.payload.PartnerDetails,
                FullLayoutTheme: {},
            };
        }

        case "SET_USER_DETAILS": {
            if (action.payload.UpdateLocalStorage) {
                userDetails(action.payload.UserDetails);
            }

            return {
                ...state,
                UserDetails: action.payload.UserDetails,
                FullLayoutTheme: {},
            };
        }

        case "SET_USER_ROLES": {
            if (action.payload.UpdateLocalStorage) {
                userRoles(action.payload.UserRoles);
            }

            return {
                ...state,
                UserRoles: action.payload.UserRoles,
            };
        }

        case "SET_TOKENS": {
            if (action.payload?.RefreshToken?.UpdateLocalStorage) {
                parentRefreshToken(action.payload?.RefreshToken?.Token);
            }

            if (action.payload?.AccessToken?.UpdateLocalStorage) {
                parentAccessToken(action.payload?.AccessToken?.Token);
            }

            return {
                ...state,
                AccessToken: action.payload?.AccessToken?.Token,
                RefreshToken: action.payload?.RefreshToken?.Token,
            };
        }

        case "SET_ACCESS_TOKEN": {
            if (action.payload.UpdateLocalStorage) {
                accessToken(action.payload.AccessToken);
            }

            return {
                ...state,
                AccessToken: action.payload.AccessToken,
            };
        }

        case "SET_REFRESH_TOKEN": {
            if (action.payload.UpdateLocalStorage) {
                refreshToken(action.payload.RefreshToken);
            }

            return {
                ...state,
                RefreshToken: action.payload.RefreshToken,
            };
        }

        case "SET_PARENT_TOKENS": {
            if (action.payload?.RefreshToken?.UpdateLocalStorage) {
                parentRefreshToken(action.payload?.RefreshToken?.Token);
            }

            if (action.payload?.AccessToken?.UpdateLocalStorage) {
                parentAccessToken(action.payload?.AccessToken?.Token);
            }

            return {
                ...state,
                ParentAccessToken: action.payload?.AccessToken?.Token,
                ParentRefreshToken: action.payload?.RefreshToken?.Token,
            };
        }

        case "SET_PARENT_ACCESS_TOKEN": {
            if (action.payload.UpdateLocalStorage) {
                parentAccessToken(action.payload.AccessToken);
            }

            return {
                ...state,
                ParentAccessToken: action.payload.AccessToken,
            };
        }

        case "SET_PARENT_REFRESH_TOKEN": {
            if (action.payload.UpdateLocalStorage) {
                parentRefreshToken(action.payload.RefreshToken);
            }

            return {
                ...state,
                ParentRefreshToken: action.payload.RefreshToken,
            };
        }

        case "LOGOUT": {
            partnerDetails(null, true);
            userDetails(null, true);
            userRoles(null, true);
            accessToken(null, true);
            refreshToken(null, true);

            return {
                ...state,
                PartnerDetails: {},
                UserDetails: {},
                UserRoles: [],
                AccessToken: null,
            };
        }

        case "SET_FULLLAYOUT_THEME": {
            return { ...state, FullLayoutTheme: action.payload };
        }

        case "SET_FULLLAYOUT_PARTNER_LIST": {
            if (action.payload.UpdateLocalStorage) {
                fullLayoutPartnerList(action.payload.PartnerList);
            }

            return { ...state, PartnerList: action.payload.PartnerList };
        }

        case "OVERIDE_NOT_AUTHORIZED": {
            return {
                ...state,
                OverrideNotAuthorizedPage: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

const setLoginDetails = (data) => (dispatch) => {
    dispatch({
        type: "SET_LOGIN_DETAILS",
        payload: data,
    });

    if (typeof partnerDetails()?.TemplateConfiguration?.Value === "string") {
        partnerDetails().TemplateConfiguration.Value = JSON.parse(
            partnerDetails().TemplateConfiguration.Value,
        );
    }

    if (userDetails()?.UsePersonalTheme?.Value) {
        dispatch({
            type: "LOAD_PERSONAL_THEME",
        });
    } else if (!userDetails?.UsePersonalTheme?.Value) {
        dispatch({
            type: "GET_THEME_CONFIGURATION",
        });
    }
};

const setFullLayoutTheme = (data) => (dispatch) => {
    dispatch({
        type: "SET_FULLLAYOUT_THEME",
        payload: data,
    });
};

const setFullLayoutPartnerList = (data) => (dispatch) => {
    dispatch({
        type: "SET_FULLLAYOUT_PARTNER_LIST",
        payload: data,
    });
};

const setPartnerDetails = (data) => (dispatch) => {
    dispatch({
        type: "SET_PARTNER_DETAILS",
        payload: data,
    });
};

const setUserDetails = (data) => (dispatch) => {
    dispatch({
        type: "SET_USER_DETAILS",
        payload: data,
    });
};

const setUserRoles = (data) => (dispatch) => {
    dispatch({
        type: "SET_USER_ROLES",
        payload: data,
    });
};

const setAccessToken = (data) => (dispatch) => {
    dispatch({
        type: "SET_ACCESS_TOKEN",
        payload: data,
    });
};

const setRefreshToken = (data) => (dispatch) => {
    dispatch({
        type: "SET_REFRESH_TOKEN",
        payload: data,
    });
};

const setTokens = (data) => (dispatch) => {
    dispatch({
        type: "SET_TOKENS",
        payload: data,
    });
};

const setParentTokens = (data) => (dispatch) => {
    dispatch({
        type: "SET_PARENT_TOKENS",
        payload: data,
    });
};

const overideNotAuthorizedPage = (data) => (dispatch) => {
    dispatch({
        type: "OVERIDE_NOT_AUTHORIZED",
        payload: data,
    });
};

export {
    globalReducer,
    setLoginDetails,
    setFullLayoutTheme,
    setPartnerDetails,
    setFullLayoutPartnerList,
    setUserDetails,
    setUserRoles,
    setAccessToken,
    setRefreshToken,
    setParentTokens,
    setTokens,
    overideNotAuthorizedPage,
};
