import React, { useEffect } from "react";
import StaticLabel from "components/customs/static-label-wrapper";
import { useIntl } from "react-intl";
import i18nTexts from "i18n/en-us.json";
import { Input, Button } from "reactstrap";
import { Toast } from "utility/helpers";
import useSelector from "hooks/useSelector";
import classNames from "classnames";

const CustomPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage,
    disableRowsPerPage = false,
    disableRecordCount = false,
}) => {
    const customizer = useSelector((state) => state.customizer.customizer);

    useEffect(() => {
        if (!disableRowsPerPage) {
            const selectElement = document.querySelector(
                ".custom-pagination--select",
            );
            const options = selectElement.getElementsByTagName("OPTION");
            let option = null;

            for (const element of options) {
                if (element.hasAttribute("selected")) {
                    option = element;
                }
            }

            if (option !== null && parseInt(option?.value) !== rowsPerPage) {
                option.removeAttribute("selected");

                for (const element of options) {
                    if (parseInt(element.value) === rowsPerPage) {
                        return element.setAttribute("selected", true);
                    }

                    if (parseInt(element.value) > rowsPerPage) {
                        return element.setAttribute("selected", true);
                    }
                }
            }
        }
    }, [rowsPerPage]);

    useEffect(() => {
        const input = document.querySelector(".custom-pagination--input");

        if (input && input.value !== currentPage) {
            input.value = currentPage;
        }
    }, [currentPage]);

    const intl = useIntl();

    const handleFirstPageButtonClick = () => {
        onChangePage(1);
    };

    const handleInputGoToPage = (e) => {
        const page = Number(e.target.value);
        const code = e.keyCode ? e.keyCode : e.which;

        if (code && code === 13) {
            if (page !== 0 && page <= getNumberOfPages(rowCount, rowsPerPage)) {
                onChangePage(page);
            } else {
                const input = document.querySelector(
                    ".custom-pagination--input",
                );
                input.value = currentPage;

                Toast({
                    type: 4,
                    content: <StaticLabel id="ToatMsgPageNotFound" />,
                });
            }

            return;
        }

        if (typeof page === "number" && !code) {
            if (page !== 0 && page <= getNumberOfPages(rowCount, rowsPerPage)) {
                onChangePage(page);
            } else {
                const input = document.querySelector(
                    ".custom-pagination--input",
                );
                input.value = currentPage;

                Toast({
                    type: 4,
                    content: <StaticLabel id="ToatMsgPageNotFound" />,
                });
            }
        }
    };

    const handleBackButtonClick = () => {
        onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = () => {
        onChangePage(currentPage + 1);
    };

    const handleLastPageButtonClick = () => {
        onChangePage(getNumberOfPages(rowCount, rowsPerPage));
    };

    const handleOnChangeRowsPerPage = (event) => {
        const code = event.target.value;

        if (code <= rowCount) return onChangeRowsPerPage(parseInt(code));

        if (code > rowCount && rowsPerPage < rowCount) {
            return onChangeRowsPerPage(rowCount);
        }
    };

    const customLabelDisplayedRows = () => {
        const staticLabel = intl.formatMessage({
            id: "PaginationLabelDisplayedRows",
            defaultMessage: i18nTexts.PaginationLabelDisplayedRows,
        });

        const rowMin = (currentPage - 1) * rowsPerPage + 1;

        const rowMax =
            rowCount - rowMin <= rowsPerPage
                ? rowCount - rowMin + rowMin
                : Math.min((currentPage - 1) * rowsPerPage, rowCount) +
                rowsPerPage;

        return `${rowMin}-${rowMax} ${staticLabel} ${rowCount}`;
    };

    const getNumberOfPages = (rowCount, rowsPerPage) =>
        Math.ceil(rowCount / rowsPerPage);

    return (
        <nav className="sc-lmgQwP glnkQP rdt_Pagination custom-pagination--root">
            {!disableRowsPerPage && (
                <div className="p-0 m-0 custom-pagination--rows-per-page">
                    <span className="sc-ezzafa sc-kLojOw dvHFX iehvjW custom-pagination--rows-per-page">
                        <StaticLabel id="PaginationLabelRowsPerPage" />
                    </span>
                    <div
                        className={`sc-cxNHIi fHUQtx custom-pagination--select-div ${customizer.theme}`}
                    >
                        <select
                            aria-label="Rows per page:"
                            defaultValue={10}
                            onChange={handleOnChangeRowsPerPage}
                            className="sc-iwajpm kydRHc custom-pagination--select pr-2"
                        >
                            <option value="10">{"10"}</option>
                            <option value="15">{"15"}</option>
                            <option value="20">{"20"}</option>
                            <option value="25">{"25"}</option>
                            <option value="30">{"30"}</option>
                            <option value="50">{"50"}</option>
                            <option value="100">{"100"}</option>
                        </select>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M7 10l5 5 5-5z" />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </div>
                </div>
            )}
            {!disableRecordCount && (
                <span
                    className={`sc-ezzafa sc-bYwzuL dvHFX fDDjHD custom-pagination--display-rows ${customizer.theme}`}
                >
                    {customLabelDisplayedRows()}
                </span>
            )}
            <div
                className={classNames(
                    "sc-giAqHp bYgUJS custom-pagination--nav-buttons",
                    {
                        "w-100": disableRecordCount && disableRowsPerPage,
                    },
                )}
            >
                <Button
                    id="pagination-first-page"
                    aria-label="First Page"
                    aria-disabled="true"
                    onClick={handleFirstPageButtonClick}
                    disabled={currentPage === 1}
                    className="sc-iJCRrE hCkVby"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="presentation"
                    >
                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
                        <path fill="none" d="M24 24H0V0h24v24z" />
                    </svg>
                </Button>
                <Button
                    id="pagination-previous-page"
                    type="button"
                    aria-label="Previous Page"
                    aria-disabled="true"
                    disabled={currentPage === 1}
                    className="sc-iJCRrE hCkVby"
                    onClick={handleBackButtonClick}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="presentation"
                    >
                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                </Button>
                <Input
                    className="custom-pagination--input"
                    type="number"
                    onBlur={(e) => {
                        handleInputGoToPage(e);
                    }}
                    defaultValue={currentPage}
                    onKeyDown={(e) => {
                        handleInputGoToPage(e);
                    }}
                    disabled={
                        currentPage >= getNumberOfPages(rowCount, rowsPerPage)
                    }
                />
                <Button
                    id="pagination-next-page"
                    type="button"
                    aria-label="Next Page"
                    aria-disabled="true"
                    disabled={
                        currentPage >= getNumberOfPages(rowCount, rowsPerPage)
                    }
                    className="sc-iJCRrE hCkVby"
                    onClick={handleNextButtonClick}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="presentation"
                    >
                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                </Button>
                <Button
                    id="pagination-last-page"
                    type="button"
                    aria-label="last Page"
                    aria-disabled="true"
                    disabled=""
                    className="sc-iJCRrE hCkVby"
                    onClick={handleLastPageButtonClick}
                    disabled={
                        currentPage >= getNumberOfPages(rowCount, rowsPerPage)
                    }
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="presentation"
                    >
                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z" />
                        <path fill="none" d="M0 0h24v24H0V0z" />
                    </svg>
                </Button>
            </div>
        </nav>
    );
};

export default CustomPagination;
