/* eslint-disable react/jsx-indent */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { createContext, useContext, useReducer } from "react";
import { X } from "react-feather";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ContextLayout, texts } from "utility/context/Layout";
import { CustomButtonRipple } from "components/customs";
import { useDispatch } from "react-redux";
import { Toast, convertColorToHex } from "utility/helpers";
import { partnerDetails, userDetails } from "configs/config";
import {
    changeJoyrideStatus,
    setJoyrideSteps,
    setTutorialID,
} from "components/react-joyride/redux/actions";
import StaticLabel from "components/customs/static-label-wrapper";
import useSelector from "hooks/useSelector";
import customizerService from "services/customizerService";
import CustomzierToggle from "./CustomzierToggle";
import MenuColor from "./MenuColor";
import AccentColor from "./AccentColor";
import NavbarColor from "./NavbarColor";
import ThemeLayout from "./ThemeLayout";
import ThemeMode from "./ThemeMode";
import NavbarType from "./NavbarType";
import FooterType from "./FooterType";
import RTL from "./RTL";
import HideScrollToTop from "./HideScrollToTop";

import "assets/scss/custom.scss";
import "assets/scss/components/customizer.scss";
import { CustomizerContext } from "./Context";

const Customizer = ({
    customizerState,
    handleCustomizer,
    changeMode,
    changeAccentColor,
    changeNavbarType,
    changeLayout,
    changeDirection,
    changeFooterType,
    changeNavbarColor,
    changeMenuTheme,
    accentColor,
    changeCustomizerState,
    changeOverlayState,
}) => {
    const { switchLayout, switchDir, state } = useContext(ContextLayout);
    const { activeLayout } = state;
    const dispatch = useDispatch();
    const customizer = useSelector((state) => state.customizer.customizer);

    const doc = document.getElementById("sidebar-customizer");

    if (doc) {
        doc.setAttribute(
            "style",
            `--accentColor: ${convertColorToHex(customizer?.accentColor)}`,
        );
    }

    const joyRide = useSelector((state) => state.joyRide);

    const { stateDispatch } = useContext(CustomizerContext);

    const isFranchiseeSelected = useSelector(
        (state) => state.customizer.customizer.isFranchiseeSelected,
    );

    const { partnerConfig } = useSelector(
        (state) => state.customizer.customizer,
    );

    const { PartnerDetails, UserDetails } = useSelector(
        (state) => state.globalConfig,
    );

    const updateDetailsCallback = (responseJSON) => {
        try {
            if (responseJSON.status === 200) {
                const { data } = responseJSON;
                const { Status, Data } = data;

                if (Status?.IsSuccess) {
                    if (UserDetails?.UsePersonalTheme?.Value) {
                        if (!isFranchiseeSelected) {
                            userDetails({
                                ...userDetails(),
                                TemplateConfiguration: {
                                    ...userDetails().TemplateConfiguration,
                                    Value: Data?.NewValue,
                                },
                            });
                        }

                        dispatch({
                            type: "SET_USER_DETAILS",
                            payload: {
                                UserDetails: {
                                    ...userDetails(),
                                    TemplateConfiguration: {
                                        ...userDetails().TemplateConfiguration,
                                        Value: Data?.NewValue,
                                    },
                                },
                            },
                        });
                    } else if (!UserDetails?.UsePersonalTheme?.Value) {
                        if (!isFranchiseeSelected) {
                            partnerDetails({
                                ...partnerDetails(),
                                TemplateConfiguration: {
                                    ...partnerDetails().TemplateConfiguration,
                                    Value: Data?.NewValue,
                                },
                            });
                        }

                        dispatch({
                            type: "SET_PARTNER_DETAILS",
                            payload: {
                                PartnerDetails: {
                                    ...partnerDetails(),
                                    TemplateConfiguration: {
                                        ...partnerDetails()
                                            .TemplateConfiguration,
                                        Value: Data?.NewValue,
                                    },
                                },
                            },
                        });
                    }

                    Toast({
                        type: 1,
                        content: texts.ThemeSavedSuccessMessage,
                    });
                    window.location.reload();
                } else {
                    Toast({
                        type: 4,
                        content: Status?.Message,
                    });
                }
            }
        } catch (Exception) {
            Toast({
                type: 4,
                content: Exception,
            });
        }
    };

    const handleApplyChanges = (data) => {
        customizerService.setDetails({
            data,
            setPartnerTheme: isFranchiseeSelected,
            callback: updateDetailsCallback,
        });
    };

    const revertChanges = () => {
        if (customizerState) {
            changeOverlayState(false);

            const { UsePersonalTheme } = UserDetails;
            const isPersonalTheme = UsePersonalTheme.Value;

            if (!isFranchiseeSelected) {
                if (isPersonalTheme) {
                    const { TemplateConfiguration } = UserDetails;
                    const { Value } = TemplateConfiguration;

                    const {
                        menuTheme,
                        direction,
                        accentColor,
                        layout,
                        navbarColor,
                        navbarType,
                        theme,
                        footerType,
                    } = Value;

                    changeMenuTheme(menuTheme);
                    changeDirection(direction);
                    changeAccentColor(accentColor);
                    changeLayout(layout, false);
                    changeNavbarColor(navbarColor);
                    changeNavbarType(navbarType);
                    changeMode(theme);
                    changeFooterType(footerType);

                    switchLayout(layout);
                    switchDir(direction);
                } else {
                    const { TemplateConfiguration } = PartnerDetails;
                    const { Value } = TemplateConfiguration;

                    const {
                        menuTheme,
                        direction,
                        accentColor,
                        layout,
                        navbarColor,
                        navbarType,
                        theme,
                        footerType,
                    } = Value;

                    changeMenuTheme(menuTheme);
                    changeDirection(direction);
                    changeAccentColor(accentColor);
                    changeLayout(layout, false);
                    changeNavbarColor(navbarColor);
                    changeNavbarType(navbarType);
                    changeMode(theme);
                    changeFooterType(footerType);

                    switchLayout(layout);
                    switchDir(direction);
                }
            } else if (partnerConfig) {
                const {
                    menuTheme,
                    direction,
                    accentColor,
                    layout,
                    navbarColor,
                    navbarType,
                    theme,
                    footerType,
                } = partnerConfig;
                changeMenuTheme(menuTheme);
                changeDirection(direction);
                changeAccentColor(accentColor);
                changeLayout(layout, false);
                changeNavbarColor(navbarColor);
                changeNavbarType(navbarType);
                changeMode(theme);
                changeFooterType(footerType);

                switchLayout(layout);
                switchDir(direction);
            }
        } else if (!customizerState) {
            changeOverlayState(!customizerState);
        }
    };

    const handleOnToggleClick = () => {
        handleCustomizer(!customizerState);

        stateDispatch({
            type: "SET_COLOR_PICKER_OPENED",
            payload: {
                navbarColor: false,
                menuColor: false,
                accentColor: false,
            },
        });

        changeOverlayState(!customizerState);

        changeCustomizerState(!customizerState);
        revertChanges();

        if (joyRide.tutorialID && joyRide.tutorialID === 6) {
            setJoyrideSteps([
                {
                    content: (
                        <div>
                            <StaticLabel
                                id="VirtualTour6Step4"
                                className="mb-1"
                            />
                            <StaticLabel
                                id="VirtualTour6Step5"
                                className="mb-1"
                            />
                        </div>
                    ),
                    locale: {
                        skip: (
                            <strong aria-label="skip">
                                <StaticLabel id="VirtualTourSkip" />
                            </strong>
                        ),
                    },
                    placement: "center",
                    target: "body",
                },

                {
                    content: <StaticLabel id="VirtualTour6Step6" />,

                    placement: "top",
                    target: "#customizer-apply-changes",
                },
            ]);

            setTimeout(() => {
                changeJoyrideStatus(true);
            }, 500);
            setTutorialID(null);
        }
    };

    return (
        <div
            id="sidebar-customizer"
            className={classnames("customizer d-none d-md-block", {
                open: customizerState === true,
            })}
        >
            <CustomzierToggle
                aria-hidden="true"
                color={customizer.accentColor}
                className={
                    accentColor
                        ? `customizer-toggle badge-${accentColor}`
                        : "customizer-toggle badge-primary"
                }
                style={
                    accentColor?.startsWith("#")
                        ? { backgroundColor: accentColor }
                        : {}
                }
                onClick={handleOnToggleClick}
            />
            <div className="header d-flex justify-content-between px-2 pt-2">
                <div className="title">
                    <h4 className="text-uppercase mb-0">
                        <StaticLabel id="ThemeCustomizerTitle" />
                    </h4>
                    <small>
                        <StaticLabel id="ThemeCustomizerSubtitle" />
                    </small>
                </div>
                <div
                    aria-hidden="true"
                    className="close-icon cursor-pointer"
                    onClick={() => {
                        handleCustomizer(false);
                        // setShowMenuCustomizer(false);
                        // setShowAccentCustomizer(false);

                        changeOverlayState(false);
                        changeCustomizerState(false);
                        // setShowNavCustomizer(false);

                        revertChanges();
                    }}
                >
                    <X size={24} />
                </div>
            </div>
            <hr />
            <PerfectScrollbar
                options={{ wheelPropagation: false }}
                className="customizer-content p-2"
            >
                <MenuColor />
                <hr />
                <AccentColor />
                <hr />
                <ThemeLayout />
                <hr />
                <ThemeMode />
                <hr />
                {activeLayout === "vertical" && <NavbarColor />}
                <NavbarType />
                {/* <hr /> */}
                {/* <FooterType /> */}
                <hr />
                <RTL />
                {/* <hr /> */}
                {/* <HideScrollToTop /> */}
                <hr />
                <div
                    id="customizer-apply-changes"
                    className="d-flex justify-content-center align-items-center"
                >
                    <CustomButtonRipple
                        onClick={() => handleApplyChanges(customizer)}
                    >
                        <StaticLabel id="ApplyChangesBtn" />
                    </CustomButtonRipple>
                </div>
            </PerfectScrollbar>
        </div>
    );
};

export default Customizer;
