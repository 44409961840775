import React from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Badge,
} from "reactstrap";

import { ChevronDown, ChevronRight } from "react-feather";
import { Link } from "react-router-dom";
import classnames from "classnames";
import navigationConfig from "configs/navigationConfig";
import { partnerDetails, userMenu } from "configs/config";
import { history } from "history.js";

import { connect } from "react-redux";
import StaticLabel from "components/customs/static-label-wrapper";

class HorizontalMenuContent extends React.Component {
    constructor(props) {
        super(props);
        this.parentArr = [];

        this.collapsedPath = null;

        this.redirectUnauthorized = () => {
            history.push("/misc/not-authorized");
        };
        this.userMenu = userMenu();

        this.navigationConfig = navigationConfig.filter((_navigationConfig) => {
            return this.userMenu.find(
                (userMenu) =>
                    userMenu.ApplicationFunctionID == _navigationConfig.id,
            );
        });
    }

    state = {
        flag: true,
        isHovered: false,
        activeGroups: [],
        currentActiveGroup: [],
        tempArr: [],
    };

    initRender = (parentArr) => {
        this.setState({
            activeGroups: parentArr.slice(0),
            currentActiveGroup: parentArr.slice(0),
            flag: false,
        });
    };

    componentDidMount() {
        this.initRender(this.parentArr[0] ? this.parentArr[0] : []);
        // console.log(this.props.activePath)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activePath !== this.props.activePath) {
            this.initRender(
                this.parentArr[0]
                    ? this.parentArr[this.parentArr.length - 1]
                    : [],
            );
        }
    }

    render() {
        const {
            logo,
            // visibilityState,
            // toggleSidebarMenu,
            // sidebarHover,
            // toggle,
            // color,
            // sidebarVisibility,
            // activeTheme,
            // collapsed,
            // activePath,
            // sidebarState,
            // currentLang,
            // permission,
            // currentUser,
            // collapsedMenuPaths
        } = this.props;

        const menuItems = this.navigationConfig.map((item) => {
            const CustomAnchorTag = item.type === "external-link" ? "a" : Link;
            // checks if item has groupheader
            // if (item.type === "groupHeader") {
            //     return (
            //         <li
            //             className="navigation-header"
            //             key={`group-header-${item.groupTitle}`}>
            //             <span>{item.groupTitle}</span>
            //         </li>
            //     )
            // }

            if (
                item.type === "item" &&
                item.navLink &&
                item.navLink === this.props.activePath
            ) {
                this.activeFlag = true;
                // this.updateParentItems(item.id, true)
            }

            const renderItem = (
                <li
                    className={classnames("nav-item", {
                        active:
                            this.props.activePath.replace("/:id", "") ===
                            item.navLink || item.navLink === "",
                        hover: this.state.parentHover === item.id,
                    })}
                    key={item.id}
                    ref={(el) => (this.menuDrodpown = el)}
                >
                    <div
                        className={classnames(
                            "nav-item-wrapper cursor-pointer",
                            {
                                "single-item-custom": item.type === "item",
                            },
                        )}
                        onMouseEnter={() => {
                            // this.openDropdown(item.id)
                            // this.handleParentHover(item.id)
                        }}
                        onMouseLeave={() => {
                            // this.closeDropdown(item.id)
                            // this.handleParentHover(null)
                        }}
                    >
                        {item.children ? (
                            <Dropdown
                                // isOpen={this.state.openDropdown.includes(item.id)}
                                className="nav-link"
                                toggle={() => {
                                    // this.openDropdown(item.id)
                                }}
                            >
                                <DropdownToggle
                                    className="d-flex align-items-center"
                                    tag="div"
                                >
                                    <div className="dropdown-text">
                                        <span className="menu-icon align-middle mr-75">
                                            {item.icon}
                                        </span>
                                        <span className="menu-title align-middle">
                                            {/* <FormattedMessage
                                    className="menu-title align-middle"
                                    id={item.title}
                                /> */}
                                        </span>
                                    </div>
                                    <ChevronDown
                                        className="ml-50 align-bottom"
                                        size={15}
                                    />
                                </DropdownToggle>

                                {/* {this.updateParentItems(item.id, true)} */}
                                {item.children
                                    ? this.renderSubMenu(item.children, item.id)
                                    : null}
                            </Dropdown>
                        ) : (
                            <CustomAnchorTag
                                className={classnames({
                                    "nav-link": item.type === "item",
                                    hover: this.state.parentHover === item.id,
                                })}
                                to={
                                    item.filterBase
                                        ? item.filterBase
                                        : item.navLink && item.type === "item"
                                            ? item.navLink
                                            : "#"
                                }
                                href={
                                    item.type === "external-link"
                                        ? item.navLink
                                        : undefined
                                }
                                target={item.newTab ? "_blank" : undefined}
                            >
                                <span className="menu-icon align-middle mr-75">
                                    {item.icon}
                                </span>
                                <span className="menu-title align-middle">
                                    <StaticLabel id={item.key} />
                                </span>
                            </CustomAnchorTag>
                        )}
                    </div>
                </li>
            );

            // if (
            //     item.navLink &&
            //     item.collapsed !== undefined &&
            //     item.collapsed === true
            // ) {
            //     this.collapsedPath = item.navLink
            //     this.props.collapsedMenuPaths(item.navLink)
            // }

            if (
                item.type === "collapse" ||
                item.type === "external-link" ||
                (item.type === "item" &&
                    item.permissions &&
                    item.permissions.includes(this.props.currentUser)) ||
                item.permissions === undefined
            ) {
                return renderItem;
            }

            if (
                item.type === "item" &&
                item.navLink === this.props.activePath &&
                !item.permissions.includes(this.props.currentUser)
            ) {
                return this.redirectUnauthorized();
            }
        });

        return (
            <>
                {menuItems}
                {/* {logo && (
                    <div className="poweredByContainer">
                        <img
                            title="Powered By Bundr"
                            src={Logo}
                            style={{ height: 25, marginRight: 5 }}
                            className="mb-0"
                            alt=""
                        />
                        <p>{"Powered by Bundr"}</p>
                    </div>
                )} */}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        logo: state.customizer.customizer.logo,
    };
};

export default connect(mapStateToProps)(HorizontalMenuContent);
