import React, { useContext } from "react";
import StaticLabel from "components/customs/static-label-wrapper";
import useSelector from "hooks/useSelector";
import { ContextLayout } from "utility/context/Layout";
import { useDispatch } from "react-redux";
import { changeMode } from "redux/actions/customizer";
import Radio from "../radio/RadioVuexy";

export default function ThemeMode() {
    const { theme } = useSelector((state) => state.customizer.customizer);
    const { state } = useContext(ContextLayout);
    const { activeLayout } = state;

    const dispatch = useDispatch();

    return (
        <div id="customizer-theme-mode" className="theme-mode">
            <h5 className="mt-1">
                <StaticLabel id="ThemeModeTitle" />
            </h5>
            <div className="d-inline-block mr-1">
                <Radio
                    label={
                        // "Light"
                        <StaticLabel id="RadioLightMode" />
                    }
                    color="primary"
                    checked={
                        theme === "light" ||
                        !["light", "dark", "semi-dark"].includes(theme)
                    }
                    name="themeLayout"
                    onChange={() => dispatch(changeMode("light"))}
                />
            </div>
            <div className="d-inline-block mr-1">
                <Radio
                    label={
                        // "Dark"
                        <StaticLabel id="RadioDarkMode" />
                    }
                    color="primary"
                    checked={theme === "dark"}
                    name="themeLayout"
                    onChange={() => dispatch(changeMode("dark"))}
                />
            </div>
            {activeLayout !== "horizontal" && (
                <div className="d-inline-block">
                    <Radio
                        label={
                            // "Semi Dark"
                            <StaticLabel id="RadioSemiDarkMode" />
                        }
                        color="primary"
                        checked={theme === "semi-dark"}
                        name="themeLayout"
                        onChange={() => dispatch(changeMode("semi-dark"))}
                    />
                </div>
            )}
        </div>
    );
}
