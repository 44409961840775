import { changeSiteDirection } from "utility/helpers";
import themeConfig from "../../../configs/themeConfig";
import { partnerDetails, userDetails } from "../../../configs/config";

const isDisableCustomizer = (partnerConfig) => {
    const { PartnerTypeID, RelationshipIsExclusive } =
        partnerConfig.PartnerTypeID;
    const disableCustomizerForPartnerTypeIDs = [3];

    if (PartnerTypeID !== 4) {
        if (!RelationshipIsExclusive) {
            return false;
        }
    } else {
        return disableCustomizerForPartnerTypeIDs.includes(PartnerTypeID);
    }
};

const customizerReducer = (state = themeConfig, action) => {
    switch (action.type) {
        case "LOAD_PARTNER_CONFIG": {
            const { partnerConfig } = action;
            const { TemplateConfiguration, PartnerID } = partnerConfig;
            const { Value, RID } = TemplateConfiguration;

            changeSiteDirection(Value?.direction);
            delete Value?.isOpenCustomizer;

            const detailconfig = { ...Value };
            delete detailconfig.appOverlay;

            if (state.partnerLayoutChanged || state.layoutChanged) {
                delete detailconfig.layout;
            }

            return {
                ...state,
                ...detailconfig,
                partnerConfig: { ...Value },
                RID,
                layoutChanged: detailconfig.layout !== state.layout,
                PartnerID,
                isFranchiseeSelected:
                    partnerConfig.PartnerTypeID === 2 ||
                    partnerConfig.PartnerTypeID === 4,
                disableCustomizer: isDisableCustomizer(partnerConfig),
            };
        }

        case "RESET_PARTNER_CONFIG": {
            let themeConfig;

            if (userDetails()?.UsePersonalTheme?.Value) {
                themeConfig = { ...userDetails().TemplateConfiguration.Value };
            } else {
                themeConfig = {
                    ...partnerDetails().TemplateConfiguration.Value,
                };

                if (themeConfig?.direction) {
                    changeSiteDirection(themeConfig.direction);
                }
            }

            return themeConfig || state;
        }

        case "CHANGE_CUSTOMIZER_STATE": {
            return { ...state, isOpenCustomizer: action.isOpenCustomizer };
        }

        case "CHANGE_OVERLAY_STATE": {
            return { ...state, appOverlay: action.appOverlay };
        }

        case "CHANGE_MODE": {
            return { ...state, theme: action.mode };
        }

        case "GET_THEME_CONFIGURATION": {
            changeSiteDirection(
                partnerDetails()?.TemplateConfiguration?.Value?.direction,
            );

            return {
                ...partnerDetails()?.TemplateConfiguration?.Value,
                layoutChanged: false,
            };
        }

        case "COLLAPSE_SIDEBAR": {
            return { ...state, collapsedSidebar: action.value };
        }

        case "CHANGE_NAVBAR_COLOR": {
            return { ...state, navbarColor: action.color };
        }

        case "CHANGE_DIRECTION": {
            changeSiteDirection(action.direction);

            return { ...state, direction: action.direction };
        }

        case "CHANGE_LAYOUT": {
            return {
                ...state,
                layout: action.layout,
                layoutChanged: action.layoutChanged,
            };
        }

        case "CHANGE_NAVBAR_TYPE": {
            return { ...state, navbarType: action.style };
        }

        case "CHANGE_FOOTER_TYPE": {
            return { ...state, footerType: action.style };
        }

        case "CHANGE_MENU_COLOR": {
            return { ...state, menuTheme: action.style };
        }

        case "CHANGE_ACCENT_COLOR": {
            return { ...state, accentColor: action.style };
        }

        case "REMOVE_THEME_CUSTOMIZER_CONFIG": {
            return { ...themeConfig, accentColor: "primary" };
        }

        case "HIDE_SCROLL_TO_TOP": {
            return { ...state, hideScrollToTop: action.value };
        }

        case "DISABLE_CUSTOMIZER": {
            if (state.layoutChanged) {
                return { ...state, disableCustomizer: true };
            }

            return { ...state, disableCustomizer: true };
        }

        case "ENABLE_CUSTOMIZER": {
            return { ...state, disableCustomizer: false };
        }

        case "LOAD_PERSONAL_THEME": {
            changeSiteDirection(
                userDetails().TemplateConfiguration.Value.direction,
            );

            return {
                ...userDetails().TemplateConfiguration.Value,
                layoutChanged: false,
            };
        }

        case "SET_CUSTOMIZER": {
            return { ...action.payload };
        }

        default: {
            return state;
        }
    }
};

export default customizerReducer;
