import Axios, { AxiosRequestConfig } from "axios";
import { APIResponseData, RequestJSON } from "services/apiService.types";
import { withAccessToken } from "utility/axios-middlewares";
import { Undefinable } from "utility/checkers";
import { expiredTokenRequestRefreshAndRetry } from "utility/helpers";
import { API_URL } from "../configs/config";

const apiService = Axios.create({
    baseURL: API_URL,
});

apiService.interceptors.response.use(
    (response: any) => {
        return response;
    },
    async (error) => expiredTokenRequestRefreshAndRetry(error, apiService),
);

export default apiService;

export const singleApiUrl = "/single_api";

export async function apiGet<Data = any, ResponseData = APIResponseData<Data>>(
    requestjson: RequestJSON,
    config?: AxiosRequestConfig,
    url = singleApiUrl,
) {
    return apiService
        .get<Undefinable<ResponseData>>(url, {
            ...config,
            params: {
                ...config?.params,
                requestjson,
            },
        })
        .then((res) => res.data);
}

/**
 * `name = RID.AttributeName`
 */
export async function apiUpdate<
    ResponseData = {
        Data: {
            NewValue: string;
        };
        Status: {
            IsSuccess: boolean;
            Message: String;
        };
    },
>(
    name: string,
    oldValue: string,
    newValue: string,
    config?: AxiosRequestConfig,
    url = "/update_by_rid",
) {
    const formData = new FormData();

    formData.set("RID", name);
    formData.set("OldValue", oldValue);
    formData.set("Value", newValue);

    return apiService.post<ResponseData>(url, formData, config);
}

export async function apiPost<ResponseData = any>(
    requestjson: RequestJSON,
    config?: AxiosRequestConfig | any,
    url = singleApiUrl,
) {
    const formData = new FormData();

    formData.set("requestjson", JSON.stringify(requestjson));

    return apiService.post<ResponseData>(url, formData, config);
}

export async function apiDeleteByRID<ResponseData = any>(
    referenceId: number | string,
    config?: Omit<AxiosRequestConfig, "data">,
) {
    const formData = new FormData();

    formData.set("RID", JSON.stringify(referenceId));

    return apiService.delete<ResponseData>("/delete_by_rid", {
        ...config,
        data: formData,
    });
}

apiService.interceptors.request.use(withAccessToken);
