/* eslint-disable no-console */
import React, { useReducer, createContext, useEffect } from "react";
import * as Icon from "react-feather";
import { apiUpdate } from "services/apiService";
import { genericQuery } from "utility/helpers";
import StaticLabel from "components/customs/static-label-wrapper";

export const texts = {
    BellNotificationsReadAll: <StaticLabel id="BellNotificationsReadAll" />,
    //  "Read all notifications",
};

/* 
Notification Types
1	Messaging  // N/A
2	Order Payment
3	Actual Credit
4	Franchisee Commission // N/A
5	Order Payment Failed
6	Actual Credit Failed
7	Order Account Approval
8	Order Supplier Approval
9	Order Assessment
10	Order Assessment Rejected
11	Order For Payment
*/

const getIcon = (typeId) => {
    switch (typeId) {
        case 1:
            return <Icon.PlusSquare className="font-medium-5 info" size={21} />;

        case 5:

        case 10:

        case 6:
            return (
                <Icon.AlertTriangle
                    className="font-medium-5 danger"
                    size={21}
                />
            );

        case 7:

        case 8:

        case 9:
            return (
                <Icon.AlertTriangle
                    className="font-medium-5 danger"
                    size={21}
                />
            );

        default:
            return (
                <Icon.AlertCircle className="font-medium-5 info" size={21} />
            );
    }
};

const moduleQuery = (params = {}) => {
    const { type, callBack } = params;

    const config = {
        ResponseSuccessCallback:
            callBack ||
            ((responseJSON) => {
                console.log(responseJSON);
            }),
        ResponseFailCallback:
            callBack ||
            ((responseJSON) => {
                console.log(responseJSON);
            }),
    };

    switch (type) {
        case "custom":
            config.Method = params?.Method || "GET";

            config.Data = params.Data;

            if (config.Method !== "GET") {
                config.Data = {
                    requestjson: params.Data,
                };
            }

            config.Url = `/${params.Url}`;
            break;

        case "generic":
            config.Method = "GET";

            config.Data = {
                requestjson: {
                    Module: params.Module,
                    Parameters: params.Parameters,
                },
            };
            config.Url = "/single_api";
            break;

        default:
        //
    }

    if (type) {
        genericQuery(config);
    }
};

const defaultState = {
    notifications: [],
    listOptions: {
        SearchValue: "",
        CurrentRowsPerPage: 15,
        PageNumber: 1,
    },
    isLoading: false,
    count: 0,
    countUnPreviewed: 0,
    initialized: false,
    isOpen: false,
};

const stateDispatchReducer = (state, action) => {
    switch (action.type) {
        case "update":
            return {
                ...state,
                ...action.payload,
            };

        default: {
            if (action.key) {
                if (
                    typeof action.payload === "object" &&
                    !Array.isArray(action.payload)
                ) {
                    return {
                        ...state,
                        [action.key]: {
                            ...state[action.key],
                            ...action.payload,
                        },
                    };
                }

                return {
                    ...state,
                    [action.key]: action.payload,
                };
            }

            return state;
        }
    }
};

const defaultStateFn = (key) => {
    return key ? defaultState[key] : defaultState;
};

export const DetailsContext = createContext({});

const DetailsProvider = ({ children }) => {
    const [state, stateDispatch] = useReducer(
        stateDispatchReducer,
        defaultStateFn(),
    );

    useEffect(() => {
        return () => {
            stateDispatch({
                key: "initialized",
                payload: false,
                notifications: [],
                listOptions: {
                    SearchValue: "",
                    CurrentRowsPerPage: 15,
                    PageNumber: 1,
                },
                isLoading: false,
                count: 0,
                countUnPreviewed: 0,
                isOpen: false,
            });
        };
    }, []);

    const {
        notifications,
        initialized,
        listOptions,
        count,
        isLoading,
        countUnPreviewed,
    } = state;

    const callList = (refresh = false) => {
        let Parameters = listOptions;

        if (refresh) {
            Parameters = defaultState.listOptions;
        }

        moduleQuery({
            type: "generic",
            Module: "notification_listof_bellnotifications",
            Parameters,
            callBack: (response) => {
                if (response.status === 200) {
                    if (response.data.Status.IsSuccess) {
                        const { Notifications, TotalRows, UnPreviewedCount } =
                            response.data.Data;

                        let notificationsHolder =
                            defaultState.listOptions.PageNumber !==
                            state.listOptions.PageNumber
                                ? [...state.notifications, ...Notifications]
                                : Notifications;

                        if (refresh) {
                            notificationsHolder = Notifications;
                        }

                        stateDispatch({
                            type: "update",
                            payload: {
                                notifications: notificationsHolder,
                                count: TotalRows,
                                countUnPreviewed: UnPreviewedCount,
                                initialized: true,
                                isLoading: false,
                            },
                        });
                    } else {
                        stateDispatch({
                            key: "initialized",
                            payload: true,
                            isLoading: false,
                        });
                    }
                }
            },
        });
    };

    useEffect(() => {
        if (
            !initialized ||
            defaultState.listOptions.PageNumber !== state.listOptions.PageNumber
        ) {
            callList();
        }
    }, [initialized, listOptions]);

    const payload = {
        notifications,
        count,
        countUnPreviewed,
        getIcon,
        isLoading,
        initialized,
        pageNum: state.listOptions.PageNumber,
        loadNextPage: () => {
            stateDispatch({
                key: "listOptions",
                payload: {
                    ...state.listOptions,
                    PageNumber: state.listOptions.PageNumber + 1,
                },
            });
        },
        onPreviewed: (FullRID, cb = null) => {
            apiUpdate(FullRID, false, true).then((res) => {
                if (cb) {
                    cb(res);
                }
            });
        },
        onReadAll: () => {
            moduleQuery({
                type: "generic",
                Module: "notification_update_bellnotification",
                Parameters: {},
                callBack: (response) => {
                    if (response.data.Status.IsSuccess) {
                        callList();
                    }
                },
            });
        },
    };

    return (
        <DetailsContext.Provider value={payload}>
            {children}
        </DetailsContext.Provider>
    );
};

export default DetailsProvider;
