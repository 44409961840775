import React from "react";
import { useSelector } from "react-redux";
import "assets/scss/custom.scss";
import Select from "react-select";

const CustomSelect = (props) => {
    const { theme } = useSelector((state) => state.customizer.customizer);
    const isDarkTheme = theme === "dark";

    const getOptionColor = (state) => {
        if (isDarkTheme) {
            if (state.isFocused) {
                // ? Dark Navy Blue
                return "#10163A";
            }

            if (state.isSelected) {
                // ? Dark Navy Blue
                return "#10163A";
            }

            if (state.isHovered) {
                // ? Dark Navy Blue
                return "#10163A";
            }

            // ? Dark Navy Blue
            return "#C2C6DC !important";
        }

        return "#636363";
        // return "#10163a";
    };

    const getOptionBackground = (state) => {
        if (isDarkTheme) {
            if (state.isFocused) {
                /// gray
                return "#C2C6DC !important";
            }

            /// dark navy blue
            return "transparent !important";
        }

        if (state.isFocused) {
            return "#C2C6DC !important";
        }

        return "white !important";
        // return "#10163a";
    };

    const colourStyles = {
        control: (base, state) => ({
            ...base,
            background: isDarkTheme ? "#262C49" : "white",
        }),
        singleValue: (base) => ({
            ...base,
            color: !isDarkTheme ? "#152033" : "#c2c6dc",
        }),
        input: (base) => ({
            ...base,
            color: !isDarkTheme ? "#152033" : "#c2c6dc",
        }),
        option: (base, state) => ({
            ...base,
            color: getOptionColor(state),
            backgroundColor: getOptionBackground(state),
        }),
    };

    return (
        <Select
            styles={colourStyles}
            className="React"
            classNamePrefix="select"
            {...props}
        />
    );
};

export default CustomSelect;
