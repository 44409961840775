import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import "assets/scss/custom.scss";
import { useHexCode } from "../../../hooks/useHexCode";

const CustomRadioVuexy = (props) => {
    const accentColor = useSelector(
        (state) => state.customizer.customizer.accentColor,
    );

    // console.log(props);

    return (
        <div
            className={classnames(
                `vx-radio-con ${props.className} vx-radio--circle vx-radio-con-custom `,
            )}
            // className={!isDefaultClass ? `vx-radio-${props.color} vx-radio-con ${props.className} vx-radio-${props.color}` : `vx-radio-con ${props.className} vx-radio-${props.color} vx-radio-con-custom`}
            style={{ "--color": accentColor }}
        >
            <input
                type="radio"
                defaultChecked={props.defaultChecked}
                value={props.value}
                disabled={props.disabled}
                name={props.name}
                onClick={props.onClick}
                onChange={props.onChange}
                ref={props.ref}
                checked={props.checked}
            />
            <span
                className={classnames("vx-radio", {
                    "vx-radio-sm": props.size === "sm",
                    "vx-radio-lg": props.size === "lg",
                })}
            >
                {/*	TODO: Shoul only apply color if checked.
				error: in custom.scss, (--var) wasn't reflected from styles value here in this file, same to customCheckbox
			*/}
                <span
                    className="vx-radio--border"
                    // style={{
                    //     backgroundColor: useHexCode(accentColor),
                    //     boxShadow: `0 3px 12px 0 ${useHexCode(accentColor)}`,
                    // }}
                />
                <span
                    className="vx-radio--circle"
                    style={{
                        backgroundColor: useHexCode(accentColor),
                        boxShadow: `0 3px 12px 0 ${useHexCode(accentColor)}`,
                    }}
                />
            </span>
            <span>{props.label}</span>
        </div>
    );
};

export default CustomRadioVuexy;
