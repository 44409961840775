import React from "react";

const LoginSVG = ({ color }) => (
    <svg id="b88d90b7-f620-4670-beb6-c2778d3d2c46" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="524.6028" height="427" viewBox="0 0 724.6028 527"> 
        <path d="M919.827,189.9881H366.89408a3.5475,3.5475,0,0,0-3.545-3.4881H341.57158a3.547,3.547,0,0,0-3.545,3.4881h-5.33307a3.5475,3.5475,0,0,0-3.545-3.4881H307.371a3.5475,3.5475,0,0,0-3.545,3.4881H284.42132a42.52292,42.52292,0,0,0-42.47514,42.47444v.41816c-.06658-.00382-.13039-.02-.19837-.02h-.498a3.55111,3.55111,0,0,0-3.55121,3.55121v41.53836a3.55111,3.55111,0,0,0,3.55121,3.55121h.498c.068,0,.13179-.01621.19837-.02V671.02555A42.52292,42.52292,0,0,0,284.42132,713.5H919.827a42.52277,42.52277,0,0,0,42.47445-42.47445v-438.563A42.52277,42.52277,0,0,0,919.827,189.9881ZM949.517,671.02555A29.724,29.724,0,0,1,919.827,700.71563H284.42132a29.72413,29.72413,0,0,1-29.69077-29.69008v-438.563a29.72413,29.72413,0,0,1,29.69077-29.69008H919.827A29.724,29.724,0,0,1,949.517,232.46254Z" transform="translate(-237.6986 -186.5)" fill="#ccc"/>
        <path d="M919.82661,707.81806H284.42115a36.83414,36.83414,0,0,1-36.79268-36.79251v-438.563A36.83413,36.83413,0,0,1,284.42115,195.67H919.82661a36.83416,36.83416,0,0,1,36.79285,36.7925v438.563A36.83417,36.83417,0,0,1,919.82661,707.81806Z" transform="translate(-237.6986 -186.5)" fill="#e6e6e6"/>
        <path d="M920.02116,678.17792H284.22677a8.10742,8.10742,0,0,1-8.09791-8.09843V233.40792a8.10682,8.10682,0,0,1,8.09791-8.09774H920.02116a8.10686,8.10686,0,0,1,8.09808,8.09774V670.07949A8.10746,8.10746,0,0,1,920.02116,678.17792Z" transform="translate(-237.6986 -186.5)" fill="#fff"/>
        <circle cx="24.32028" cy="264.48397" r="3.04005" fill="#fff"/>
        <path d="M515.15853,283.0916H380.09244a40.00843,40.00843,0,0,0-39.96312,39.96313v12.20836a1.855,1.855,0,0,0-2.34708,1.81131v9.38831a1.855,1.855,0,0,0,2.34708,1.8113v14.21524a1.85482,1.85482,0,0,0-2.34708,1.81125v23.47078a1.85482,1.85482,0,0,0,2.34708,1.81125v8.5823a1.85482,1.85482,0,0,0-2.34708,1.81125v23.47078a1.85479,1.85479,0,0,0,2.34708,1.81125v247.855a40.00842,40.00842,0,0,0,39.96312,39.96313H515.15853a40.00842,40.00842,0,0,0,39.96312-39.96313V323.05473A40.00843,40.00843,0,0,0,515.15853,283.0916Zm31.51364,390.02155a31.5494,31.5494,0,0,1-31.51364,31.51365H380.09244a31.54941,31.54941,0,0,1-31.51364-31.51365V323.05473a31.54941,31.54941,0,0,1,31.51364-31.51365H515.15853a31.5494,31.5494,0,0,1,31.51364,31.51365Z" transform="translate(-237.6986 -186.5)" fill="#ccc"/>
        <path d="M515.15853,709.321H380.09244a36.249,36.249,0,0,1-36.2078-36.20781V323.05473a36.24894,36.24894,0,0,1,36.2078-36.2078H515.15853a36.24894,36.24894,0,0,1,36.2078,36.2078V673.11315A36.249,36.249,0,0,1,515.15853,709.321Z" transform="translate(-237.6986 -186.5)" fill="#e6e6e6"/>
        <path d="M518.49624,703.688H376.75485a24.44846,24.44846,0,0,1-24.42073-24.42061V316.90053a24.44836,24.44836,0,0,1,24.42061-24.42061h18.48473a4.04086,4.04086,0,0,1,4.03622,4.03633,11.93717,11.93717,0,0,0,11.9238,11.9238h70.97424a11.93719,11.93719,0,0,0,11.92391-11.9238,4.04086,4.04086,0,0,1,4.03622-4.03633h20.36227a24.44845,24.44845,0,0,1,24.42073,24.42061V679.26736A24.44836,24.44836,0,0,1,518.49624,703.688Z" transform="translate(-237.6986 -186.5)" fill="#fff"/>
        <path d="M453.72789,300.69469h-16.899a1.87766,1.87766,0,1,1,0-3.75533h16.899a1.87767,1.87767,0,0,1,0,3.75533Z" transform="translate(-237.6986 -186.5)" fill="#fff"/>
        <circle cx="227.76468" cy="112.78644" r="0.93883" fill="#fff"/>
        <path d="M466.41527,388.93448l.196,4.36786c22.32961-1.0005,48.186.94517,75.70591,5.53795V394.411C514.75591,389.854,488.84067,387.93015,466.41527,388.93448Z" transform="translate(-237.6986 -186.5)" fill="#ccc"/>
        <path d="M438.64993,474.48508q-.26015,2.50739-.42387,5.04537-.3456,4.99082-.3456,10.06037A146.14677,146.14677,0,0,0,542.31715,629.57864v-280.002A146.07521,146.07521,0,0,0,438.64993,474.48508Z" transform="translate(-237.6986 -186.5)" fill={color}/>
        <circle cx="128.0756" cy="200.45777" r="2.87877" fill="#ccc"/>
        <circle cx="121.93992" cy="313.13102" r="5.68695" fill="#ccc"/>
        <circle cx="229.03532" cy="164.19102" r="4.11892" fill="#ccc"/>
        <circle cx="135.32687" cy="393.45257" r="3.28616" fill="#ccc"/>
        <circle cx="201.70368" cy="439.19122" r="3.55873" fill="#ccc"/>
        <polygon points="305.157 80.869 298.134 80.869 298.134 73.846 294.366 73.846 294.366 80.869 287.34 80.869 287.34 84.639 294.366 84.639 294.366 91.662 298.134 91.662 298.134 84.639 305.157 84.639 305.157 80.869" fill="#ccc"/>
        <circle cx="254.13582" cy="278.54814" r="12.27134" opacity="0.1" style={{isolation:'isolate'}} />
        <circle cx="260.27147" cy="365.00534" r="12.27133" opacity="0.1" style={{isolation:'isolate'}} />
        <path d="M443.83272,474.57211c-15.05816-10.11117-26.274-20.08648-33.33569-29.649-7.0448-9.53962-9.55668-18.15732-7.46679-25.61417,2.0899-7.457,8.71522-13.51382,19.69188-18.00213,11.003-4.49941,25.76936-7.19248,43.88849-8.00436l-.19534-4.36794c-38.79961,1.73882-62.80589,12.10692-67.59488,29.19425s10.33054,38.42168,42.575,60.07315c26.29466,17.65668,61.513,33.96249,100.92181,46.87644v-4.6017C503.83873,507.76114,469.50379,491.81032,443.83272,474.57211Z" transform="translate(-237.6986 -186.5)" fill="#ccc"/>
        <path d="M443.83085,478.07024q-2.669-1.79481-5.18092-3.58516-.26015,2.50739-.42387,5.04545,1.55616,1.08646,3.16991,2.16855c26.29465,17.65731,61.51238,33.96288,100.92118,46.87722v-4.60248C503.84,511.2581,469.50567,495.30768,443.83085,478.07024Z" transform="translate(-237.6986 -186.5)" opacity="0.1" style={{isolation:"isolate"}}/>
        <path d="M606.67575,407.64918c-17.19689-4.82013-34.24227-8.74605-50.78052-11.76781v4.44339c16.1557,2.97871,32.80039,6.8255,49.60034,11.53435C658.9805,426.85005,707.3778,448.69693,738.278,471.7975l2.61832-3.50174C709.581,444.88473,660.65937,422.77989,606.67575,407.64918Z" transform="translate(-237.6986 -186.5)" fill="#ccc"/>
        <path d="M735.07021,489.59082a146.128,146.128,0,0,0-179.175-142.25341v284.497a146.1045,146.1045,0,0,0,164.41014-78.18689q1.13025-2.30418,2.17317-4.652a145.50051,145.50051,0,0,0,12.592-59.40465Z" transform="translate(-237.6986 -186.5)" fill={color}/>
        <circle cx="355.20436" cy="129.05035" r="1.67338" fill="#f2f2f2"/>
        <circle cx="468.43538" cy="139.09053" r="3.79021" fill="#ccc"/>
        <circle cx="602.86239" cy="296.39737" r="6.15382" fill="#ccc"/>
        <circle cx="554.74246" cy="198.11071" r="2.64027" fill="#ccc"/>
        <circle cx="545.50149" cy="283.9193" r="2.64028" fill="#ccc"/>
        <polygon points="473.192 460.867 468.78 460.867 468.78 456.458 466.415 456.458 466.415 460.867 462.006 460.867 462.006 463.233 466.415 463.233 466.415 467.643 468.78 467.643 468.78 463.233 473.192 463.233 473.192 460.867" fill="#ccc"/>
        <polygon points="574.971 438.296 571.675 438.296 571.675 435 569.904 435 569.904 438.296 566.608 438.296 566.608 440.066 569.904 440.066 569.904 443.362 571.675 443.362 571.675 440.066 574.971 440.066 574.971 438.296" fill="#ccc"/>
        <circle cx="353.53103" cy="188.74421" r="12.27135" opacity="0.1" style={{isolation:"isolate"}}/>
        <circle cx="353.53103" cy="297.51296" r="12.27135" opacity="0.1" style={{isolation:"isolate"}}/>
        <circle cx="419.35006" cy="231.69392" r="12.27135" opacity="0.1" style={{isolation:"isolate"}}/>
        <circle cx="460.06859" cy="314.8044" r="12.27135" opacity="0.1" style={{isolation:"isolate"}}/>
        <path d="M768.46131,495.73671c-5.76224-8.84169-15.03625-18.07429-27.565-27.44072l-2.61832,3.50158c24.34687,18.20155,35.68792,35.59973,31.93512,48.98943-2.0899,7.45708-8.71521,13.51359-19.69156,18.0019-11.00359,4.49942-25.77,7.19232-43.88912,8.00444q-5.77477.25827-11.85069.25826c-35.45251-.00078-78.19995-6.56505-121.905-18.81479q-8.59595-2.40938-16.98152-5.04037v4.57807q7.80989,2.42651,15.80133,4.67207c44.087,12.357,87.24731,18.97711,123.10679,18.97625,4.10529,0,8.12074-.08664,12.02506-.26171,38.7996-1.73905,62.80557-12.10668,67.59487-29.194C776.64466,514.04124,774.639,505.21584,768.46131,495.73671Z" transform="translate(-237.6986 -186.5)" fill="#ccc"/>
        <path d="M706.63369,550.28968q-5.77337.258-11.85288.258c-35.44938,0-78.20089-6.56263-121.90469-18.81339q-8.59595-2.40948-16.98089-5.04044v4.57885q7.80894,2.42638,15.80008,4.67191c44.08884,12.35571,87.24669,18.97523,123.10741,18.97523,4.1053,0,8.12356-.08749,12.02788-.26241q7.07406-.31473,13.47477-1.00989,1.13073-2.30418,2.17317-4.652Q715.0415,549.90924,706.63369,550.28968Z" transform="translate(-237.6986 -186.5)" opacity="0.1" style={{isolation:"isolate"}}/>
        <circle cx="419.3014" cy="180.5" r="16" fill="#fff"/>
        <path d="M657.04858,343.45032a22.86929,22.86929,0,0,0-22.86926,22.86928c0,12.63036,22.86926,54.10547,22.86926,54.10547S679.91784,378.95,679.91784,366.3196A22.86929,22.86929,0,0,0,657.04858,343.45032Zm0,34.02508a10.598,10.598,0,1,1,10.598-10.598,10.598,10.598,0,0,1-10.598,10.598h0Z" transform="translate(-237.6986 -186.5)" fill="#3f3d56"/>
        <circle cx="353.3014" cy="238.5" r="16" fill="#fff"/>
        <path d="M591.04858,401.45032a22.86929,22.86929,0,0,0-22.86926,22.86928c0,12.63036,22.86926,54.10547,22.86926,54.10547S613.91784,436.95,613.91784,424.3196A22.86929,22.86929,0,0,0,591.04858,401.45032Zm0,34.02508a10.598,10.598,0,1,1,10.598-10.598,10.598,10.598,0,0,1-10.598,10.598h0Z" transform="translate(-237.6986 -186.5)" fill="#3f3d56"/>
        <circle cx="353.3014" cy="135.5" r="16" fill="#fff"/>
        <path d="M591.04858,298.45032a22.86929,22.86929,0,0,0-22.86926,22.86928c0,12.63036,22.86926,54.10547,22.86926,54.10547S613.91784,333.95,613.91784,321.3196A22.86929,22.86929,0,0,0,591.04858,298.45032Zm0,34.02508a10.598,10.598,0,1,1,10.598-10.598,10.598,10.598,0,0,1-10.598,10.598h0Z" transform="translate(-237.6986 -186.5)" fill="#ccc"/>
        <circle cx="460.3014" cy="259.5" r="16" fill="#fff"/>
        <path d="M698.04858,422.45032a22.86929,22.86929,0,0,0-22.86926,22.86928c0,12.63036,22.86926,54.10547,22.86926,54.10547S720.91784,457.95,720.91784,445.3196A22.86929,22.86929,0,0,0,698.04858,422.45032Zm0,34.02508a10.598,10.598,0,1,1,10.598-10.598,10.598,10.598,0,0,1-10.598,10.598h0Z" transform="translate(-237.6986 -186.5)" fill="#ccc"/>
        <circle cx="254.3014" cy="223.5" r="16" fill="#fff"/>
        <path d="M492.04858,386.45032a22.86929,22.86929,0,0,0-22.86926,22.86928c0,12.63036,22.86926,54.10547,22.86926,54.10547S514.91784,421.95,514.91784,409.3196A22.86929,22.86929,0,0,0,492.04858,386.45032Zm0,34.02508a10.598,10.598,0,1,1,10.598-10.598,10.598,10.598,0,0,1-10.598,10.598h0Z" transform="translate(-237.6986 -186.5)" fill="#ccc"/>
        <circle cx="260.3014" cy="310.5" r="16" fill="#fff"/>
        <path d="M498.04858,473.45032a22.86929,22.86929,0,0,0-22.86926,22.86928c0,12.63036,22.86926,54.10547,22.86926,54.10547S520.91784,508.95,520.91784,496.3196A22.86929,22.86929,0,0,0,498.04858,473.45032Zm0,34.02508a10.598,10.598,0,1,1,10.598-10.598,10.598,10.598,0,0,1-10.598,10.598h0Z" transform="translate(-237.6986 -186.5)" fill="#3f3d56" />
    </svg>
);

export default LoginSVG;
