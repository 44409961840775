import classNames from "classnames";
import { useDispatch } from "react-redux";
import StaticLabel from "components/customs/static-label-wrapper";
import useSelector from "hooks/useSelector";
import React, { useContext, useState } from "react";
import { ChromePicker } from "react-color";
import { X } from "react-feather";
import { changeNavbarColor } from "redux/actions/customizer";
import { CustomizerContext } from "./Context";

export default function NavbarColor() {
    const { navbarType, navbarColor } = useSelector(
        (state) => state.customizer.customizer,
    );

    const dispatch = useDispatch();
    const { state, stateDispatch } = useContext(CustomizerContext);
    const { colorPickerOpened } = state;

    const defaultColors = [
        "default",
        "primary",
        "danger",
        "info",
        "warning",
        "dark",
        "success",
    ];

    if (navbarType === "static") {
        return <></>;
    }

    return (
        <>
            <div id="customizer-navbar-color" className="navbar-colors">
                <h5>
                    <StaticLabel id="NavbarColorTitle" />
                </h5>
                <div className="d-flex">
                    <div
                        aria-hidden="true"
                        className={classNames("color-box bg-primary", {
                            selected:
                                navbarColor === "primary" &&
                                navbarType !== "static",
                            disabled: navbarType === "static",
                        })}
                        onClick={() => dispatch(changeNavbarColor("primary"))}
                    />
                    <div
                        aria-hidden="true"
                        className={classNames("color-box bg-success", {
                            selected:
                                navbarColor === "success" &&
                                navbarType !== "static",
                            disabled: navbarType === "static",
                        })}
                        onClick={() => dispatch(changeNavbarColor("success"))}
                    />
                    <div
                        aria-hidden="true"
                        className={classNames("color-box bg-danger", {
                            selected:
                                navbarColor === "danger" &&
                                navbarType !== "static",
                            disabled: navbarType === "static",
                        })}
                        onClick={() => dispatch(changeNavbarColor("danger"))}
                    />
                    <div
                        aria-hidden="true"
                        className={classNames("color-box bg-info", {
                            selected:
                                navbarColor === "info" &&
                                navbarType !== "static",
                            disabled: navbarType === "static",
                        })}
                        onClick={() => dispatch(changeNavbarColor("info"))}
                    />
                    <div
                        aria-hidden="true"
                        className={classNames("color-box bg-warning", {
                            selected:
                                navbarColor === "warning" &&
                                navbarType !== "static",
                            disabled: navbarType === "static",
                        })}
                        onClick={() => dispatch(changeNavbarColor("warning"))}
                    />
                    <div
                        aria-hidden="true"
                        className={classNames("color-box bg-dark", {
                            selected:
                                navbarColor === "dark" &&
                                navbarType !== "static",
                            disabled: navbarType === "static",
                        })}
                        onClick={() => dispatch(changeNavbarColor("dark"))}
                    />
                    <div
                        aria-hidden="true"
                        className={classNames("color-box", {
                            disabled: navbarType === "static",
                            selected:
                                navbarColor === "default" ||
                                navbarType === "static" ||
                                !defaultColors.includes(navbarColor),
                        })}
                        style={{
                            position: "relative",
                            backgroundImage:
                                defaultColors.includes(navbarColor) &&
                                "linear-gradient( 90deg , rgb(131, 58, 180) 0%, rgb(253, 29, 29) 50%, rgb(252, 176, 69) 100%)",
                            backgroundColor: `${navbarColor}`,
                        }}
                        onClick={() => {
                            stateDispatch({
                                type: "SET_NAVBAR_COLOR_PCIKER_OPENED",
                                payload: !colorPickerOpened.navbarColor,
                            });
                        }}
                    />
                    {colorPickerOpened.navbarColor && (
                        <div className="custom-nav-container">
                            <div
                                aria-hidden="true"
                                className="close-icon cursor-pointer float-right bg-white color-picker-margin"
                                onClick={() =>
                                    stateDispatch({
                                        type: "SET_NAVBAR_COLOR_PCIKER_OPENED",
                                        payload: false,
                                    })
                                }
                            >
                                <X size={18} />
                            </div>
                            <ChromePicker
                                color={navbarColor}
                                onChangeComplete={(color) =>
                                    dispatch(changeNavbarColor(color.hex))
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
            <hr />
        </>
    );
}
