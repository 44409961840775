/* eslint-disable no-shadow */
/* eslint-disable react/state-in-constructor */
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import i18nDefaults from "i18n/en-us.json";
import _ from "lodash";
import axios from "axios";
import { languagePersistState, userDetails } from "configs/config";
import { genericQuery, Toast } from "utility/helpers";
import { apiUpdate } from "services/apiService";
import Spinner from "components/@vuexy/spinner/Loading-spinner";

const Context = React.createContext();

const IntlProviderWrapper = ({ children }) => {
    const [messages, setMessages] = useState(i18nDefaults);

    const [locale, setLocale] = useState(null);
    const [language, setLanguage] = useState(null);

    const [defaultLanguage, setDefaultLanguage] = useState(null);
    const [languageVersion, setLanguageVersion] = useState(null);

    const [languageList, setLanguageList] = useState([]);

    const getDefaultLanguageDetails = async () => {
        return new Promise((resolve) => {
            const config = {
                Url: "public_api",
                Method: "GET",
                Data: {
                    requestjson: {
                        Module: "system_get_languagedetails",
                    },
                },
                ResponseSuccessCallback: (responseJSON) => {
                    const { data } = responseJSON;
                    const { Data } = data;

                    resolve(Data);
                },
                ResponseFailCallback: () => {},
            };
            genericQuery(config);
        });
    };

    useEffect(() => {
        getDefaultLanguageDetails().then((res) => {
            const { LanguageVersion, DefaultLanguage } = res;
            const { LanguageID, Name, TwoLetterCode } = DefaultLanguage;

            let param = {
                LanguageID,
                Name,
                TwoLetterCode,
                Version: LanguageVersion.Value,
                Default: DefaultLanguage,
            };

            if (!_.isEmpty(userDetails()) && userDetails().LanguageDetails) {
                if (Object.keys(userDetails().LanguageDetails).length > 0) {
                    const { LanguageID, Name, TwoLetterCode } =
                        userDetails()?.LanguageDetails;

                    param = {
                        LanguageID,
                        Name,
                        TwoLetterCode,
                        Version: LanguageVersion.Value,
                        Default: DefaultLanguage,
                    };
                }
            } else if (
                !_.isEmpty(languagePersistState()) &&
                Object.keys(languagePersistState()).length > 0
            ) {
                const { LanguageID, Name, TwoLetterCode } =
                    languagePersistState();

                param = {
                    LanguageID,
                    Name,
                    TwoLetterCode,
                    Version: LanguageVersion.Value,
                    Default: DefaultLanguage,
                };
            }

            setLanguageVersion(LanguageVersion);
            setDefaultLanguage(DefaultLanguage);

            getLanguage(param);
            getLanguages();
        });
    }, []);

    const getLanguages = () => {
        const config = {
            Url: "public_api",
            Method: "GET",
            Data: {
                requestjson: {
                    Module: "system_get_list",
                    Parameters: {
                        ObjectType: "LanguageList",
                    },
                },
            },
            ResponseSuccessCallback: (requestjson) => {
                const { data } = requestjson;
                const { Data } = data;
                const { List } = Data;
                setLanguageList(List);
            },
            ResponseFailCallback: (requestjson) => {
                Toast({
                    type: 4,
                    content: requestjson?.data?.Data?.Status?.Message,
                });
            },
        };

        genericQuery(config);
    };

    const getLanguage = ({ LanguageID, TwoLetterCode, Name = "", Version }) => {
        return axios
            .get(
                `${process.env.REACT_APP_URL}/languages/lang-${LanguageID}-v${Version}.json`,
            )
            .then((res) => {
                setMessages(res.data);
                setLocale(TwoLetterCode);
                setLanguage(Name);
            });
    };

    const switchLanguage = (language) => {
        const { LanguageID, TwoLetterCode, Name } = language;

        if (userDetails()?.LanguageDetails?.RID) {
            const oldLangID = userDetails()?.LanguageDetails?.LanguageID;
            const RID = userDetails()?.LanguageDetails.RID;

            // !! Uncomment only when toast is approved
            // toast("Loading Language", {
            //     autoClose: false,
            //     type: toast.TYPE.WARNING,
            //     position: "top-center",
            // });

            apiUpdate(`${RID}.Value`, oldLangID, LanguageID).then(() => {
                userDetails({
                    ...userDetails(),
                    LanguageDetails: {
                        ...userDetails().LanguageDetails,
                        LanguageID,
                        TwoLetterCode,
                        Name,
                    },
                });

                getLanguage({
                    LanguageID,
                    Name,
                    TwoLetterCode,
                    Version: languageVersion.Value,
                });
                // !! Uncomment only when toast is approved
                // destroyAllToast();
            });
        } else if (language.UserActivationLanguage) {
            const { RID, Token } = language.UserActivationLanguage;

            // !! Uncomment only when toast is approved
            // toast("Loading Language", {
            //     autoClose: false,
            //     type: toast.TYPE.WARNING,
            //     position: "top-center",
            // });

            apiUpdate(
                `${RID}.LanguageID`,
                language.UserActivationLanguage?.LanguageID,
                LanguageID,
                {
                    headers: {
                        token: Token,
                    },
                },
            ).then(() => {
                getLanguage({
                    LanguageID,
                    Name,
                    TwoLetterCode,
                    Version: languageVersion.Value,
                });
                // !! Uncomment only when toast is approved
                // destroyAllToast();
            });
        } else {
            getLanguage({
                LanguageID,
                Name,
                TwoLetterCode,
                Version: languageVersion.Value,
            });
        }
    };

    const payload = {
        languageVersion,
        locale,
        language,
        defaultLanguage,
        switchLanguage,
        languageList,
    };

    if (!languageVersion && !language && !locale && !defaultLanguage) {
        return <Spinner />;
    }

    return (
        <Context.Provider value={payload}>
            <IntlProvider
                key={locale}
                locale={locale}
                messages={messages}
                // defaultLocale="en"
                onError={() => {}}
            >
                {children}
            </IntlProvider>
        </Context.Provider>
    );
};

export { IntlProviderWrapper, Context as IntlContext };
