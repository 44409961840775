import React, { MouseEventHandler } from "react";
import * as Icon from "react-feather";
import { Button } from "reactstrap";

export type PreBuiltButtonProps = {
    type: "facebook-icon" | "instagram-icon";
    classNameAppend?: string;
    outline?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
};

const PreBuiltButton = ({
    type,
    outline = false,
    classNameAppend = "",
    onClick,
}: PreBuiltButtonProps) => {
    const btnProps = {
        className: `btn-icon${classNameAppend ? ` ${classNameAppend}` : ""}`,
        outline,
        onClick,
    };

    switch (type) {
        case "facebook-icon":
            return (
                <Button {...btnProps}>
                    <Icon.Facebook size={15} />
                </Button>
            );

        case "instagram-icon":
            return (
                <Button {...btnProps}>
                    <Icon.Instagram size={15} />
                </Button>
            );

        default:
            return null;
    }
};

export default PreBuiltButton;
