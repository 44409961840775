import React, { useContext } from "react";
import StaticLabel from "components/customs/static-label-wrapper";
import classNames from "classnames";
import useSelector from "hooks/useSelector";
import { ChromePicker } from "react-color";
import { changeAccentColor } from "redux/actions/customizer";
import { X } from "react-feather";
import { useDispatch } from "react-redux";
import { CustomizerContext } from "./Context";

export default function AccentColor() {
    const { accentColor } = useSelector((state) => state.customizer.customizer);

    const { state, stateDispatch } = useContext(CustomizerContext);
    const { colorPickerOpened } = state;
    const dispatch = useDispatch();

    const defaultColors = [
        "default",
        "primary",
        "danger",
        "info",
        "warning",
        "dark",
        "success",
    ];

    return (
        <div id="customizer-accent-color" className="menu-theme-colors">
            <h5>
                <StaticLabel id="AccentColorTitle" />
            </h5>
            <div className="d-flex">
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-primary", {
                        selected: accentColor === "primary",
                    })}
                    onClick={() => dispatch(changeAccentColor("primary"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-success", {
                        selected: accentColor === "success",
                    })}
                    onClick={() => dispatch(changeAccentColor("success"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-danger", {
                        selected: accentColor === "danger",
                    })}
                    onClick={() => dispatch(changeAccentColor("danger"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-info", {
                        selected: accentColor === "info",
                    })}
                    onClick={() => dispatch(changeAccentColor("info"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-warning", {
                        selected: accentColor === "warning",
                    })}
                    onClick={() => dispatch(changeAccentColor("warning"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-dark", {
                        selected: accentColor === "dark",
                    })}
                    onClick={() => dispatch(changeAccentColor("dark"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box", {
                        selected: ![
                            "primary",
                            "danger",
                            "info",
                            "warning",
                            "dark",
                            "success",
                        ].includes(accentColor),
                    })}
                    style={{
                        position: "relative",
                        backgroundImage:
                            defaultColors.includes(accentColor) &&
                            "linear-gradient( 90deg , rgb(131, 58, 180) 0%, rgb(253, 29, 29) 50%, rgb(252, 176, 69) 100%)",
                        backgroundColor: accentColor,
                    }}
                    onClick={() => {
                        stateDispatch({
                            type: "SET_ACCENT_COLOR_PCIKER_OPENED",
                            payload: !colorPickerOpened.accentColor,
                        });
                    }}
                />
                {colorPickerOpened.accentColor && (
                    <div className="custom-accent-container">
                        <div
                            aria-hidden="true"
                            className="close-icon cursor-pointer float-right bg-white color-picker-margin"
                            onClick={() =>
                                stateDispatch({
                                    type: "SET_ACCENT_COLOR_PCIKER_OPENED",
                                    payload: false,
                                })
                            }
                        >
                            <X size={18} />
                        </div>
                        <ChromePicker
                            color={accentColor}
                            onChangeComplete={(color) => {
                                dispatch(changeAccentColor(color.hex));
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
