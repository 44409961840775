import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import TutorialJoyride from "components/react-joyride";
import { INTERCOM_APP_ID } from "configs/config";
import { IntercomProvider } from "react-use-intercom";
import { updateVersionLogout } from "utility/helpers";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import Layout from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-sortable-tree/style.css";
import Initialization from "./configs/Initialization";
import "moment/min/locales";

const LazyApp = lazy(() => import("./App"));

updateVersionLogout();

ReactDOM.render(
    <Provider store={store}>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
            <Suspense fallback={<Spinner />}>
                <IntlProviderWrapper>
                    <TutorialJoyride>
                        <Layout>
                            <Initialization />
                            <LazyApp />
                        </Layout>
                    </TutorialJoyride>
                </IntlProviderWrapper>
            </Suspense>
        </IntercomProvider>
    </Provider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
