import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

interface CustomFontAwesomeProps {
    icon: any;
    className?: string;
    useAccentColor?: boolean;
    color?: string;
}

const defaultColors = ["primary", "info", "success", "warning", "danger"];

const checkColor = ({
    useAccentColor,
    color = "",
    accentColor,
}: {
    useAccentColor?: boolean;
    color?: string;
    accentColor: string;
}) => {
    if (!useAccentColor) return color;

    if (defaultColors.includes(accentColor)) return "";

    return accentColor;
};

const CustomFontAwesome: React.FC<CustomFontAwesomeProps> = ({
    icon,
    className,
    useAccentColor,
    color,
}: CustomFontAwesomeProps) => {
    const accentColor = useSelector(
        (state: any) => state.customizer.customizer.accentColor,
    );

    return (
        <FontAwesomeIcon
            icon={icon}
            className={`${className}${
                useAccentColor &&
                (defaultColors.includes(accentColor) ? ` ${accentColor}` : "")
            }`}
            color={checkColor({ useAccentColor, color, accentColor })}
        />
    );
};

CustomFontAwesome.defaultProps = {
    className: "",
    color: "#000",
    useAccentColor: false,
};

export default CustomFontAwesome;
