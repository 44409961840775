/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React from "react";
import useSelector from "hooks/useSelector";
import { Check } from "react-feather";
import { useHexCode } from "../../../hooks/useHexCode";

import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";

import "assets/scss/custom.scss";

const CustomCheckbox = (props) => {
	const accentColor = useSelector(
		(state) => state.customizer.customizer.accentColor,
	);
	props = { ...props, color: useHexCode(accentColor) };

	return (
		<Checkbox
			className="custom-vx-checkbox-con"
			icon={
				<Check
					className="vx-icon"
					size={16}
					style={{ backgroundColor: useHexCode(accentColor) }}
				/>
			}
			{...props}
		/>
	);
};

export default CustomCheckbox;
