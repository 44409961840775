/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import { Check, X } from "react-feather";
import { Card } from "reactstrap";
import StaticLabel from "components/customs/static-label-wrapper";
import { createPortal } from "react-dom";

interface PasswordCheckerProps {
    password: string;
    validatePassword: (isValid: boolean) => void;
    container?: string;
    requirement?: {
        minimumChar: number;
    };
}

const PasswordChecker = ({
    password,
    validatePassword,
    container,
    requirement,
}: PasswordCheckerProps) => {
    const [isPasswordLonger, setIsPasswordLonger] = useState(false);
    const [isPasswordHasUpperCase, setIsPasswordHasUpperCase] = useState(false);
    const [isPasswordHasLowerCase, setIsPasswordHasLowerCase] = useState(false);
    const [isPasswordHasNumber, setIsPasswordHasNumber] = useState(false);

    const [isPasswordHasSpecialChar, setIsPasswordHasSpecialChar] =
        useState(false);
    const [isPasswordHasNoSpace, setIsPasswordHasNoSpace] = useState(false);

    useEffect(() => {
        if (password) {
            // Check if Password > 6
            if (requirement?.minimumChar) {
                if (password?.length >= requirement?.minimumChar) {
                    setIsPasswordLonger(true);
                } else {
                    setIsPasswordLonger(false);
                }
            } else {
                setIsPasswordLonger(true);
            }

            // Check if Password Has Upper Case
            if (password.match(/[A-Z]/) != null) {
                setIsPasswordHasUpperCase(true);
            } else {
                setIsPasswordHasUpperCase(false);
            }

            // Check if Password Has Lower Case
            if (password.match(/[a-z]/) != null) {
                setIsPasswordHasLowerCase(true);
            } else {
                setIsPasswordHasLowerCase(false);
            }

            // Check if Password Has Number
            if (password.match(/[0-9]/) != null) {
                setIsPasswordHasNumber(true);
            } else {
                setIsPasswordHasNumber(false);
            }

            // Check if Password Has Special Characters
            if (password.match(/[#?!@$%^&)+{}_=[\];:.,<>'"|(*\\~/-]/) != null) {
                setIsPasswordHasSpecialChar(true);
            } else {
                setIsPasswordHasSpecialChar(false);
            }

            // Check if Password Has No Space
            if (!password.match(/\s/) && password?.length > 0) {
                setIsPasswordHasNoSpace(true);
            } else {
                setIsPasswordHasNoSpace(false);
            }
        } else {
            setIsPasswordLonger(false);
            setIsPasswordHasUpperCase(false);
            setIsPasswordHasLowerCase(false);
            setIsPasswordHasNumber(false);
            setIsPasswordHasSpecialChar(false);
            setIsPasswordHasNoSpace(false);
        }
    }, [password]);

    useEffect(() => {
        validate(
            isPasswordLonger,
            isPasswordHasLowerCase,
            isPasswordHasNoSpace,
            isPasswordHasUpperCase,
            isPasswordHasSpecialChar,
            isPasswordHasNumber,
        );
    }, [
        isPasswordLonger,
        isPasswordHasLowerCase,
        isPasswordHasNoSpace,
        isPasswordHasNumber,
        isPasswordHasSpecialChar,
        isPasswordHasUpperCase,
    ]);

    const validate = (
        isPasswordLonger: boolean,
        isPasswordHasLowerCase: boolean,
        isPasswordHasNoSpace: boolean,
        isPasswordHasUpperCase: boolean,
        isPasswordHasSpecialChar: boolean,
        isPasswordHasNumber: boolean,
    ) => {
        if (
            isPasswordLonger &&
            isPasswordHasLowerCase &&
            isPasswordHasNoSpace &&
            isPasswordHasUpperCase &&
            isPasswordHasSpecialChar &&
            isPasswordHasNumber
        ) {
            validatePassword(true);
        } else {
            validatePassword(false);
        }
    };

    const renderCard = (
        <>
            <Card className="mt-1">
                <p className="px-2 pt-1 auth-title">
                    {/* {"In order to \r"}
                    <strong>{"protect your account"}</strong>
                    {", make sure your password composes:"} */}
                    <StaticLabel
                        id="ResetPassNotif"
                        values={{
                            strongText: (
                                <strong>
                                    <StaticLabel id="PasswordCheckerHeaderStrong" />
                                </strong>
                            ),
                        }}
                    />
                </p>
                <ul className="list-unstyled ml-2">
                    <li>
                        {!isPasswordLonger ? (
                            <X color="red" size="15" />
                        ) : (
                            <Check color="green" size="15" />
                        )}
                        <span
                            className={`${!isPasswordLonger
                                    ? " text-danger"
                                    : "text-success"
                                } ml-1 `}
                        >
                            <StaticLabel
                                id="PasswordRule5"
                                values={{
                                    number: `${requirement?.minimumChar}`,
                                }}
                            />
                        </span>
                    </li>
                    <li>
                        {!isPasswordHasUpperCase ? (
                            <X color="red" size="15" />
                        ) : (
                            <Check color="green" size="15" />
                        )}
                        <span
                            className={`${!isPasswordHasUpperCase
                                    ? " text-danger"
                                    : "text-success"
                                } ml-1`}
                        >
                            <StaticLabel
                                id="PasswordRule1"
                                values={{ number: 1 }}
                            />
                        </span>
                    </li>
                    <li>
                        {!isPasswordHasLowerCase ? (
                            <X color="red" size="15" />
                        ) : (
                            <Check color="green" size="15" />
                        )}
                        <span
                            className={`${!isPasswordHasLowerCase
                                    ? " text-danger"
                                    : "text-success"
                                } ml-1`}
                        >
                            <StaticLabel
                                id="PasswordRule2"
                                values={{ number: 1 }}
                            />
                        </span>
                    </li>
                    <li>
                        {!isPasswordHasNumber ? (
                            <X color="red" size="15" />
                        ) : (
                            <Check color="green" size="15" />
                        )}
                        <span
                            className={`${!isPasswordHasNumber
                                    ? " text-danger"
                                    : "text-success"
                                } ml-1`}
                        >
                            <StaticLabel
                                id="PasswordRule3"
                                values={{ number: 1 }}
                            />
                        </span>
                    </li>
                    <li>
                        {!isPasswordHasSpecialChar ? (
                            <X color="red" size="15" />
                        ) : (
                            <Check color="green" size="15" />
                        )}
                        <span
                            className={`${!isPasswordHasSpecialChar
                                    ? " text-danger"
                                    : "text-success"
                                } ml-1`}
                        >
                            <StaticLabel
                                id="PasswordRule4"
                                values={{ number: 1 }}
                            />
                        </span>
                    </li>
                    <li>
                        {!isPasswordHasNoSpace ? (
                            <X color="red" size="15" />
                        ) : (
                            <Check color="green" size="15" />
                        )}
                        <span
                            className={`${!isPasswordHasNoSpace
                                    ? " text-danger"
                                    : "text-success"
                                } ml-1`}
                        >
                            <StaticLabel
                                id="PasswordRule6"
                                values={{ number: 1 }}
                            />
                        </span>
                    </li>
                </ul>
            </Card>
        </>
    );

    if (container) {
        return createPortal(
            renderCard,
            document.getElementById(container) as HTMLElement,
        );
    }

    return renderCard;
};

export default PasswordChecker;
