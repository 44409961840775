import React from "react";
import MainComponent from "./Main";
import DetailsProvider from "./Context";

const DropdownNotifications = (props) => {
    return (
        <DetailsProvider>
            <MainComponent {...props} />
        </DetailsProvider>
    );
};

export default DropdownNotifications;
