import { promptPersistState } from "configs/config";
import React from "react";

const defaultState = {
    isModalOpen: promptPersistState().isModalOpen || false,
    step: promptPersistState().step || 0,
};

const promptReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_PROMPT_STATE":
            return { ...state, isModalOpen: action.isModalOpen };

        case "SET_PROMPT_STEP":
            return { ...state, step: action.step };
        default:
            return state;
    }
};

export default promptReducer;
