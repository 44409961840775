import { useSelector as useDefaultSelector } from "react-redux";
import { isEqual } from "utility/checkers";

import { RootState } from "../redux/reducers/rootReducer";

/**
 * A typed `react-redux` `useSelector()` based on `rootReducer`.
 */
export default function useSelector<T, State = RootState>(
    select: (
        // eslint-disable-next-line no-unused-vars
        state: State,
    ) => T,
) {
    return useDefaultSelector(select, isEqual);
}
