import React from "react";
import * as Icon from "react-feather";

interface CustomDeleteButtonProps {
    id: any;
    type?: "icon-trash";
    onClickDelete: any;
	containerStyle: string;
}

const CustomDeleteButton = ({
    id,
    type,
    onClickDelete,
	containerStyle,
}: CustomDeleteButtonProps) => {
    switch (type) {
        default:
            return (
                <Icon.Trash
                    id={`${id}`}
                    className={`mr-1 icon-button danger ${containerStyle}`}
                    size={14}
                    onClick={onClickDelete}
                />
            );
    }
};

export default CustomDeleteButton;
