/* eslint-disable no-nested-ternary */
import React from "react";
import { Navbar } from "reactstrap";
import { connect, useSelector } from "react-redux";
import classnames from "classnames";
import LogoSVG from "assets/img/logo/logo-svg";
import { useHexCode } from "hooks/useHexCode";
import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg";
import { MEDIA_LIBRARY, userDetails } from "../../../configs/config";
import { isDefaultClass } from "../../../utility/utilities";

const UserName = (props) => {
    let username = "";

    if (props.userdata) {
        const userDetail = props.userdata;

        username = `${userDetail.FirstName} ${userDetail.LastName}`;
    }

    return username;
};

const ThemeNavbar = (props) => {
    const colorsArr = [
        "primary",
        "danger",
        "success",
        "info",
        "warning",
        "dark",
    ];
    const navbarTypes = ["floating", "static", "sticky", "hidden"];
    const user = {};
    const customizer = useSelector((state) => state.customizer.customizer);

    const logoColor = useHexCode(customizer.menuTheme);

    return (
        <>
            <div className="content-overlay" />
            <div className="header-navbar-shadow" />
            <Navbar
                className={classnames(
                    "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
                    {
                        "bg-custom":
                            !isDefaultClass(props.navbarColor) &&
                            !props.horizontal,
                        "navbar-light":
                            props.navbarColor === "default" ||
                            !colorsArr.includes(props.navbarColor),
                        "navbar-dark": colorsArr.includes(props.navbarColor),
                        "bg-primary":
                            props.navbarColor === "primary" &&
                            props.navbarType !== "static" &&
                            !props.horizontal,
                        "bg-danger":
                            props.navbarColor === "danger" &&
                            props.navbarType !== "static" &&
                            !props.horizontal,
                        "bg-success":
                            props.navbarColor === "success" &&
                            props.navbarType !== "static" &&
                            !props.horizontal,
                        "bg-info":
                            props.navbarColor === "info" &&
                            props.navbarType !== "static" &&
                            !props.horizontal,
                        "bg-warning":
                            props.navbarColor === "warning" &&
                            props.navbarType !== "static" &&
                            !props.horizontal,
                        "bg-dark":
                            props.navbarColor === "dark" &&
                            props.navbarType !== "static" &&
                            !props.horizontal,
                        "d-none":
                            props.navbarType === "hidden" && !props.horizontal,
                        "floating-nav":
                            (props.navbarType === "floating" &&
                                !props.horizontal) ||
                            (!navbarTypes.includes(props.navbarType) &&
                                !props.horizontal),
                        "navbar-static-top":
                            props.navbarType === "static" && !props.horizontal,
                        "fixed-top":
                            props.navbarType === "sticky" || props.horizontal,
                        scrolling: props.horizontal && props.scrolling,
                    },
                )}
                style={{ "--nav_color": props.navbarColor }}
            // style={{ backgroundColor: props.navbarColor }}
            >
                <div className="navbar-wrapper">
                    <div className="navbar-container content">
                        <div
                            className="navbar-collapse d-flex justify-content-between align-items-center"
                            id="navbar-mobile"
                        >
                            <NavbarBookmarks
                                sidebarVisibility={props.sidebarVisibility}
                                handleAppOverlay={props.handleAppOverlay}
                            />
                            {/* <div className="bookmark-wrapper">
                                <NavbarBookmarks
                                    sidebarVisibility={props.sidebarVisibility}
                                    handleAppOverlay={props.handleAppOverlay}
                                />
                            </div> */}
                            {!props.horizontal && (
                                <div className="bookmark-wrapper" />
                            )}
                            {props.horizontal ? (
                                <div className="logo d-flex align-items-center">
                                    {!customizer.logoExtended && (
                                        <LogoSVG color={logoColor} />
                                    )}
                                    {customizer.logoExtended && (
                                        <img
                                            alt=""
                                            src={`${MEDIA_LIBRARY}/?MediaFileID=${customizer.logoExtended}&width=50&height=50`}
                                        />
                                    )}
                                </div>
                            ) : null}
                            <NavbarUser
                                handleAppOverlay={props.handleAppOverlay}
                                changeCurrentLang={props.changeCurrentLang}
                                // userName={<UserName userdata={props.globalConfig?.UserDetails || {}} {...props} />}
                                userName={
                                    <UserName
                                        userdata={userDetails() || {}}
                                        {...props}
                                    />
                                }
                                userImg={
                                    props.user.login.values !== undefined &&
                                        props.user.login.values.loggedInWith !==
                                        "jwt" &&
                                        props.user.login.values.photoUrl
                                        ? props.user.login.values.photoUrl
                                        : user !== undefined && user.picture
                                            ? user.picture
                                            : false
                                }
                                loggedInWith={
                                    props.user !== undefined &&
                                        props.user.login.values !== undefined
                                        ? props.user.login.values.loggedInWith
                                        : null
                                }
                            />
                        </div>
                    </div>
                </div>
            </Navbar>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth,
        globalConfig: state.globalConfig,
    };
};

export default connect(mapStateToProps, {})(ThemeNavbar);
