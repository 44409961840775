import React from "react";
import { Button, DropdownItem, Input } from "reactstrap";
import { useSelector } from "react-redux";
import classnames from "classnames";
import "assets/scss/custom.scss";
import * as Icon from "react-feather";
import { isDefaultClass } from "../../../utility/utilities";
import { useHexCode } from "../../../hooks/useHexCode";

const CustomDropdownItem = (props) => {
    const accentColor = useSelector(
        (state) => state.customizer.customizer.accentColor,
    );

    return (
        <DropdownItem
            {...props}
            className="custom__dropdown"
            style={{ "--accentColor": useHexCode(accentColor) }}
        >
            {props.children}
        </DropdownItem>
    );
};

export default CustomDropdownItem;
