import React, { useEffect } from "react";
import themeConfig from "../configs/themeConfig";
import classnames from "classnames";
import _ from "lodash";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledButtonDropdown,
} from "reactstrap";
import { SHOW_PARTNER_BTN } from "../configs/config";
import { Sliders } from "react-feather";
import "./index.scss";
import { connect } from "react-redux";
import { setFullLayoutPartnerList } from "../redux/mixin/global";
import { genericQuery, Toast } from "../utility/helpers";

const FullPageLayout = ({ children, ...props }) => {
    const { globalConfig } = props;

    const handleOnClickPartner = (partner) => {
        props.loadSystemTemplateLogin(partner.URL);
    };
    const showPartnerBtn = SHOW_PARTNER_BTN === "1";

    useEffect(() => {
        if (showPartnerBtn && _.isEmpty(globalConfig.FullLayoutTheme)) {
            props.loadPartnerList();
        } else if (!showPartnerBtn) {
            props.loadSystemTemplateLogin(window.location.origin);
        }
    }, []);

    const accentColor =
        globalConfig?.FullLayoutTheme?.accentColor ||
        globalConfig?.PartnerDetails?.TemplateConfiguration?.Value?.accentColor;

    return (
        <div
            className={classnames(
                "full-layout wrapper bg-full-screen-image blank-page dark-layout",
                {
                    "layout-dark": themeConfig.layoutDark,
                },
            )}
        >
            <div className="app-content">
                <div className="content-wrapper">
                    <div className="content-body">
                        <div className="flexbox-container">
                            <main className="main w-100">{children}</main>
                        </div>
                    </div>
                </div>
            </div>
            {showPartnerBtn && (
                <div className="partnerSelector__QkPYzH">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle
                            className="btn-icon rounded-circle"
                            color={accentColor || "primary"}
                            size="sm"
                        >
                            <Sliders size={15} />
                        </DropdownToggle>
                        <DropdownMenu container="body">
                            {_.filter(
                                globalConfig?.PartnerList ?? [],
                                (partner) => partner.URL,
                            ).map((partner) => {
                                return (
                                    <DropdownItem
                                        key={`plist-${partner?.PartnerID}`}
                                        onClick={() =>
                                            handleOnClickPartner(partner)
                                        }
                                    >
                                        {partner?.CompanyName}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            )}
        </div>
    );
};

const loadPartnerList = () => {
    return (dispatch) =>
        moduleQuery({
            type: 1,
            callBack: (responseJSON) => {
                try {
                    if (responseJSON.status === 200) {
                        const { data } = responseJSON;
                        const { Status, Data } = data;

                        if (Status?.IsSuccess) {
                            dispatch({
                                type: "SET_FULLLAYOUT_PARTNER_LIST",
                                payload: {
                                    UpdateLocalStorage: true,
                                    PartnerList: Data,
                                },
                            });
                        } else {
                            Toast({
                                type: 4,
                                content: Status?.Message,
                            });
                        }
                    }
                } catch (Exception) {
                    Toast({
                        type: 4,
                        content: Exception,
                    });
                }
            },
        });
};

const loadSystemTemplateLogin = (URL) => {
    return (dispatch) =>
        moduleQuery({
            type: 2,
            URL,
            callBack: (responseJSON) => {
                try {
                    if (responseJSON.status === 200) {
                        const { data } = responseJSON;
                        const { Status, Data } = data;

                        if (Status?.IsSuccess) {
                            dispatch({
                                type: "SET_FULLLAYOUT_THEME",
                                payload: Data.TemplateConfiguration.Value,
                            });
                        }
                    }
                } catch (Exception) { }
            },
        });
};

const moduleQuery = (params = {}) => {
    const { type, formData, callBack } = params;

    const config = {
        ResponseSuccessCallback:
            callBack ||
            ((responseJSON) => {
                console.log(responseJSON);
            }),
        ResponseFailCallback:
            callBack ||
            ((responseJSON) => {
                console.log(responseJSON);
            }),
    };

    switch (type) {
        case 1: {
            config.Method = "GET";

            config.Data = {
                requestjson: {
                    Module: "system_logon_partners",
                    Parameters: {},
                },
            };
            config.Url = "/public_api";
            break;
        }

        case 2: {
            config.Method = "GET";

            config.Data = {
                requestjson: {
                    Module: "system_template_login",
                    Parameters: {
                        URL: params.URL,
                    },
                },
            };
            config.Url = "/public_api";
            break;
        }
        default:
    }

    if (type) {
        genericQuery(config);
    }
};

const mapStateToProps = (state) => ({
    globalConfig: state.globalConfig,
});

export default connect(mapStateToProps, {
    loadPartnerList,
    setFullLayoutPartnerList,
    loadSystemTemplateLogin,
})(FullPageLayout);
