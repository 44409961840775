import React, { useContext, useEffect, useState } from "react";
import { Input, UncontrolledTooltip } from "reactstrap";
import { useSelector } from "react-redux";
import "assets/scss/custom.scss";
import { useIntl } from "react-intl";
import { Eye } from "react-feather";
import PasswordChecker from "components/password-checker";
import { useHexCode } from "../../../hooks/useHexCode";
import StaticLabel from "../static-label-wrapper";

const CustomBasicInput = (props) => {
    const intl = useIntl();

    const accentColorState = useSelector(
        (state) => state.customizer.customizer.accentColor,
    );
    let accentValue;

    if (props.className) {
        // accentValue = props.className
    } else {
        accentValue = accentColorState;
    }

    if (props.type === "password") {
        return <CustomBasicInputPassword {...props} />;
    }

    // eslint-disable-next-line prefer-const
    let elementProps = { ...props };

    if (props.passwordChecker) {
        delete elementProps.passwordChecker;
    }

    return (
        <Input
            {...elementProps}
        // className={!isDefaultClass ? `${accentValue}__input` : 'custom__input'}
        // style={{ "--color": useHexCode(accentValue) }}
        />
    );
};

export default CustomBasicInput;

const CustomBasicInputPassword = (props) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [password, setPassword] = useState(null);
    const [isValidated, setIsValidated] = useState(false);

    const elementProps = {
        ...props,
        type: isShowPassword ? "text" : "password",
        placeholder: props.placeholder,
        style: { backgroundPosition: "right 1.95em center" },
        onChange: (e) => {
            (async () => {
                setPassword(e.target.value);
                props.onChange(e);
            })();
        },
    };

    if (props.passwordChecker) {
        delete elementProps.passwordChecker;
    }

    return (
        <>
            {props.passwordChecker &&
                document.getElementById(props.passwordChecker.container) && (
                    <PasswordChecker
                        password={password}
                        validatePassword={(isValid) => {
                            setIsValidated(isValid);

                            props.passwordChecker.setIsValidPassword(isValid);
                        }}
                        container={props.passwordChecker.container}
                        requirement={{
                            minimumChar: props.min,
                        }}
                    />
                )}

            <Input {...elementProps} />
            {isShowPassword ? (
                <>
                    <div id="hidePassword" className="showPassword-container">
                        <Eye
                            size={15}
                            className="cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsShowPassword(!isShowPassword);
                            }}
                        />
                    </div>
                    {" "}
                    <UncontrolledTooltip placement="top" target="hidePassword">
                        {/* {"Hide Password"} */}
                        <StaticLabel id="TooltipHidePassword" />
                    </UncontrolledTooltip>
                    {" "}
                </>
            ) : (
                <>
                    <div id="showPassword" className="showPassword-container">
                        <Eye
                            size={15}
                            className="cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsShowPassword(!isShowPassword);
                            }}
                        />
                    </div>
                    <UncontrolledTooltip placement="top" target="showPassword">
                        {/* {"Show Password"} */}
                        <StaticLabel id="TooltipShowPassword" />
                    </UncontrolledTooltip>
                </>
            )}
        </>
    );
};
