/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap";
import { genericQuery, Toast } from "utility/helpers";
import _ from "lodash";
import { languagePersistState } from "configs/config";
import { IntlContext } from "../../utility/context/Internationalization";

const UnauthorizedLaguageSelector = (props) => {
    const [languageList, setLanguageList] = useState([]);
    const { token, language } = props;
    // const [preferredLanguage, setPreferredLanguage] = useState({});

    const [state, setState] = useState({
        navbarSearch: false,
        langDropdown: false,
    });

    const handleLangDropdown = () =>
        setState({ langDropdown: !state.langDropdown });

    const getLanguages = () => {
        const config = {
            Url: "public_api",
            Method: "GET",
            Data: {
                requestjson: {
                    Module: "system_get_list",
                    Parameters: {
                        ObjectType: "LanguageList",
                    },
                },
            },
            ResponseSuccessCallback: (requestjson) => {
                const { data } = requestjson;
                const { Data } = data;
                const { List } = Data;
                setLanguageList(List);
            },
            ResponseFailCallback: (requestjson) => {
                Toast({
                    type: 4,
                    content: requestjson?.data?.Data?.Status?.Message,
                });
            },
        };

        genericQuery(config);
    };

    const renderLanguageSelection = (context) => {
        if (!_.isEmpty(languageList)) {
            return (
                <DropdownMenu right>
                    {languageList.map((language) => (
                        <DropdownItem
                            tag="a"
                            key={language.LanguageID}
                            onClick={(e) => {
                                if (props.language && token) {
                                    language = {
                                        ...language,
                                        UserActivationLanguage: {
                                            ...props.language,
                                            Token: token,
                                        },
                                    };
                                }
                                context.switchLanguage(language);

                                if (
                                    Object.keys(languagePersistState()).length <
                                    1
                                ) {
                                    const { LanguageID, Name, TwoLetterCode } =
                                        language;

                                    languagePersistState({
                                        LanguageID,
                                        Name,
                                        TwoLetterCode,
                                    });
                                }
                            }}
                        >
                            <ReactCountryFlag
                                className="country-flag"
                                countryCode={language.TwoLetterCode}
                                svg
                            />
                            <span className="ml-1">{language.Name}</span>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            );
        }
    };

    useEffect(() => {
        getLanguages();
    }, []);

    // useEffect(() => {
    //     if (!_.isEmpty(languageList) && lang) {
    //         languageList.forEach((language) => {
    //             if (language.TwoLetterCode === lang) {
    //                 setPreferredLanguage(language);
    //             }
    //         });
    //     }
    // }, [lang]);

    return (
        <ul className="nav navbar-nav navbar-nav-user float-right">
            <IntlContext.Consumer>
                {(context) => {
                    return (
                        <Dropdown
                            tag="li"
                            className="dropdown-language nav-item"
                            isOpen={state.langDropdown}
                            toggle={handleLangDropdown}
                        >
                            <DropdownToggle tag="a" className="nav-link">
                                <ReactCountryFlag
                                    className="country-flag"
                                    countryCode={context.locale}
                                    svg
                                />
                                <span
                                    className="d-sm-inline-block d-none text-capitalize align-middle ml-50"
                                // style={{ color: "#c2c6dc" }}
                                >
                                    {context.language}
                                </span>
                            </DropdownToggle>
                            {languageList && renderLanguageSelection(context)}
                        </Dropdown>
                    );
                }}
            </IntlContext.Consumer>
        </ul>
    );
};

export default UnauthorizedLaguageSelector;
