import React from "react";
import classnames from "classnames";
import { Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import "./spinner.scss";

const CustomSpinner = ({
    overlay = false,
    backdrop = false,
    alignment = "",
    ...props
}) => {
    const accentColor = useSelector(
        (state) => state.customizer.customizer.accentColor,
    );

    return (
        <div
            className={classnames("custom-spinner", {
                "text-center": alignment === "center",
                overlayed: overlay,
                backdrop,
            })}
        >
            <Spinner
                color={accentColor}
                {...props}
                style={{ color: accentColor }}
            />
        </div>
    );
};

export default CustomSpinner;
