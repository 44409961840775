import { genericQuery } from "utility/helpers";
import { responseCallbackConstant } from "constants/responseCallbackConstant";
import { MEDIA_URL } from "../configs/config";

const globalService = {
    delete: (params) => {
        const { data, callback } = params;
        const formData = new FormData();
        formData.set("RID", `${data}`);

        const config = {
            ...responseCallbackConstant(callback),
            Method: "DELETE",
            Url: "/delete_by_rid",
            Data: { requestjson: formData },
        };

        genericQuery(config);
    },
    update: (params) => {
        const { AttributeName, RID, oldValue, newValue, callback } = params;

        const formData = new FormData();

        formData.set("RID", `${RID}.${AttributeName}`);
        formData.set("OldValue", oldValue);
        formData.set("Value", newValue);

        const config = {
            ...responseCallbackConstant(callback),
            Method: "POST",
            Url: "/update_by_rid",
            Data: { requestjson: formData },
        };

        genericQuery(config);
    },
    postMediaLibrary: (params) => {
        const { file, partnerID, notificationID, mediaTypeID, callback } =
            params;
        const formData = new FormData();

        formData.append("File", file);
        formData.append("PartnerID", partnerID);
        formData.append("NotificationID", notificationID);
        formData.append("MediaTypeID", mediaTypeID);

        const config = {
            ...responseCallbackConstant(callback),
            BaseURL: MEDIA_URL,
            Url: "/medialibrary/",
            Method: "POST",
            Data: { requestjson: formData },
        };

        genericQuery(config);
    },
    getCreditCardBalance: (params) => {
        const { callback } = params;

        const config = {
            ...responseCallbackConstant(callback),
            Method: "GET",
            Data: {
                requestjson: {
                    Module: "financial_get_usertransactions",
                    Parameters: {
                        CreditBalanceOnly: 1,
                    },
                },
            },
        };

        genericQuery(config);
    },
};

export default globalService;
