import React from "react";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";
import { isDefaultClass } from "utility/utilities";
import { useHexCode } from "hooks/useHexCode";

const CustomBadge = (props) => {
    const accentColorState = useSelector(
        (state) => state?.customizer?.customizer?.accentColor,
    );

    let classValue;
    if (props.className) classValue = props.className;

    return (
        <Badge
            {...props}
            className={`${
                !isDefaultClass(useHexCode(accentColorState)) && "custom"
            } ${classValue}`}
            style={{ backgroundColor: useHexCode(accentColorState) }}
        >
            {props.children}
        </Badge>
    );
};

export default CustomBadge;
