import { uniqBy } from "lodash";

const initialState = {
    mails: [],
    params: null,
    query: null,
    selectedEmails: [],
    filteredMails: [],
    currentMail: {},
    currentGroup: {},
    userList: [],
    summary: {},
    groups: {},
    formData: {
        // Attachments:{
        // 	DT_Created: "2021-05-04T18:56:29"
        // 	MediaFileID: "05E16EA9-EE20-4E9D-909D-A0A11491E5FF"
        // 	Name: "untitled"
        // 	NotificationAttachmentID: 19
        // 	NotificationID: 612
        // 	RID: 5500000000000020
        // 	Size: 93873
        // }
    },
    partnerList: [],
    status: {
        type: "",
        isLoading: false
    },
    currentNotificationID: null,
    messageRecipient: [],
    asReply: false,
    currentNotificationGroupID: null,
};

const emails = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MAILS":
            return {
                ...state,
                mails: action.mails,
                // mailPageSummary: action.pageSummary,
                status: {
                    isLoading: true
                }
            };
        //    TODO:: WIP
        case "UPDATED_MAILS":
            return {
                ...state,
                mails: uniqBy([...state?.mails, ...action?.mails || []], "UserNotificationID"),
            };
        
        case "SET_MAILS":
            return {
                ...state,
                mails: action.mails,
                status: {
                    isLoading: true
                }
            };

        case "SET_LOADING":
            return {
                ...state,
                status: {
                    isLoading: action.payload
                }
            };

        case "RESET_MAILS":
            return { ...state, mails: [] };

        case "GET_NOTIFICATION_USER_LIST":
            let userList = [...state.userList];
            // TODO:: Add flagging if selected to avoid duplication
            userList = action.userList;

            return { ...state, userList };

        case "SELECT_CURRENT_MAIL":
            return {
                ...state,
                currentMail: action.payload,
            };

        case "SET_CURRENT_GROUP":
            return { ...state, currentGroup: action.payload };

        case "RESET_CURRENT_GROUP":
            return { ...state, currentGroup: {} };

        case "SET_EMAIL_FORM_DATA":

            if (action?.recipient) {
                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        Recipient: {
                            ...state.formData.Recipient,
                            To: [
                                ...state.formData.Recipient.To,
                                ...action.recipient,
                            ],
                        },
                    },
                };
            }

            return {
                ...state,
                formData: action.formData,
            };

        // dispatch
        // case "SET_EMAIL_FORM_DATA_FROM_GROUP":
        // 	return {
        // 		...state,
        // 		formData: {
        // 			...state.formData,
        // 			Recipient: [
        // 				action.formDataRecipient
        // 			]
        // 		},
        // 	};

        case "SET_STARRED":
            const hello = (state.mails.find(
                (i) => i.NotificationID === action.NotificationID,
            ).IsMarked = !state.mails.find(
                (i) => i.NotificationID === action.NotificationID,
            ).IsMarked);

            return { ...state };

        case "SET_CURRENT_MAIL_STARRED":

            return {
                ...state,
                currentMail: {
                    ...state.currentMail,
                    IsMarked: !state.currentMail.IsMarked
                }
            };

        case "SEARCH_MAIL":
            const filteredMails = state.mails.filter((val) => {
                if (action.query.length > 0) {
                    return (
                        val.sender_name.toLowerCase().includes(action.query) ||
                        val.sender.toLowerCase().includes(action.query) ||
                        val.message.includes(action.query) ||
                        val.subject.toLowerCase().includes(action.query)
                    );
                }

                return state.mails;
            });

            return { ...state, query: action.query, filteredMails };

        case "SELECT_MAIL":
            const mailId = action.id;
            let selectedEmails = [...state.selectedEmails];

            if (selectedEmails.find((id) => id === mailId) !== undefined) {
                selectedEmails = selectedEmails.filter((id) => id !== mailId);
            } else {
                selectedEmails = [...selectedEmails, mailId];
            }

            return { ...state, selectedEmails };

        case "SELECT_ALL_MAILS":
            const arr = Object.keys(state.mails).map((k) => {
                return state.mails[k];
            });

            const selectedEmailsID = arr.map((mail) => mail.UserNotificationID);

            return {
                ...state,
                selectedEmails: selectedEmailsID,
            };

        case "DESELECT_ALL_MAILS": {
            return {
                ...state,
                selectedEmails: [],
            };
        }

        case "MOVE_MAIL":
            return { ...state, selectedEmails: [] };

        case "SET_UNREAD":
            const mailIndex = state.mails.findIndex(
                (mail) => mail.RID === action.RID,
            );

            if (mailIndex !== -1) {
                state.mails[mailIndex].IsSeen = action.unreadFlag;
            }
            // const updatedEmails = action.notificationID.forEach((mailId) => {
            //     const mailIndex = state.mails.findIndex(
            //         (mail) => mail.NotificationID === mailId,
            //     );
            //
            //     if (mailIndex !== -1) {
            //         state.mails[mailIndex].unread = action.unreadFlag;
            //     }
            //     state.selectedEmails = [];
            // });

            return { ...state, mails: state.mails };

        case "CHANGE_FILTER":
            state.params = action.filter;

            return { ...state };
    
        case "GET_NOTIFICATIONS_SUMMARY":
            return { ...state, summary: action.summary[0] };

        case "SET_AS_REPLY":
            return { ...state, asReply: action.asReply };

        case "GET_NOTIFICATION_GROUPS":
            return { ...state, groups: action.groups };

        case "CREATE_NOTIFICATION_GROUPS":


            return { ...state, groups: uniqBy([action.groups, ...state?.groups], 'PartnerNotificationGroupID') };

        // case "UPDATE_NOTIFICATION_GROUP":
        //
        // 	const groupIndex = state.group.findIndex(
        // 		(group) => group.Name === action.NewValue,
        // 	);
        // 	if (groupIndex !== -1) {
        // 		state.group[groupIndex].Name = action.NewValue;
        // 	}
        // 	return { ...state, groups: }
        case "GET_NOTIFICATION_PARTNER_LIST":
            return { ...state, partnerList: action.partnerList };

        case "GET_CURRENT_ID":
            return { ...state, currentNotificationID: action.notificationID };

        case "GET_CURRENT_NOTIFICATION_GROUP_ID":
            return {
                ...state,
                currentNotificationGroupID: action.id,
                isSelectPartnerGroup: action.isSelectPartnerGroup
            };

        case "ADD_MESSAGE_RECIPIENT":
            return { ...state, messageRecipient: action.recipient };

        case "REMOVE_MESSAGE_RECIPIENT":
            // CHECK ID EQUAL TO ANY SELECTED RECIPIENT, THEN REMOVE
            return { ...state, messageRecipient: action.recipient };

        //		reset recipient

        //		TODO:: WIP- Attachments
        case "ADD_ATTACHMENT":

            return {
                ...state,
                formData: {
                    ...state.formData,
                    Attachments: [
                        action?.payload,
                        ...(state?.formData?.Attachments || []),
                    ],
                },
            };

        case "DELETE_ATTACHMENT":
            return {
                ...state,
                formData: {
                    ...state.formData,
                    Attachments: state.formData.Attachments.filter(
                        (x) => x.NotificationAttachmentRID !== action.payload,
                    ),
                },
            };

        case "DELETE_MAILS":
            const toRemoveMap = action.payload.reduce((memo, item) => ({
                ...memo, [item]: true
            }), {});

            const filteredArray = state.mails.filter(x => !toRemoveMap[x.UserNotificationID]);

            return {
                ...state,
                mails: filteredArray
            };

        default:
            return { ...state };
    }
};

export default emails;
