export const register = (state = {}, action) => {
    switch (action.type) {
        case "SIGNUP": {
            return { ...state, values: action.payload }
        }
        default: {
            return state
        }
    }
}
