import React from "react";
import * as Icon from "react-feather";
import { BiUserVoice } from "react-icons/bi";
import { IoTicketOutline } from "react-icons/io5";
import { HiOutlineTemplate } from "react-icons/hi";
import { partnerDetails } from "./config";

const navigationConfig = [
    {
        type: "groupHeader",
        groupTitle: "NAVIGATION",
    },
    {
        id: "5",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Home size={20} />,
        key: "UserMenuDashboard",
        // permissions: [],
        navLink: "/",
    },
    {
        id: "13",
        title: "Home",
        type: "item",
        icon: <Icon.Home size={20} />,
        key: "UserMenuHome",
        // permissions: [],
        navLink: "/",
    },
    {
        id: "22",
        title: "Franchisee",
        type: "item",
        key: "UserMenuFranchiseeList",
        icon: <Icon.Users size={20} />,
        // permissions: [],
        navLink: "/franchise",
        collapsed: false,
    },
    {
        id: "27",
        title: "Franchisee Details",
        type: "item",
        key: "UserMenuFranchiseeDetail",
        icon: <Icon.BarChart2 size={20} />,
        isDetails: true,
        // permissions: [],
        navLink: `/franchise/${partnerDetails().PartnerID}`,
    },
    {
        id: "26",
        title: "Account",
        type: "item",
        key: "UserMenuAccountList",
        icon: <Icon.Briefcase size={20} />,
        // permissions: [],
        navLink: "/account",
    },
    {
        id: "70",
        title: "Account Details",
        type: "item",
        key: "UserMenuAccountDetail",
        icon: <Icon.BarChart2 size={20} />,
        isDetails: true,
        // permissions: [],
        navLink: `/account/${partnerDetails().PartnerID}`,
    },
    {
        id: "71",
        title: "Advertiser Details",
        type: "item",
        key: "UserMenuSupplierDetail",
        icon: <Icon.BarChart2 size={20} />,
        // permissions: [],
        isDetails: true,
        navLink: `/advertiser/${partnerDetails().PartnerID}`,
    },
    {
        id: "6",
        title: "Advertiser",
        key: "UserMenuSupplierList",
        type: "item",
        icon: <BiUserVoice size={26} />,
        // permissions: [],
        navLink: "/advertiser",
        collapsed: true,
    },
    {
        id: "250",
        title: "Account Groups",
        type: "item",
        icon: <Icon.Users size={20} />,
        key: "UserMenuAccountGroups",
        navLink: "/account-groups",
        collapsed: true,
    },
    {
        id: "142",
        title: "Business Type",
        type: "item",
        key: "UserMenuBusinessType",
        icon: <Icon.Briefcase size={20} />,
        // permissions: [],
        navLink: "/business-type",
        collapsed: true,
    },
    {
        id: "7",
        title: "Brands",
        type: "item",
        key: "UserMenuBrands",
        icon: <Icon.Tag size={20} />,
        // permissions: [],
        navLink: "/brand",
        collapsed: true,
    },
    {
        id: "311",
        title: "Campaigns",
        type: "item",
        key: "UserMenuCampaign",
        icon: <IoTicketOutline size={20} />,
        // permissions: [],
        navLink: "/coupons",
        collapsed: true,
    },
    {
        id: "61",
        title: "Product Line",
        type: "item",
        key: "UserMenuProductLine",
        icon: <Icon.Package size={20} />,
        // permissions: [],
        navLink: "/product-line",
        collapsed: true,
    },
    {
        id: "67",
        title: "Accounts",
        type: "item",
        key: "UserMenuAccountBrandProductLine",
        icon: <Icon.Briefcase size={20} />,
        // permissions: [],
        navLink: "/account/brand/product-line",
    },
    {
        id: "152",
        title: "Notifications",
        type: "item",
        key: "UserMenuNotifications",
        icon: <Icon.Bell size={20} />,
        navLink: "/notifications",
        collapsed: true,
        // TODO:: wip notification sidebar tag
        // badge: "success",
        // badgeText: "3",
    },
    {
        id: "9",
        title: "Orders",
        type: "item",
        key: "UserMenuOrders",
        icon: <Icon.ShoppingBag size={20} />,
        // permissions: [],
        navLink: "/order",
        collapsed: true,
    },
    {
        id: "198",
        title: "Templates",
        type: "item",
        key: "UserMenuTemplates",
        icon: <Icon.Image size={20} />,
        navLink: "/campaign-templates",
    },
    {
        id: "227",
        title: "Translation",
        type: "item",
        icon: <Icon.Globe size={20} />,
        key: "UserMenuTranslation",
        // permissions: [],
        navLink: "/translation",
    },
    {
        id: "230",
        title: "Email Templates",
        type: "item",
        icon: <Icon.Image size={20} />,
        key: "UserMenuEmailTemplates",
        navLink: "/email-templates",
    },
    {
        id: "318",
        title: "Voucher Templates",
        type: "item",
        icon: <HiOutlineTemplate size={20} />,
        key: "UserMenuCouponTemplates",
        navLink: "/coupon-templates",
    },
];

export default navigationConfig;
