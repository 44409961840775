import { AxiosRequestConfig } from "axios";
import { getAccessToken } from "../configs/config";

export function withAccessToken(
    config: AxiosRequestConfig,
): AxiosRequestConfig {
    const token = getAccessToken();

    if (token) {
        return {
            ...config,
            headers: {
                ...config.headers,
                token,
            },
        };
    }

    return config;
}

export default null;
