import { genericQuery } from "utility/helpers";
import { responseCallbackConstant } from "constants/responseCallbackConstant";

const systemGetListService = {
    productLineList: (params) => {
        const config = {
            ...responseCallbackConstant(params.callback),
            Method: "GET",
            Data: {
                requestjson: {
                    Module: "system_get_list",
                    Parameters: {
                        ObjectType: "ProductLineList",
                        ...params.data
                    }
                }
            }
        };

        genericQuery(config);
    },
    brandList: (params) => {
        const config = {
            ...responseCallbackConstant(params.callback),
            Method: "GET",
            Data: {
                requestjson: {
                    Module: "system_get_list",
                    Parameters: {
                        ObjectType: "BrandList",
                        ...params.data
                    }
                }
            }
        };

        genericQuery(config);
    },
    supplierBrandList: (params) => {
        const config = {
            ...responseCallbackConstant(params.callback),
            Method: "GET",
            Data: {
                requestjson: {
                    Module: "system_get_list",
                    Parameters: {
                        ObjectType: "SupplierBrandList",
                        ...params.data
                    }
                }
            }
        };

        genericQuery(config);
    }
};

export default systemGetListService;