import React, { useContext, useState } from "react";
import classNames from "classnames";
import { ChromePicker } from "react-color";
import useSelector from "hooks/useSelector";
import { changeMenuColor } from "redux/actions/customizer";
import { useDispatch } from "react-redux";
import { X } from "react-feather";
import StaticLabel from "components/customs/static-label-wrapper";
import { CustomizerContext } from "./Context";

export default function MenuColor() {
    const { menuTheme, accentColor, menuColor } = useSelector(
        (state) => state.customizer.customizer,
    );
    const dispatch = useDispatch();
    const { state, stateDispatch } = useContext(CustomizerContext);
    const { colorPickerOpened } = state;

    const defaultColors = [
        "default",
        "primary",
        "danger",
        "info",
        "warning",
        "dark",
        "success",
    ];

    return (
        <div id="customizer-menu-color" className="menu-theme-colors">
            <h5>
                <StaticLabel id="MenuColorTitle" />
            </h5>
            <div className="d-flex">
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-primary", {
                        selected: menuTheme === "primary",
                    })}
                    onClick={() => dispatch(changeMenuColor("primary"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-success", {
                        selected: menuTheme === "success",
                    })}
                    onClick={() => dispatch(changeMenuColor("success"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-danger", {
                        selected: menuTheme === "danger",
                    })}
                    onClick={() => dispatch(changeMenuColor("danger"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-info", {
                        selected: menuTheme === "info",
                    })}
                    onClick={() => dispatch(changeMenuColor("info"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-warning", {
                        selected: menuTheme === "warning",
                    })}
                    onClick={() => dispatch(changeMenuColor("warning"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box bg-dark", {
                        selected: menuTheme === "dark",
                    })}
                    onClick={() => dispatch(changeMenuColor("dark"))}
                />
                <div
                    aria-hidden="true"
                    className={classNames("color-box", {
                        selected: ![
                            "primary",
                            "danger",
                            "info",
                            "warning",
                            "dark",
                            "success",
                        ].includes(menuTheme),
                    })}
                    style={{
                        position: "relative",
                        backgroundImage:
                            defaultColors.includes(menuTheme) &&
                            "linear-gradient( 90deg , rgb(131, 58, 180) 0%, rgb(253, 29, 29) 50%, rgb(252, 176, 69) 100%)",
                        backgroundColor: menuTheme,
                    }}
                    onClick={() => {
                        stateDispatch({
                            type: "SET_MENU_COLOR_PCIKER_OPENED",
                            payload: !colorPickerOpened.menuColor,
                        });
                    }}
                />

                {colorPickerOpened.menuColor && (
                    <div className="custom-menu-container">
                        <div
                            aria-hidden="true"
                            className="close-icon cursor-pointer float-right bg-white color-picker-margin"
                            onClick={() =>
                                stateDispatch({
                                    type: "SET_MENU_COLOR_PCIKER_OPENED",
                                    payload: false,
                                })
                            }
                        >
                            <X size={18} />
                        </div>

                        <ChromePicker
                            color={menuTheme}
                            onChangeComplete={(color) => {
                                dispatch(changeMenuColor(color.hex));
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
