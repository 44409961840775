/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
import axios from "axios";
import moment from "moment-timezone";
import _ from "lodash";
import { toast } from "react-toastify";
import { apiUpdate } from "services/apiService";
import { store } from "redux/storeConfig/store";
// import axiosRetry from "axios-retry";
import {
    accessToken,
    API_URL,
    parentAccessToken,
    refreshToken,
    userDetails,
    userMenu,
    userRoles,
} from "../configs/config";
import { parseNumber } from "./parsers";

const expiredTokenRequestRefreshAndRetry = async (error, axiosInstance) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 403) {
        const refreshInstance = axios.create({
            baseURL: API_URL,
        });

        refreshInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            // eslint-disable-next-line no-shadow
            async (error) => {
                // const originalRequest = error.config
                if (error.response && error.response.status === 401) {
                    // eslint-disable-next-line no-alert
                    alert("Token has expired, You will be logged out");
                    window.location.href = "/logout";
                }

                return Promise.reject(error);
            },
        );

        if (refreshToken()) {
            const response = await refreshInstance.post("/refresh_token", {
                RefreshToken: refreshToken(),
            });

            const { AccessToken } = response.data.Data;

            if (store) {
                store.dispatch({
                    type: "SET_ACCESS_TOKEN",
                    payload: {
                        UpdateLocalStorage: true,
                        AccessToken,
                    },
                });
            } else {
                accessToken(AccessToken);
            }

            originalRequest.headers = {
                token: AccessToken,
            };

            if (originalRequest.method === "post") {
                originalRequest.data = JSON.parse(originalRequest.data);
            }

            return axiosInstance.request(originalRequest);
        }
    }

    return Promise.reject(error);
};

const genericQuery = (config) => {
    const { globalConfig } = store.getState();

    const newCancelToken = axios.CancelToken;
    const newSource = newCancelToken.source();

    if (config?.CancelToken) {
        config.CancelToken.cancel(
            "Cancel request because another request will be made.",
        );
    }

    const requestInstance = axios.create({
        baseURL: config?.BaseURL ? config?.BaseURL : API_URL,
        cancelToken: newSource.token,
        headers: {
            token: config?.Token || globalConfig.AccessToken || accessToken(),
            "Content-Type": "multipart/form-data",
        },
        responseType: config?.ResponseType ? config?.ResponseType : "json",
        onUploadProgress: config?.OnUploadProgress
            ? config?.OnUploadProgress
            : null,
    });

    requestInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) =>
            expiredTokenRequestRefreshAndRetry(error, requestInstance),
    );

    const processData = () => {
        if (config.Method === "DELETE") {
            return {
                data: config.Data.requestjson,
            };
        }

        return config.Data.requestjson || config.Data;
    };

    const processQueryParams = () => {
        if (config.Data.requestjson) {
            return `requestjson=${JSON.stringify(config.Data.requestjson)}`;
        }

        return new URLSearchParams(config.Data).toString();
    };

    const processRequestURL = () => {
        if (config.Method === "GET") {
            if (config.Url) {
                return `${config.Url}?${processQueryParams()}`;
            }

            return `/single_api?${processQueryParams()}`;
        }

        if (config.Url) {
            return config.Url;
        }

        return "/single_api";
    };

    requestInstance[config.Method.toLowerCase()](
        processRequestURL(),
        processData(),
    )
        .then((response) => {
            config.ResponseSuccessCallback(response, config);
        })
        .catch((thrown) => {
            config.ResponseFailCallback(thrown, config);
        });

    if (config.CancelToken) {
        config.CancelToken = newSource;
    }

    return config;
};

const promiseGenericQuery = (config) => {
    const { globalConfig } = store.getState();

    const newCancelToken = axios.CancelToken;
    const newSource = newCancelToken.source();

    if (config?.CancelToken) {
        config.CancelToken.cancel(
            "Cancel request because another request will be made.",
        );
    }

    const requestInstance = axios.create({
        baseURL: config?.BaseURL ? config?.BaseURL : API_URL,
        cancelToken: newSource.token,
        headers: {
            token: config?.Token || globalConfig.AccessToken || accessToken(),
        },
        responseType: config?.ResponseType ? config?.ResponseType : "json",
        onUploadProgress: config?.OnUploadProgress
            ? config?.OnUploadProgress
            : null,
    });

    requestInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) =>
            expiredTokenRequestRefreshAndRetry(error, requestInstance),
    );

    const processData = () => {
        if (config.Data.requestjson instanceof FormData) {
            // eslint-disable-next-line prefer-const
            let data = {};

            // eslint-disable-next-line no-restricted-syntax
            for (const key of config.Data.requestjson.keys()) {
                data[key] = config.Data.requestjson.get(key);
            }

            if (config.Method === "DELETE") {
                return {
                    data,
                };
            }

            return data;
        }

        return config.Data;
    };

    const processQueryParams = () => {
        if (config.Data.requestjson) {
            return `requestjson=${JSON.stringify(config.Data.requestjson)}`;
        }

        return new URLSearchParams(config.Data).toString();
    };

    const processRequestURL = () => {
        if (config.Method === "GET") {
            if (config.Url) {
                return `${config.Url}?${processQueryParams()}`;
            }

            return `/single_api?${processQueryParams()}`;
        }

        if (config.Url) {
            return config.Url;
        }

        return "/single_api";
    };

    return requestInstance[config.Method.toLowerCase()](
        processRequestURL(),
        processData(),
    );
};

const Toast = (config = {}) => {
    const { customizer } = store.getState();
    const { theme } = customizer.customizer;

    let configColor = theme;

    const type = [
        toast.TYPE.DEFAULT,
        toast.TYPE.SUCCESS,
        toast.TYPE.INFO,
        toast.TYPE.WARNING,
        toast.TYPE.ERROR,
    ][config.type || 0];

    if (theme === "semi-dark") {
        configColor = "colored";
    }

    toast(config.content, {
        position: config.position || "top-right",
        autoClose: config.autoClose !== undefined ? config.autoClose : 5000,
        hideProgressBar: config.hideProgressBar || false,
        closeOnClick: config.closeOnClick || true,
        pauseOnHover: config.pauseOnHover || true,
        draggable: config.draggable || true,
        progress: config.progress || undefined,
        type,
        theme: configColor || "light",
    });
};

const getArrayValueByKey = (params) => {
    const { Key, Value, List } = params;

    return List?.find((item) => {
        return item[Key] == Value;
    });
};

const mapArraySetNewValue = ({ array, newData, conditionObject }) => {
    return _.map(array, (item) => {
        let conditionIterator = 0;

        _.mapValues(conditionObject, (condition) => {
            if (item?.[condition.key] === condition.value) {
                ++conditionIterator;
            }
        });

        if (conditionIterator === _.keys(conditionObject).length) {
            item = {
                ...item,
                ...newData,
            };
        }

        return item;
    });
};

const listToTree = (data, options) => {
    options = options || {};

    const ID_KEY = options.idKey || "id";
    const PARENT_KEY = options.parentKey || "parent";
    const CHILDREN_KEY = options.childrenKey || "children";
    const { setEmptyChildrenToNull } = options;
    let tree = [];
    const childrenOf = {};
    let item;
    let id;
    let parentId;

    for (let i = 0, { length } = data; i < length; i++) {
        item = data[i];
        id = item[ID_KEY];
        item.title = item.Name;
        item.label = item.Name;
        item.value = id;
        parentId = item[PARENT_KEY] || 0;
        childrenOf[id] = childrenOf[id] || [];
        item[CHILDREN_KEY] = childrenOf[id];

        if (parentId !== 0) {
            childrenOf[parentId] = childrenOf[parentId] || [];
            childrenOf[parentId].push(item);
        } else {
            item.placement = tree.length;

            tree.push(item);
        }
    }

    if (setEmptyChildrenToNull) {
        tree = listToTreeModifier(tree, CHILDREN_KEY, {
            setEmptyChildrenToNull,
        });
    }

    return tree;
};

const listToTreeModifier = (
    treeArray = [],
    CHILDREN_KEY,
    modifiers = { setEmptyChildrenToNull: false },
) => {
    const { setEmptyChildrenToNull } = modifiers;

    return (
        treeArray ||
        [].map((treeItem) => {
            const children = treeItem[CHILDREN_KEY];

            if (setEmptyChildrenToNull) {
                if (Array.isArray(children) && children.length === 0) {
                    treeItem[CHILDREN_KEY] = null;
                } else {
                    treeItem[CHILDREN_KEY] = listToTreeModifier(
                        treeItem[CHILDREN_KEY],
                        CHILDREN_KEY,
                        modifiers,
                    );
                }
            }

            return treeItem;
        })
    );
};

const addNodeUnderParent = ({
    treeData,
    newNode,
    parentKey = null,
    getNodeKey,
    ignoreCollapsed = true,
    expandParent = false,
    addAsFirstChild = false,
}) => {
    if (parentKey === null) {
        return addAsFirstChild
            ? {
                treeData: [newNode, ...(treeData || [])],
                treeIndex: 0,
            }
            : {
                treeData: [...(treeData || []), newNode],
                treeIndex: (treeData || []).length,
            };
    }

    let insertedTreeIndex = null;
    let hasBeenAdded = false;

    const changedTreeData = treeMap({
        treeData,
        getNodeKey,
        ignoreCollapsed,
        callback: ({ node, treeIndex, path }) => {
            const key = path ? path[path.length - 1] : null;

            // Return nodes that are not the parent as-is
            if (hasBeenAdded || key !== parentKey) {
                return node;
            }
            hasBeenAdded = true;

            const parentNode = {
                ...node,
            };

            if (expandParent) {
                parentNode.expanded = true;
            }

            // If no children exist yet, just add the single newNode
            if (!parentNode.children) {
                insertedTreeIndex = treeIndex + 1;

                return {
                    ...parentNode,
                    children: [newNode],
                };
            }

            if (typeof parentNode.children === "function") {
                throw new Error("Cannot add to children defined by a function");
            }

            let nextTreeIndex = treeIndex + 1;

            for (let i = 0; i < parentNode.children.length; i += 1) {
                nextTreeIndex +=
                    1 +
                    getDescendantCount({
                        node: parentNode.children[i],
                        ignoreCollapsed,
                    });
            }

            insertedTreeIndex = nextTreeIndex;

            const children = addAsFirstChild
                ? [newNode, ...parentNode.children]
                : [...parentNode.children, newNode];

            return {
                ...parentNode,
                children,
            };
        },
    });

    if (!hasBeenAdded) {
        throw new Error("No node found with the given key.");
    }

    return {
        treeData: changedTreeData,
        treeIndex: insertedTreeIndex,
    };
};

const treeMap = ({
    treeData,
    getNodeKey,
    callback,
    ignoreCollapsed = true,
}) => {
    if (!treeData || treeData.length < 1) {
        return [];
    }

    return mapDescendants({
        callback,
        getNodeKey,
        ignoreCollapsed,
        isPseudoRoot: true,
        node: { children: treeData },
        currentIndex: -1,
        path: [],
        lowerSiblingCounts: [],
    }).node.children;
};

const mapDescendants = ({
    callback,
    getNodeKey,
    ignoreCollapsed,
    isPseudoRoot = false,
    node,
    parentNode = null,
    currentIndex,
    path = [],
    lowerSiblingCounts = [],
}) => {
    const nextNode = { ...node };

    // The pseudo-root is not considered in the path
    const selfPath = isPseudoRoot
        ? []
        : [...path, getNodeKey({ node: nextNode, treeIndex: currentIndex })];

    const selfInfo = {
        node: nextNode,
        parentNode,
        path: selfPath,
        lowerSiblingCounts,
        treeIndex: currentIndex,
    };

    // Return self on nodes with no children or hidden children
    if (
        !nextNode.children ||
        (nextNode.expanded !== true && ignoreCollapsed && !isPseudoRoot)
    ) {
        return {
            treeIndex: currentIndex,
            node: callback(selfInfo),
        };
    }

    // Get all descendants
    let childIndex = currentIndex;
    const childCount = nextNode.children.length;

    if (typeof nextNode.children !== "function") {
        nextNode.children = nextNode.children.map((child, i) => {
            const mapResult = mapDescendants({
                callback,
                getNodeKey,
                ignoreCollapsed,
                node: child,
                parentNode: isPseudoRoot ? null : nextNode,
                currentIndex: childIndex + 1,
                lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
                path: selfPath,
            });
            childIndex = mapResult.treeIndex;

            return mapResult.node;
        });
    }

    return {
        node: callback(selfInfo),
        treeIndex: childIndex,
    };
};

const getDescendantCount = ({ node, ignoreCollapsed = true }) => {
    return (
        getNodeDataAtTreeIndexOrNextIndex({
            getNodeKey: () => { },
            ignoreCollapsed,
            node,
            currentIndex: 0,
            targetIndex: -1,
        }).nextIndex - 1
    );
};

const getNodeDataAtTreeIndexOrNextIndex = ({
    targetIndex,
    node,
    currentIndex,
    getNodeKey,
    path = [],
    lowerSiblingCounts = [],
    ignoreCollapsed = true,
    isPseudoRoot = false,
}) => {
    // The pseudo-root is not considered in the path
    const selfPath = !isPseudoRoot
        ? [...path, getNodeKey({ node, treeIndex: currentIndex })]
        : [];

    // Return target node when found
    if (currentIndex === targetIndex) {
        return {
            node,
            lowerSiblingCounts,
            path: selfPath,
        };
    }

    // Add one and continue for nodes with no children or hidden children
    if (!node.children || (ignoreCollapsed && node.expanded !== true)) {
        return { nextIndex: currentIndex + 1 };
    }

    // Iterate over each child and their descendants and return the
    // target node if childIndex reaches the targetIndex
    let childIndex = currentIndex + 1;
    const childCount = node.children.length;

    for (let i = 0; i < childCount; i += 1) {
        const result = getNodeDataAtTreeIndexOrNextIndex({
            ignoreCollapsed,
            getNodeKey,
            targetIndex,
            node: node.children[i],
            currentIndex: childIndex,
            lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
            path: selfPath,
        });

        if (result.node) {
            return result;
        }

        childIndex = result.nextIndex;
    }

    // If the target node is not found, return the farthest traversed index
    return { nextIndex: childIndex };
};

const hasUserHaveThisRole = ({ roleID = [], some = false, every = false }) => {
    const _userRoles = userRoles() || [];

    if (some) {
        return _userRoles.some((userRole) => roleID.includes(userRole.RoleID));
    }

    if (every) {
        return _userRoles.every((userRole) => roleID.includes(userRole.RoleID));
    }

    return false;
};

const hasUserHaveThisDefaultRole = ({
    roleID = [],
    some = false,
    every = false,
}) => {
    const _userRoles = userRoles() || [];

    if (some) {
        return _userRoles.some(
            (userRole) =>
                roleID.includes(userRole.RoleID) && userRole.IsDefault,
        );
    }

    if (every) {
        return _userRoles.every(
            (userRole) =>
                roleID.includes(userRole.RoleID) && userRole.IsDefault,
        );
    }

    return false;
};

const hasUserHaveThisMenu = ({
    applicationFunctionID = [],
    some = false,
    every = false,
}) => {
    const _userMenu = userMenu() || [];

    if (some) {
        return _userMenu.some((userMenu) =>
            applicationFunctionID.includes(userMenu.ApplicationFunctionID),
        );
    }

    if (every) {
        return _userMenu.every((userMenu) =>
            applicationFunctionID.includes(userMenu.ApplicationFunctionID),
        );
    }

    return false;
};

const hasApplicationFunction = ({
    applicationFunctionID,
    applicationFunctionIDs = [],
}) => {
    return applicationFunctionIDs.filter(
        (applicationFunction) =>
            parseNumber(applicationFunction.ApplicationFunctionID) ===
            applicationFunctionID,
    )[0]?.HasPermission;
};

const changeSiteDirection = (direction) => {
    document.getElementsByTagName("html")[0].setAttribute("dir", direction);
};

const getRole = () => {
    return JSON.parse(localStorage.getItem("USER_ROLES"));
};

const isAccountAdmin = () => {
    const role = getRole();

    return role[0]?.Name == "Account Admin";
};

const isOwnerAdmin = () => {
    const role = getRole();

    return role[0]?.Name == "Owner Admin";
};

const isFranchiseeAdmin = () => {
    const role = getRole();

    return role[0]?.Name == "Franchisee Admin";
};

const isSupplierAdmin = () => {
    const role = getRole();

    return role[0]?.Name == "Supplier Admin";
};

const isAccountApprover = () => {
    const role = getRole();

    return role[0]?.Name == "Account Approver";
};

const isAssessmentApprover = () => {
    const role = getRole();

    return role[0]?.Name == "Assessment Approver";
};

const getHexColorFromVuexyColor = (color) => {
    switch (color) {
        case "primary":
            return "#7367F0";

        case "success":
            return "#28C76F";

        case "danger":
            return "#EA5455";

        case "info":
            return "#00CFE8";

        case "warning":
            return "#FF9F43";

        case "dark":
            return "#1E1E1E";
        default:
            return color;
    }
};

const getDefaultUserRole = () => {
    return getArrayValueByKey({
        Key: "IsDefault",
        Value: true,
        List: userRoles(),
    });
};

const getCardModeColor = (mode) => {
    if (mode === "dark") {
        return "#10163A";
    }

    return "white";
};

const getKeyValue = ({ obj, key }) => {
    return obj[key];
};

const destroyAllToast = () => {
    return toast.dismiss();
};

function nl2br(str, is_xhtml) {
    if (typeof str === "undefined" || str === null) {
        return "";
    }

    const breakTag =
        is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";

    return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
}

const convertToCurrency = (amount, currency) => {
    return amount.toLocaleString("en-UK", {
        style: "currency",
        currency: currency.Code,
    });
};

const deleteAllCookies = () => {
    document.cookie.split(";").forEach((c) => {
        document.cookie =
            `${c.trim().split("=")[0]}=` +
            "expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    });
};

const getDBAttributes = ({ requestjson }, token = null) => {
    return new Promise((resolve, reject) => {
        const ResponseSuccessCallback = (responseJSON) => {
            const { Data, Status } = responseJSON.data;

            if (Status?.IsSuccess) {
                const { Attributes } = Data;
                resolve(Attributes);
            } else {
                Toast({ type: 4, content: Status.Message });
            }
        };

        const ResponseFailCallback = (responseJSON) => {
            Toast({ type: 4, content: responseJSON.message });
        };

        const config = {
            Data: { requestjson },
            Method: "GET",
            ResponseSuccessCallback,
            ResponseFailCallback,
            Token: token,
        };

        genericQuery(config);
    });
};

const getDBAttributesV2 = (
    { requestjson, onAttributeFetched },
    token = null,
) => {
    const ResponseSuccessCallback = (responseJSON) => {
        const { Data, Status } = responseJSON.data;

        if (Status?.IsSuccess) {
            const { Attributes } = Data;
            onAttributeFetched(Attributes);
        }
    };

    const ResponseFailCallback = (responseJSON) => {
        Toast({ mode: 4, content: responseJSON.message });
    };

    const config = {
        Data: { requestjson },
        Method: "GET",
        ResponseSuccessCallback,
        ResponseFailCallback,
        Token: token,
    };

    genericQuery(config);
};

const convertColorToHex = (value, hexOnly = false) => {
    if (value) {
        switch (value) {
            case "primary":
                return `#7367f0${!hexOnly ? ";--accentColorRGB: 115, 103, 240;" : ""
                    }`;

            case "info":
                return `#00cfe8${!hexOnly ? ";--accentColorRGB: 0, 207, 232;" : ""
                    }`;

            case "success":
                return `#28c76f${!hexOnly ? ";--accentColorRGB: 40, 199, 111;" : ""
                    }`;

            case "warning":
                return `#ff9f43${!hexOnly ? ";--accentColorRGB: 255, 159, 67;" : ""
                    }`;

            case "danger":
                return `#ea5455 ${!hexOnly ? ";--accentColorRGB: 234, 84, 85;" : ""
                    }`;

            case "dark":
                return `#1e1e1e${!hexOnly ? ";--accentColorRGB: 30, 30, 30;" : ""
                    }`;
            default:
                return value;
        }
    }
};

const updateVersionLogout = () => {
    // check version
    if (localStorage.getItem("REACT_APP_BUNDR_VERSION")) {
        if (
            parseFloat(process.env.REACT_APP_BUNDR_VERSION.replace(".", "")) >
            parseFloat(
                localStorage
                    .getItem("REACT_APP_BUNDR_VERSION")
                    .replace(".", ""),
            )
        ) {
            // if version does not match; then logout.
            if (localStorage.getItem("REACT_APP_BUNDR_VERSION")) {
                localStorage.setItem(
                    "REACT_APP_BUNDR_VERSION",
                    process.env.REACT_APP_BUNDR_VERSION,
                );
                alert("The system has been updated. Site will be refreshed");
                window.location.reload();
                // window.location.href = `/logout?v=${process.env.REACT_APP_BUNDR_VERSION}`;
            } else {
                localStorage.setItem(
                    "REACT_APP_BUNDR_VERSION",
                    process.env.REACT_APP_BUNDR_VERSION,
                );
            }
        }
    } else {
        localStorage.setItem(
            "REACT_APP_BUNDR_VERSION",
            process.env.REACT_APP_BUNDR_VERSION,
        );
    }
};

const toggleHideTooltips = (val = null) => {
    if ("HideTooltips" in userDetails()) {
        const checked = val || !userDetails()?.HideTooltips?.Value || false;

        userDetails({
            ...userDetails(),
            HideTooltips: {
                ...userDetails()?.HideTooltips,
                Value: checked,
            },
        });

        const docBody = document.getElementsByTagName("body")[0];

        if (checked) {
            docBody.classList.remove("hide-tooltip");
        } else {
            docBody.classList.add("hide-tooltip");
        }

        apiUpdate(
            `${userDetails()?.HideTooltips.RID}.HideTooltips`,
            !checked,
            checked,
        );
    }
};

const getCurrentTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getFormattedDate = (date, timezone) => {
    return moment
        .tz(date, timezone)
        .clone()
        .tz(getCurrentTimezone())
        .format("MMM DD, YYYY h:mm a z");
};

const formatNumberToHaveSeparators = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// format value based on user currency symbol and decimal separator
const formatNumberToUserDecimalSeparator = (num) => {
    if (num === null || !num) {
        return "";
    }

    const number = num.toString().split(".");
    const thousand = number[0];
    let decimal = number[1];
    if (decimal) decimal = `.${decimal}`;

    const decimalSperator = userDetails()?.MoneyFormat?.DecimalSeparator;
    const digitSeprator = userDetails()?.MoneyFormat?.DigitSeparator;
    const currencySymbol = userDetails()?.MoneyFormat?.Currency?.Symbol;

    const formattednumber =
        currencySymbol +
        thousand.toString().replace(/\B(?=(\d{3})+(?!\d))/g, digitSeprator);

    if (decimal) {
        if (decimalSperator === ",") {
            decimal.replace(".", ",");
        }
    }

    return decimal ? formattednumber + decimal : formattednumber;
};

/**
 * Returns a formatted number based on user setting
 *
 * @param {num} integer
 */
const formatNumberToUserDigitSeparators = (num) => {
    if (num === null || !num) {
        return "";
    }

    const number = num.toString().split(".");
    const thousand = number[0];
    let decimal = number[1];
    if (decimal) decimal = `.${decimal}`;

    const digitSeprator = userDetails()?.MoneyFormat?.DigitSeparator;
    const decimalSeprator = userDetails()?.MoneyFormat?.DecimalSeparator;

    const formattedNumber = thousand.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        digitSeprator,
    );

    if (decimal) {
        if (decimalSeprator === ",") {
            decimal.replace(".", ",");
        }
    }

    return decimal ? formattedNumber + decimal : formattedNumber;
};

const treatAsUTC = (date) => {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());

    return result;
};

const getDaysBetween = (startDate, endDate) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;

    const diffInMins = new Date(endDate) - new Date(startDate);
    const diffInDays = diffInMins / millisecondsPerDay;

    return Math.ceil(diffInDays);
};

const arrayInsert = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
];

const daysBetween = (startDate, endDate) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;

    return Math.floor(
        (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay,
    );
};

/**
 * Returns an integer from a string of floating number
 *
 * @param {num} string
 */
const removeDecimalPoint = (num) => {
    let number = num.replaceAll(".", "");
    // eslint-disable-next-line radix
    number = parseInt(number);

    return number;
};

/**
 * Set user money format
 *
 * @param {indentifier} string ['DecimalSeparator', 'DigitSeparator']
 * @param {newValue} string ['.', ',']
 */
const setMoneyFormat = (indentifier, newValue) => {
    const userDetail = userDetails();

    userDetail.MoneyFormat[indentifier] = newValue;

    userDetails(userDetail);
};

/**
 * validate if supplied param is a valid url
 *
 * @param {url} string
 */
const isValidURL = (url) => {
    const res =
        /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

    return res.test(url);
};

store.subscribe(Toast, genericQuery, promiseGenericQuery);

export {
    arrayInsert,
    getDBAttributes,
    getDBAttributesV2,
    hasUserHaveThisMenu,
    deleteAllCookies,
    destroyAllToast,
    getKeyValue,
    getDefaultUserRole,
    getHexColorFromVuexyColor,
    getCardModeColor,
    changeSiteDirection,
    addNodeUnderParent,
    genericQuery,
    getArrayValueByKey,
    mapArraySetNewValue,
    Toast,
    listToTree,
    hasUserHaveThisRole,
    hasApplicationFunction,
    isAccountAdmin,
    isOwnerAdmin,
    isFranchiseeAdmin,
    isSupplierAdmin,
    isAccountApprover,
    isAssessmentApprover,
    nl2br,
    convertToCurrency,
    convertColorToHex,
    updateVersionLogout,
    toggleHideTooltips,
    hasUserHaveThisDefaultRole,
    getCurrentTimezone,
    getFormattedDate,
    formatNumberToHaveSeparators,
    formatNumberToUserDecimalSeparator,
    getDaysBetween,
    promiseGenericQuery,
    expiredTokenRequestRefreshAndRetry,
    removeDecimalPoint,
    formatNumberToUserDigitSeparators,
    setMoneyFormat,
    isValidURL,
};
