import React, { useContext } from "react";
import StaticLabel from "components/customs/static-label-wrapper";
import { CustomInput } from "reactstrap";
import { ContextLayout } from "utility/context/Layout";
import { useDispatch } from "react-redux";
import { changeDirection } from "redux/actions/customizer";

export default function RTL() {
    const { state, switchDir } = useContext(ContextLayout);
    const { direction } = state;
    const dispatch = useDispatch();

    return (
        <div
            id="customizer-RTL"
            className="d-flex justify-content-between align-items-center"
        >
            <h5 className="pt-25">
                <StaticLabel id="RTLTitle" />
            </h5>
            <CustomInput
                type="switch"
                id="rtl-version"
                name="rtl-version"
                inline
                checked={direction === "rtl"}
                onChange={() => {
                    if (direction === "rtl") {
                        switchDir("ltr");
                        dispatch(changeDirection("ltr"));
                    } else {
                        switchDir("rtl");
                        dispatch(changeDirection("rtl"));
                    }
                }}
            />
        </div>
    );
}
