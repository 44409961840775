import React from "react";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import "assets/scss/custom.scss";
import { isDefaultClass } from "utility/utilities";
import { CustomSpinner } from "../index";

const CustomButtonRipple = ({ isloading = false, ...props }) => {
    const accentColor = useSelector(
        (state) => state.customizer.customizer.accentColor,
    );
    let classValue;
    if (props.className) classValue = props.className;

    if (props.outline) {
        return (
            <Button.Ripple
                color={props.color || accentColor}
                {...props}
                className={
                    isDefaultClass(accentColor)
                        ? `btn-outline-${accentColor} ${classValue}`
                        : `btn-outline-custom ${classValue}`
                }
                style={props.style || { "--color": accentColor }}
            >
                <>
                    {isloading ? (
                        <>
                            <CustomSpinner
                                overlay
                                alignment="center"
                                size="sm"
                            />
                            <span style={{ opacity: "0" }}>
                                {props.children}
                            </span>
                        </>
                    ) : (
                        props.children
                    )}
                </>
            </Button.Ripple>
        );
    }

    return (
        <Button.Ripple
            color={props.color || accentColor}
            {...props}
            style={
                props.style || { backgroundColor: accentColor, color: "white" }
            }
        >
            <>
                {isloading ? (
                    <>
                        <CustomSpinner overlay alignment="center" size="sm" />
                        <span style={{ opacity: "0" }}>{props.children}</span>
                    </>
                ) : (
                    props.children
                )}
            </>
        </Button.Ripple>
    );
};

export default CustomButtonRipple;
