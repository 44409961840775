import React from 'react'
import {NavLink} from "reactstrap"
import {useSelector} from 'react-redux';
import {isDefaultClass} from "utility/utilities";
import {useHexCode} from "hooks/useHexCode";

const CustomNavLink = (props) => {
	const accentColorState = useSelector(state => state?.customizer?.customizer?.accentColor)

	let classValue;
	if (props.className) classValue = props.className
	return (
		<NavLink
			{...props}
			className={`${!isDefaultClass(useHexCode(accentColorState)) && 'custom'} ${classValue}`}
			style={{"--accentColor": useHexCode(accentColorState)}}
		>
			{props.children}
		</NavLink>
   )
}

export default CustomNavLink;
