import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Disc, X, Circle } from "react-feather";
import classnames from "classnames";
import { Input, Row } from "reactstrap";
import "../../../../assets/scss/custom.scss";
// import BundrLogo from 'assets/img/logo/bundr-logo.svg'
import Logo from "assets/img/logo/logo.svg";
// import { ReactComponent as BundrLogoComponent } from 'assets/img/logo/bundr-logo.svg'
import LogoSVG from "assets/img/logo/logo-svg";
import { MEDIA_LIBRARY } from "configs/config";
import cls from "../../../../views/pages/authentication/auth.module.scss";
import LoginSVG from "../../../../assets/img/pages/login/login-svg";

class SidebarHeader extends Component {
    render() {
        const {
            toggleSidebarMenu,
            activeTheme,
            collapsed,
            toggle,
            sidebarVisibility,
            menuShadow,
            logo,
            logoExtended,
        } = this.props;

        let activeThemeHexColor = "";

        switch (activeTheme) {
            case "primary":
                activeThemeHexColor = "#7367f0";
                break;

            case "info":
                activeThemeHexColor = "#00cfe8";
                break;

            case "success":
                activeThemeHexColor = "#28c76f";
                break;

            case "warning":
                activeThemeHexColor = "#ff9f43";
                break;

            case "danger":
                activeThemeHexColor = "#ea5455";
                break;

            case "dark":
                activeThemeHexColor = "#1e1e1e";
                break;
            default:
                activeThemeHexColor = activeTheme;
        }

        const logoObj = {
            logoMediaFileID: logo,
            src: `${MEDIA_LIBRARY}/?MediaFileID=${logo}&width=40&height=40`,
        };

        const logoExtendedObj = {
            logoMediaFileID: logoExtended,
            src: `${MEDIA_LIBRARY}/?MediaFileID=${logoExtended}&width=100&height=55`,
        };

        return (
            <div className="navbar-header mb-1">
                <ul className="nav navbar-nav flex-row">
                    <li className="nav-item mr-auto">
                        <NavLink to="/" className="navbar-brand">
                            {logo ? (
                                <img
                                    src={`${logoObj.src}`}
                                    className="mb-1 custom-brand-logo"
                                    style={{ height: 40, marginLeft: "-5px" }}
                                    alt=""
                                />
                            ) : (
                                <img
                                    src={Logo}
                                    className={`mb-1 ${cls.imageLogoContainer} custom-brand-logo`}
                                    alt=""
                                />
                            )}

                            <div
                                className="brand-text mb-1 mt-sm-n50"
                                style={{ width: "170px", height: "55px" }}
                            >
                                {logoExtended ? (
                                    <img
                                        src={`${logoExtendedObj.src}`}
                                        alt=""
                                        style={{ objectFit: "contain" }}
                                        width="170px"
                                        height="55px"
                                    />
                                ) : (
                                    <LogoSVG color={activeThemeHexColor} />
                                )}
                            </div>

                            {/* <BundrLogoComponent style={{ fill: activeTheme }}/> */}
                            {/* <img className={`brand-text ${cls.imageLogoContainer}`} style={{ "--color": activeTheme }} src={BundrLogo} alt="logo"/> */}
                            {/* <h2 className="brand-text mb-0" style={{ "--color": activeTheme }} >Bundr</h2> */}
                        </NavLink>
                    </li>
                    <li className="nav-item nav-toggle">
                        <div className="nav-link modern-nav-toggle">
                            {collapsed === false ? (
                                <Disc
                                    onClick={() => {
                                        toggleSidebarMenu(true);
                                        toggle();
                                    }}
                                    className={classnames(
                                        "toggle-icon icon-x d-none d-xl-block font-medium-4",
                                        {
                                            "text-primary":
                                                activeTheme === "primary",
                                            "text-success":
                                                activeTheme === "success",
                                            "text-danger":
                                                activeTheme === "danger",
                                            "text-info": activeTheme === "info",
                                            "text-warning":
                                                activeTheme === "warning",
                                            "text-dark": activeTheme === "dark",
                                        },
                                    )}
                                    style={{ color: activeTheme }}
                                    size={20}
                                    data-tour="toggle-icon"
                                />
                            ) : (
                                <Circle
                                    onClick={() => {
                                        toggleSidebarMenu(false);
                                        toggle();
                                    }}
                                    className={classnames(
                                        "toggle-icon icon-x d-none d-xl-block font-medium-4",
                                        {
                                            "text-primary":
                                                activeTheme === "primary",
                                            "text-success":
                                                activeTheme === "success",
                                            "text-danger":
                                                activeTheme === "danger",
                                            "text-info": activeTheme === "info",
                                            "text-warning":
                                                activeTheme === "warning",
                                            "text-dark": activeTheme === "dark",
                                        },
                                    )}
                                    style={{ color: activeTheme }}
                                    size={20}
                                />
                            )}
                            <X
                                onClick={sidebarVisibility}
                                className={classnames(
                                    "toggle-icon icon-x d-block d-xl-none font-medium-4",
                                    {
                                        "text-primary":
                                            activeTheme === "primary",
                                        "text-success":
                                            activeTheme === "success",
                                        "text-danger": activeTheme === "danger",
                                        "text-info": activeTheme === "info",
                                        "text-warning":
                                            activeTheme === "warning",
                                        "text-dark": activeTheme === "dark",
                                    },
                                )}
                                style={{ color: activeTheme }}
                                size={20}
                            />
                        </div>
                    </li>
                </ul>
                <div
                    className={classnames("shadow-bottom", {
                        "d-none": menuShadow === false,
                    })}
                />
            </div>
        );
    }
}

export default SidebarHeader;
