import React from "react";

const LogoSVG = ({ color = "#7367f0" }) => (
	<svg
		height="28"
		viewBox="0 0 1095 369"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1085 10H10V359H1085V10Z"
			stroke={color}
			strokeWidth="20"
			strokeMiterlimit="10"
		/>
		<path
			d="M935.09 214.2H900.09V292.27H856.09V79H935.34C960.54 79 979.973 84.6167 993.64 95.85C1007.31 107.083 1014.14 122.95 1014.15 143.45C1014.15 158.01 1011 170.147 1004.7 179.86C998.4 189.573 988.867 197.307 976.1 203.06L1022.25 290.22V292.22H975.08L935.09 214.2ZM900.09 178.6H935.54C946.567 178.6 955.11 175.793 961.17 170.18C967.23 164.567 970.257 156.84 970.25 147C970.25 136.94 967.393 129.03 961.68 123.27C955.967 117.51 947.2 114.63 935.38 114.63H900.08L900.09 178.6Z"
			fill={color}
		/>
		<path
			d="M669.59 291.64V78.35H735.21C753.963 78.35 770.737 82.5766 785.53 91.03C800.302 99.4447 812.33 111.945 820.17 127.03C828.47 142.61 832.623 160.31 832.63 180.13V190C832.63 209.82 828.55 227.447 820.39 242.88C812.606 257.927 800.625 270.393 785.9 278.77C771.053 287.263 754.303 291.56 735.65 291.66L669.59 291.64ZM713.53 114V256.33H734.77C751.963 256.33 765.1 250.717 774.18 239.49C783.26 228.263 787.897 212.197 788.09 191.29V180C788.09 158.32 783.6 141.887 774.62 130.7C765.64 119.513 752.503 113.947 735.21 114H713.53Z"
			fill={color}
		/>
		<path
			d="M633.89 290.58H590.09V150L504.4 290.58H460.45V77.29H504.4V217.63L589.94 77.29H633.89V290.58Z"
			fill={color}
		/>
		<path
			d="M76.1801 291.09V77.8101H150.89C176.763 77.8101 196.39 82.7667 209.77 92.6801C223.15 102.593 229.84 117.12 229.84 136.26C230.027 146.069 227.215 155.702 221.78 163.87C216.32 171.9 208.484 178.019 199.37 181.37C210.31 184.11 218.93 189.63 225.23 197.93C231.53 206.23 234.68 216.387 234.68 228.4C234.68 248.9 228.137 264.427 215.05 274.98C201.963 285.533 183.297 290.903 159.05 291.09H76.1801ZM120.12 167.17H152.64C174.813 166.783 185.9 157.943 185.9 140.65C185.9 130.977 183.09 124.02 177.47 119.78C171.85 115.54 162.99 113.417 150.89 113.41H120.12V167.17ZM120.12 198.22V255.79H157.77C168.123 255.79 176.203 253.323 182.01 248.39C187.817 243.457 190.723 236.66 190.73 228C190.73 208.473 180.623 198.56 160.41 198.26L120.12 198.22Z"
			fill={color}
		/>
		<path
			d="M423.83 76.67V217.15C423.83 240.483 416.53 258.94 401.93 272.52C387.33 286.1 367.383 292.887 342.09 292.88C317.19 292.88 297.417 286.29 282.77 273.11C268.123 259.93 260.653 241.813 260.36 218.76V76.67H304.3V217.45C304.3 231.41 307.633 241.59 314.3 247.99C320.967 254.39 330.22 257.587 342.06 257.58C366.773 257.58 379.323 244.58 379.71 218.58V76.67H423.83Z"
			fill={color}
		/>
	</svg>
);

export default LogoSVG;
