import React, { useState } from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import globalService from "services/globalService";
import StaticLabel from "components/customs/static-label-wrapper";
import { Toast } from "../../../utility/helpers";

const CustomDeleteSweetAlertCallback = ({
    isShowAlert,
    setIsShowAlert,
    selectedRid,
    refreshTable,
}) => {
    const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
    const [isShowCancelAlert, setIsShowCancelAlert] = useState(false);

    const toggleAlert = (key, value) => {
        switch (key) {
            case "alert":
                setIsShowAlert(value);
                break;

            case "confirm":
                setIsShowConfirmAlert(value);
                setIsShowAlert(false);
                break;

            case "cancel":
                setIsShowCancelAlert(value);
                setIsShowAlert(false);
                break;
            default:
                break;
        }
    };

    const deleteCallback = (responseJSON) => {
        try {
            if (responseJSON.status === 200) {
                const { data } = responseJSON;
                const { Status } = data;
                if (Status?.IsSuccess) refreshTable();
            }
        } catch (Exception) {
            Toast({
                type: 4,
                content: Exception,
            });
        }
    };

    const handleDelete = () => {
        if (selectedRid) {
            globalService.delete({
                data: selectedRid,
                callback: deleteCallback,
            });
        }
    };

    return (
        // <Card>
        // 	<CardHeader>
        // 		<CardTitle>Callback</CardTitle>
        // 	</CardHeader>
        // 	<CardBody>
        <>
            <SweetAlert
                title={
                    // "Are you sure?"
                    <StaticLabel id="SweetAlertDefaultTitle" />
                }
                warning
                show={isShowAlert}
                showCancel
                reverseButtons
                cancelBtnBsStyle="danger"
                confirmBtnText={
                    // "Yes, delete it!"
                    <StaticLabel id="SweetAlertDefaultConfirmButton" />
                }
                cancelBtnText={
                    // "Cancel"
                    <StaticLabel id="ButtonCancel" />
                }
                onConfirm={() => {
                    handleDelete();
                    toggleAlert("alert", false);
                    toggleAlert("confirm", true);
                }}
                onCancel={() => {
                    setIsShowAlert(false);
                    toggleAlert("alert", false);
                    // toggleAlert("cancel", true)
                }}
            >
                {/* You won't be able to revert this! */}
                {<StaticLabel id="SweetAlertDefaultText" />}
            </SweetAlert>

            <SweetAlert
                success
                title={
                    // "Deleted!"
                    <StaticLabel id="SweetAlertDefaultDeletedTitle" />
                }
                confirmBtnBsStyle="success"
                show={isShowConfirmAlert}
                onConfirm={() => {
                    toggleAlert("confirm", false);
                    toggleAlert("alert", false);
                }}
            >
                <p className="sweet-alert-text">
                    {/* Your record has been deleted. */}
                    <StaticLabel id="SweetAlertDefaultDeletedText" />
                </p>
            </SweetAlert>

            <SweetAlert
                error
                title={
                    // "Cancelled"
                    <StaticLabel id="SweetAlertDefaultCancelledTitle" />
                }
                confirmBtnBsStyle="success"
                show={isShowCancelAlert}
                onConfirm={() => {
                    toggleAlert("alert", false);
                    toggleAlert("cancel", false);
                }}
            >
                <p className="sweet-alert-text">
                    {/* Your record is safe :)  */}
                    <StaticLabel id="SweetAlertDefaultCancelledText" />
                </p>
            </SweetAlert>
        </>
        // 	</CardBody>
        // </Card>
    );
};

export default CustomDeleteSweetAlertCallback;
