import { useHexCode } from "hooks/useHexCode";
import React from "react";
import { Settings } from "react-feather";

export default function CustomzierToggle(props) {
    return (
        <div {...props} style={{ background: useHexCode(props.color) }}>
            <Settings className="open-icon" size={15} />
        </div>
    );
}
