import React from "react";
import { useSelector } from "react-redux";

export function useAccentColorWithParams(loginColorValues) {
    if (loginColorValues) {
        switch (loginColorValues) {
            case "primary":
                return "#7367f0";

            case "info":
                return "#00cfe8";

            case "success":
                return "#28c76f";

            case "warning":
                return "#ff9f43";

            case "danger":
                return "#ea5455";

            case "dark":
                return "#1e1e1e";
            default:
            //
        }
    }
}

export function useHexCode(accentColor) {
    const accentColorState = useSelector(
        (state) =>
            state?.globalConfig?.PartnerDetails?.TemplateConfiguration?.Value
                ?.accentColor,
    );
    let accentValue;

    if (accentColor) {
        accentValue = accentColor;
    } else {
        accentValue = accentColorState;
    }

    if (accentValue) {
        switch (accentValue) {
            case "primary":
                return "#7367f0";

            case "info":
                return "#00cfe8";

            case "success":
                return "#28c76f";

            case "warning":
                return "#ff9f43";

            case "danger":
                return "#ea5455";

            case "dark":
                return "#1e1e1e";
            default:
                return accentValue;
        }
    }

    return accentValue;
}

export default {
    useHexCode,
    useAccentColorWithParams,
};
