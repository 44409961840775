/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/sort-comp */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-access-state-in-setstate */
import React, { PureComponent } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { history } from "history.js";
import { hasUserHaveThisRole, convertColorToHex } from "utility/helpers";
import { partnerDetails, userDetails } from "configs/config";
import CustomizerSidebar from "components/@vuexy/customizer";
// eslint-disable-next-line import/no-cycle
import Sidebar from "./components/menu/vertical-menu/Sidebar";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import {
    changeMode,
    collapseSidebar,
    changeNavbarColor,
    changeNavbarType,
    changeFooterType,
    changeMenuColor,
    changeAccentColor,
    hideScrollToTop,
    disableCustomizer,
    enableCustomizer,
    changeDirection,
    changeMenuLayout,
    changeLayout,
    changeCustomizerState,
    changeOverlayState,
    setCustomzier,
} from "../redux/actions/customizer/index";

class VerticalLayout extends PureComponent {
    state = {
        width: window.innerWidth,
        sidebarState: this.props.app.customizer.sidebarCollapsed,
        layout: this.props.app.customizer.theme,
        collapsedContent: this.props.app.customizer.sidebarCollapsed,
        sidebarHidden: false,
        currentLang: "en",
        // appOverlay: false,
        // customizer: false,
        // currRoute: history.location.pathname
    };

    collapsedPaths = [];

    mounted = false;

    updateWidth = () => {
        if (this.mounted) {
            this.setState(() => ({
                width: window.innerWidth,
            }));
        }
    };

    handleCustomizer = (bool) => {
        this.setState({
            customizer: bool,
        });
    };

    componentDidMount() {
        let templateConfigValue =
            partnerDetails()?.TemplateConfiguration?.Value;

        if (userDetails()?.UsePersonalTheme?.Value) {
            templateConfigValue = userDetails()?.TemplateConfiguration?.Value;
        }

        const docBody = document.getElementsByTagName("body")[0];

        docBody.setAttribute(
            "style",
            `--accentColor: ${convertColorToHex(
                templateConfigValue?.accentColor,
            )}`,
        );
        docBody.classList.add("apply-accent");

        // check user setting hide tooltips
        if (!userDetails()?.HideTooltips?.Value) {
            docBody.classList.add("hide-tooltip");
        }

        // Check currentRoute to show customizer
        if (
            history.location.pathname === "/account-settings" ||
            history.location.pathname.includes("/franchise")
        ) {
            if (hasUserHaveThisRole({ roleID: [3, 4.6], some: true })) {
                this.props.enableCustomizer();
            } else if (hasUserHaveThisRole({ roleID: [8], every: true })) {
                if (
                    this.props.globalConfig.UserDetails.UsePersonalTheme.Value
                ) {
                    this.props.enableCustomizer();
                }
            }
        } else {
            this.props.disableCustomizer();
        }

        this.mounted = true;

        const {
            location: { pathname },
            app: {
                customizer: { theme, direction },
            },
        } = this.props;

        if (this.mounted) {
            if (window !== "undefined") {
                window.addEventListener("resize", this.updateWidth, false);
            }

            if (this.collapsedPaths.includes(pathname)) {
                this.props.collapseSidebar(true);
            }

            const layout = theme;
            const dir = direction;

            if (dir === "rtl") {
                document
                    .getElementsByTagName("html")[0]
                    .setAttribute("dir", "rtl");
            } else {
                document
                    .getElementsByTagName("html")[0]
                    .setAttribute("dir", "ltr");
            }

            return layout === "dark"
                ? document.body.classList.add("dark-layout")
                : layout === "semi-dark"
                ? document.body.classList.add("semi-dark-layout")
                : null;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let templateConfigValue =
            partnerDetails()?.TemplateConfiguration?.Value;

        if (userDetails()?.UsePersonalTheme?.Value) {
            templateConfigValue = userDetails()?.TemplateConfiguration?.Value;
        }

        const docBody = document.getElementsByTagName("body")[0];

        docBody.setAttribute(
            "style",
            `--accentColor: ${convertColorToHex(
                templateConfigValue?.accentColor,
            )}`,
        );

        if (
            this.state.collapsedContent !==
            this.props.app.customizer.collapsedSidebar
        ) {
            this.props.collapseSidebar(this.state.collapsedContent);
        }

        const {
            location: { pathname },
            app: {
                customizer: { theme, sidebarCollapsed },
            },
        } = this.props;

        const layout = theme;

        if (this.mounted) {
            if (layout === "dark") {
                document.body.classList.remove("semi-dark-layout");
                document.body.classList.add("dark-layout");
            }

            if (layout === "semi-dark") {
                document.body.classList.remove("dark-layout");
                document.body.classList.add("semi-dark-layout");
            }

            if (layout !== "dark" && layout !== "semi-dark") {
                document.body.classList.remove(
                    "dark-layout",
                    "semi-dark-layout",
                );
            }

            if (
                prevProps.app.customizer.sidebarCollapsed !==
                this.props.app.customizer.sidebarCollapsed
            ) {
                this.setState({
                    collapsedContent: sidebarCollapsed,
                    sidebarState: sidebarCollapsed,
                });
            }

            if (
                prevProps.app.customizer.sidebarCollapsed ===
                    this.props.app.customizer.sidebarCollapsed &&
                pathname !== prevProps.location.pathname &&
                this.collapsedPaths.includes(pathname)
            ) {
                this.props.collapseSidebar(true);
            }

            if (
                prevProps.app.customizer.sidebarCollapsed ===
                    this.props.app.customizer.sidebarCollapsed &&
                pathname !== prevProps.location.pathname &&
                !this.collapsedPaths.includes(pathname)
            ) {
                this.props.collapseSidebar(false);
            }
        }
    }

    handleCollapsedMenuPaths = (item) => {
        const { collapsedPaths } = this;

        if (!collapsedPaths.includes(item)) {
            collapsedPaths.push(item);
            this.collapsedPaths = collapsedPaths;
        }
    };

    toggleSidebarMenu = () => {
        this.setState({
            sidebarState: !this.state.sidebarState,
            collapsedContent: !this.state.collapsedContent,
        });
    };

    sidebarMenuHover = (val) => {
        this.setState({
            sidebarState: val,
        });
    };

    handleSidebarVisibility = () => {
        if (this.mounted) {
            if (window !== undefined) {
                window.addEventListener("resize", () => {
                    if (this.state.sidebarHidden) {
                        this.setState({
                            sidebarHidden: !this.state.sidebarHidden,
                        });
                    }
                });
            }

            this.setState({
                sidebarHidden: !this.state.sidebarHidden,
            });
        }
    };

    componentWillUnmount() {
        this.mounted = false;
    }

    handleCurrentLanguage = (lang) => {
        this.setState({
            currentLang: lang,
        });
    };

    handleAppOverlay = (value) => {
        if (value.length > 0) {
            changeCustomizerState(true);
            changeOverlayState(true);
            // this.setState({
            // 	appOverlay: true,

            // },()=>{

            // });
        } else if (value.length < 0 || value === "") {
            // this.setState({
            // 	appOverlay: false
            // }, ()=>{
            // 	changeCustomizerState(false)
            // });
            changeCustomizerState(false);
            changeOverlayState(false);
        }
    };

    handleAppOverlayClick = (bool) => {
        this.setState({
            appOverlay: bool,
        });
        changeCustomizerState(false);
    };

    render() {
        const appProps = this.props.app.customizer;

        const menuThemeArr = [
            "primary",
            "success",
            "danger",
            "info",
            "warning",
            "dark",
        ];

        const sidebarProps = {
            toggleSidebarMenu: this.props.collapseSidebar,
            toggle: this.toggleSidebarMenu,
            sidebarState: this.state.sidebarState,
            sidebarHover: this.sidebarMenuHover,
            sidebarVisibility: this.handleSidebarVisibility,
            visibilityState: this.state.sidebarHidden,
            activePath: this.props.match.path,
            collapsedMenuPaths: this.handleCollapsedMenuPaths,
            currentLang: this.state.currentLang,
            activeTheme: appProps.menuTheme,
            collapsed: this.state.collapsedContent,
            permission: this.props.permission,
            deviceWidth: this.state.width,
            logo: appProps.logo,
            logoExtended: appProps.logoExtended,
        };

        const navbarProps = {
            toggleSidebarMenu: this.toggleSidebarMenu,
            sidebarState: this.state.sidebarState,
            sidebarVisibility: this.handleSidebarVisibility,
            currentLang: this.state.currentLang,
            changeCurrentLang: this.handleCurrentLanguage,
            handleAppOverlay: this.handleAppOverlay,
            appOverlayState: appProps.appOverlay,
            navbarColor: appProps.navbarColor,
            navbarType: appProps.navbarType,
        };

        const footerProps = {
            footerType: appProps.footerType,
            hideScrollToTop: appProps.hideScrollToTop,
        };

        const customizerProps = {
            customizerState: appProps.isOpenCustomizer,
            handleCustomizer: this.handleCustomizer,
            changeMode: this.props.changeMode,
            changeNavbar: this.props.changeNavbarColor,
            changeNavbarType: this.props.changeNavbarType,
            changeFooterType: this.props.changeFooterType,
            changeNavbarColor: this.props.changeNavbarColor,
            changeMenuTheme: this.props.changeMenuColor,
            changeAccentColor: this.props.changeAccentColor, // add accent color function
            collapseSidebar: this.props.collapseSidebar,
            hideScrollToTop: this.props.hideScrollToTop,
            activeMode: appProps.theme,
            activeNavbar: appProps.navbarColor,
            navbarType: appProps.navbarType,
            footerType: appProps.footerType,
            menuTheme: appProps.menuTheme,
            accentColor: appProps.accentColor,
            scrollToTop: appProps.hideScrollToTop,
            sidebarState: appProps.collapsedSidebar,
            changeDirection: this.props.changeDirection,
            changeMenuLayout: this.props.changeMenuLayout,
            changeLayout: this.props.changeLayout,
            // handleAppOverlayClick: this.handleAppOverlayClick,
            changeOverlayState: this.props.changeOverlayState,
            changeCustomizerState: this.props.changeCustomizerState,
            // openCustomizer: this.props.openCustomizer,
            // closeCustomizer: this.props.closeCustomizer
        };

        return (
            <div
                className={classnames(
                    `wrapper vertical-layout theme-${appProps.menuTheme}`,
                    {
                        "menu-collapsed":
                            this.state.collapsedContent === true &&
                            this.state.width >= 1200,
                        "fixed-footer": appProps.footerType === "sticky",
                        "navbar-static": appProps.navbarType === "static",
                        "navbar-sticky": appProps.navbarType === "sticky",
                        "navbar-floating": appProps.navbarType === "floating",
                        "navbar-hidden": appProps.navbarType === "hidden",
                        "theme-primary": !menuThemeArr.includes(
                            appProps.menuTheme,
                        ),
                    },
                )}
            >
                <Sidebar {...sidebarProps} />
                <div
                    className={classnames("app-content content", {
                        "show-overlay": appProps.appOverlay === true,
                    })}
                    onClick={() => {
                        // console.log("hehe")
                        // changeOverlayState(!appProps.appOverlay)
                        // changeCustomizerState(!appProps.isOpenCustomizer)
                    }}
                >
                    <Navbar {...navbarProps} />
                    <div className="content-wrapper vertical-layout">
                        {this.props.children}
                    </div>
                </div>

                {!appProps.disableCustomizer && (
                    <CustomizerSidebar {...customizerProps} />
                )}
                <div
                    className="sidenav-overlay"
                    onClick={this.handleSidebarVisibility}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.customizer,
        globalConfig: state.globalConfig,
    };
};

export default connect(mapStateToProps, {
    setCustomzier,
    changeMode,
    collapseSidebar,
    changeNavbarColor,
    changeNavbarType,
    changeFooterType,
    changeMenuColor,
    changeAccentColor,
    hideScrollToTop,
    disableCustomizer,
    enableCustomizer,
    changeDirection,
    changeMenuLayout,
    changeLayout,
    changeCustomizerState,
    changeOverlayState,
})(VerticalLayout);
