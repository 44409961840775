/* eslint-disable no-console */
import { genericQuery, Toast } from "utility/helpers";
import { responseCallbackConstant } from "constants/responseCallbackConstant";
import { partnerDetails, userDetails } from "configs/config";

const customizerService = {
    setDetails: (params = {}) => {
        const { data, callback } = params;
        let isContinue = false;
        data.isOpenCustomizer = false;
        data.appOverlay = false;

        const formData = new FormData();

        const config = {
            ...responseCallbackConstant(callback),
            Method: "POST",
            Url: "/update_by_rid",
            ExtraParams: {},
        };
        const { RID } = data;
        const newValue = { ...data };

        if (newValue.menuColor) delete newValue.menuColor;
        if (newValue.menuLayout) delete newValue.menuLayout;
        if (newValue.disableCustomizer) delete newValue.disableCustomizer;

        if (newValue.franchiseeLayoutChanged) {
            delete newValue.franchiseeLayoutChanged;
        }

        if (newValue.partnerLayoutChanged) {
            delete newValue.partnerLayoutChanged;
        }

        if (newValue.layoutChanged) delete newValue.layoutChanged;

        if (RID) {
            config.ExtraParams.isFranchisee = true;

            if (newValue.partnerConfig) delete newValue.partnerConfig;
            if (newValue.oldValue) delete newValue.oldValue;

            if (newValue.isFranchiseeSelected) {
                delete newValue.isFranchiseeSelected;
            }
            if (newValue.PartnerID) delete newValue.PartnerID;
            if (newValue.RID) delete newValue.RID;

            formData.set("RID", `${RID}`);
            formData.set("OldValue", null);
            formData.set("Value", JSON.stringify(newValue));
            isContinue = true;
        } else if (
            userDetails()?.UsePersonalTheme?.Value ||
            userDetails()?.UsePersonalTheme?.Value === 1
        ) {
            config.ExtraParams.isFranchisee = false;

            const userDetail = { ...userDetails() };
            const { TemplateConfiguration } = userDetail;
            const { Value } = TemplateConfiguration;
            const personalThemeRID = TemplateConfiguration.RID;

            if (newValue.appOverlay) delete newValue.appOverlay;

            if (newValue.isOpenCustomizer) {
                delete newValue.isOpenCustomizer;
            }

            formData.set("RID", personalThemeRID);
            formData.set("OldValue", JSON.stringify(Value));
            formData.set("Value", JSON.stringify(newValue));
            isContinue = true;
        } else if (
            !userDetails()?.UsePersonalTheme?.Value ||
            userDetails()?.UsePersonalTheme?.Value === 0
        ) {
            config.ExtraParams.isFranchisee = false;

            const partnerDetail = { ...partnerDetails() };
            const { TemplateConfiguration } = partnerDetail;
            const { Value } = TemplateConfiguration;
            const partnerThemeRID = TemplateConfiguration.RID;

            if (newValue.appOverlay) delete newValue.appOverlay;

            if (newValue.isOpenCustomizer) {
                delete newValue.isOpenCustomizer;
            }

            formData.set("RID", partnerThemeRID);
            formData.set("OldValue", JSON.stringify(Value));
            formData.set("Value", JSON.stringify(newValue));
            isContinue = true;
        } else {
            isContinue = false;
        }

        if (isContinue) {
            config.Data = { requestjson: formData };

            genericQuery(config);
        } else {
            Toast({
                type: 4,
                content: "Failed to update the theme",
            });
        }
    },
    setPartnerLogo: (params) => {
        const { data, callback } = params;

        const formData = new FormData();

        const config = {
            ...responseCallbackConstant(callback),
            Method: "POST",
            Url: "/update_by_rid",
        };

        const { MediaFileID, RID, type, TemplateConfiguration } = data;

        const newValue = {
            ...TemplateConfiguration,
            logo: (type === 1 && MediaFileID) || TemplateConfiguration.logo,
            logoExtended:
                (type === 2 && MediaFileID) ||
                TemplateConfiguration.logoExtended,
        };
        formData.set("RID", RID);
        formData.set("OldValue", JSON.stringify(TemplateConfiguration));
        formData.set("Value", JSON.stringify(newValue));

        config.Data = { requestjson: formData };

        genericQuery(config);
    },
};

export default customizerService;
