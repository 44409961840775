import React, { createContext, useEffect, useState } from "react";
import {
    UncontrolledDropdown,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Media,
    Badge,
    ModalHeader,
    Modal,
} from "reactstrap";
import * as Icon from "react-feather";
import ReactCountryFlag from "react-country-flag";
import { CustomDropdownItem } from "components/customs";
import { globalService } from "services";
import {
    genericQuery,
    hasUserHaveThisDefaultRole,
    hasUserHaveThisRole,
    Toast,
} from "utility/helpers";
import { useSelector } from "react-redux";
import NotificationsDropdown from "components/notifications-dropdown";
import StaticLabel from "components/customs/static-label-wrapper";
import classNames from "classnames";
import { MultiAccountModal } from "components/multi-account";
import { history } from "../../../history";
import { IntlContext } from "../../../utility/context/Internationalization";
import { getInitials } from "../../../utility/utilities";
import CustomAvatarComponent from "../../../components/customs/avatar/CustomAvatarComponent";
import { hasMultipleUsers, userDetails } from "../../../configs/config";

const handleNavigation = (e, path) => {
    e.preventDefault();
    history.push(path);
};

const UserDropdown = (props) => {
    const [creditCardBalance, setCreditCardBalance] = useState({});

    const customizer = useSelector((state) => state.customizer.customizer);

    const { accentColor, theme } = customizer;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const currencySymbol = useSelector(
        (state) =>
            state?.globalConfig?.UserDetails?.MoneyFormat?.Currency.Symbol,
    );

    useEffect(() => {
        const creditCardBalanceCallback = {
            callback: (responseJSON) => {
                const { data } = responseJSON;

                const { Status, Data } = data;

                if (Status?.IsSuccess) {
                    setCreditCardBalance(Data);
                } else {
                    Toast({
                        type: 4,
                        content: Status?.Message,
                    });
                }
            },
        };

        globalService.getCreditCardBalance(creditCardBalanceCallback);
    }, []);

    const NavbarItems = [
        {
            onClick: (e) => handleNavigation(e, "/account-settings"),
            icon: (
                <Icon.Settings
                    size={14}
                    className={`mr-50 ${theme}`}
                    color={theme === "dark" ? "#C2C6DC" : "#626262"}
                />
            ),
            label: (
                <StaticLabel id="AccountDropdownSettings" className={theme} />
            ),
            show: true,
        },
        {
            show: !hasUserHaveThisDefaultRole({
                roleID: [5, 9, 10, 11, 12, 13],
                some: true,
            }),
            onClick: (e) => handleNavigation(e, "/account-balance"),
            icon: (
                <Icon.CreditCard
                    color={theme === "dark" ? "#C2C6DC" : "#626262"}
                    size={14}
                    className={`mr-50 ${theme}`}
                />
            ),
            label: (
                <StaticLabel id="AccountDropdownBalance" className={theme} />
            ),
            badge: (
                <Badge
                    id="account-balance-value"
                    className={classNames({
                        [`badge-${accentColor}`]: accentColor,
                        "badge-primray": !accentColor,
                        dark: theme === "dark",
                    })}
                    pill
                >
                    {`${creditCardBalance?.RemainingCreditFormated}`}
                </Badge>
            ),
        },
        {
            icon: (
                <Icon.Users
                    color={theme === "dark" ? "#C2C6DC" : "#626262"}
                    size={14}
                    className={`mr-50 ${theme}`}
                />
            ),
            onClick: () => setIsModalOpen(true),
            label: (
                <StaticLabel
                    id="AccountDropdownSwitchAccount"
                    className={theme}
                />
            ),
            show: hasMultipleUsers(),
        },
        {
            onClick: (e) => {
                e.preventDefault();
                history.push("/logout");
            },
            icon: (
                <Icon.Power
                    size={14}
                    className={`mr-50 ${theme}`}
                    color={theme === "dark" ? "#C2C6DC" : "#626262"}
                />
            ),
            label: <StaticLabel id="AccountDropdownLogout" className={theme} />,
            show: true,
        },
    ];

    const renderNavbarItems = () => {
        return NavbarItems.map((item, idx) => {
            if (item.show) {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={idx}>
                        <CustomDropdownItem
                            tag="a"
                            href="#"
                            onClick={item.onClick}
                        >
                            <span className="position-relative d-flex justify-content-between align-items-center">
                                <span className="align-middle mr-1">
                                    {item.icon}
                                    {/* {"Switch Account"} */}

                                    {item.label}
                                </span>
                                {item.badge}
                            </span>
                        </CustomDropdownItem>

                        {idx < NavbarItems.length && <DropdownItem divider />}
                    </div>
                );
            }
        });
    };

    return (
        <DropdownMenu
            right
            style={{
                width:
                    !hasUserHaveThisDefaultRole({
                        roleID: [5, 9, 10, 11, 12, 13],
                        some: true,
                    }) && 250,
            }}
        >
            <MultiAccountModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />

            {renderNavbarItems()}
        </DropdownMenu>
    );
};

const NavbarUser = (props) => {
    const [languageList, setLanguageList] = useState([]);

    const [state, setState] = useState({
        navbarSearch: false,
        langDropdown: false,
    });

    const customizer = useSelector((_state) => _state.customizer.customizer);

    const { theme } = customizer;
    // const handleNavbarSearch = () => {
    //     setState({
    //         navbarSearch: !state.navbarSearch,
    //     });
    // };

    const handleLangDropdown = () =>
        setState({ langDropdown: !state.langDropdown });

    const userFullName = `${userDetails()?.FirstName} ${userDetails()?.LastName
        }`;

    const getLanguages = () => {
        const config = {
            Method: "GET",
            Data: {
                requestjson: {
                    Module: "system_get_list",
                    Parameters: {
                        ObjectType: "LanguageList",
                    },
                },
            },
            ResponseSuccessCallback: (requestjson) => {
                const { data } = requestjson;
                const { Data } = data;
                const { List } = Data;
                setLanguageList(List);
            },
            ResponseFailCallback: (requestjson) => {
                Toast({
                    type: 4,
                    content: requestjson.data.Status.Message,
                });
            },
        };

        genericQuery(config);
    };

    const renderLanguageSelection = (context) => {
        if (userDetails()?.LanguageDetails) {
            if (
                userDetails().LanguageDetails.RID &&
                userDetails().LanguageDetails.LanguageID &&
                userDetails().LanguageDetails.Name
            ) {
                return (
                    <DropdownMenu right>
                        {languageList.map((language) => (
                            <CustomDropdownItem
                                tag="a"
                                key={language.LanguageID}
                                onClick={(e) => {
                                    context.switchLanguage(language);
                                }}
                            >
                                <ReactCountryFlag
                                    className="country-flag"
                                    countryCode={language.TwoLetterCode}
                                    svg
                                />
                                <span
                                    className={classNames("ml-1", {
                                        dark: theme === "dark",
                                    })}
                                >
                                    {language.Name}
                                </span>
                            </CustomDropdownItem>
                        ))}
                    </DropdownMenu>
                );
            }
        }
    };

    useEffect(() => {
        getLanguages();
    }, []);

    return (
        <ul className="nav navbar-nav navbar-nav-user float-right">
            <IntlContext.Consumer>
                {(context) => {
                    return (
                        <UncontrolledDropdown
                            tag="li"
                            className="dropdown-language nav-item"
                            isOpen={state.langDropdown}
                            toggle={handleLangDropdown}
                            data-tour="language"
                        >
                            <DropdownToggle tag="a" className="nav-link">
                                <ReactCountryFlag
                                    className="country-flag"
                                    countryCode={context.locale}
                                    svg
                                />
                                <span
                                    className="d-sm-inline-block d-none text-capitalize align-middle ml-50"
                                    style={{ color: "#BBBFD5 !important" }}
                                >
                                    {context.language}
                                </span>
                            </DropdownToggle>
                            {renderLanguageSelection(context)}
                        </UncontrolledDropdown>
                    );
                }}
            </IntlContext.Consumer>
            {!hasUserHaveThisRole({ roleID: [12, 13], some: true }) && (
                <NotificationsDropdown />
            )}
            <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                    <div className="user-nav d-sm-flex d-none">
                        <span className="user-name text-bold-600">
                            {props.userName}
                        </span>
                    </div>
                    <span data-tour="user">
                        <CustomAvatarComponent
                            className="avatar-rounded"
                            content={getInitials(userFullName)}
                            mediaFileID={userDetails().ProfilePicture?.Value}
                        />
                    </span>
                </DropdownToggle>
                <UserDropdown {...props} />
            </UncontrolledDropdown>
        </ul>
    );
};

export default NavbarUser;
