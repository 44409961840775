/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
import React, { PureComponent } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { history } from "history.js";
import { hasUserHaveThisRole } from "utility/helpers";
import CustomizerSidebar from "components/@vuexy/customizer";
import Sidebar from "./components/menu/horizontal-menu/HorizontalMenu";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import themeConfig from "../configs/themeConfig";
import {
    changeMode,
    collapseSidebar,
    changeNavbarColor,
    changeNavbarType,
    changeFooterType,
    changeMenuColor,
    changeAccentColor,
    hideScrollToTop,
    disableCustomizer,
    enableCustomizer,
    changeDirection,
    changeMenuLayout,
    changeLayout,
    changeCustomizerState,
    changeOverlayState,
} from "../redux/actions/customizer/index";

class HorizontalLayout extends PureComponent {
    state = {
        width: window.innerWidth,
        sidebarState: false,
        layout: this.props.customizer.layout,
        collapsedContent: false,
        sidebarHidden: false,
        currentLang: "en",
        // appOverlay: false,
        // customizer: false,
        currRoute: this.props.location.pathname,
        menuOpen: themeConfig.menuOpen,
    };

    mounted = false;

    updateWidth = () => {
        if (this.mounted) {
            this.setState(() => ({
                width: window.innerWidth,
            }));
        }
    };

    updateScroll = () => {
        if (this.mounted) {
            this.setState({ scroll: window.pageYOffset });
        }
    };

    handleCustomizer = (bool) => {
        this.setState({
            customizer: bool,
        });
    };

    componentDidMount() {
        // Check currentRoute to show customizer
        if (
            history.location.pathname === "/account-settings" ||
            history.location.pathname.includes("/franchise")
        ) {
            if (hasUserHaveThisRole({ roleID: [3, 4.6], some: true })) {
                this.props.enableCustomizer();
            } else if (hasUserHaveThisRole({ roleID: [8], every: true })) {
                if (
                    this.props?.globalConfig?.UserDetails?.UsePersonalTheme
                        ?.Value
                ) {
                    this.props.enableCustomizer();
                } else {
                    this.props.disableCustomizer();
                }
            }
        } else {
            this.props.disableCustomizer();
        }

        this.mounted = true;

        if (this.mounted) {
            if (window !== "undefined") {
                window.addEventListener("resize", this.updateWidth, false);
                window.addEventListener("scroll", this.updateScroll, false);
            }

            if (this.props.location.pathname === "/pages/profile") {
                this.setState({
                    sidebarState: true,
                    collapsedContent: true,
                });
            }
            const layout = this.props.customizer.theme;

            return layout === "dark"
                ? document.body.classList.add("dark-layout")
                : layout === "semi-dark"
                    ? document.body.classList.add("semi-dark-layout")
                    : null;
        }

        if (
            history.location.pathname === "/account-settings" ||
            history.location.pathname.includes("/franchise")
        ) {
            if (hasUserHaveThisRole({ roleID: [3, 4.6], some: true })) {
                this.props.enableCustomizer();
            } else if (hasUserHaveThisRole({ roleID: [8], every: true })) {
                if (
                    this.props.globalConfig.UserDetails.UsePersonalTheme.Value
                ) {
                    this.props.enableCustomizer();
                }
            }
        } else {
            this.props.disableCustomizer();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            location: { pathname },
        } = this.props;

        if (this.mounted) {
            if (this.state.currRoute !== this.props.location.pathname) {
                this.handleRouteChange();

                this.setState({
                    currRoute: this.props.location.pathname,
                });
            }

            const layout = this.props.customizer.theme;

            if (layout === "dark") {
                document.body.classList.remove("semi-dark-layout");
                document.body.classList.add("dark-layout");
            } else if (layout === "semi-dark") {
                document.body.classList.remove("dark-layout");
                document.body.classList.add("semi-dark-layout");
            } else {
                return document.body.classList.remove(
                    "dark-layout",
                    "semi-dark-layout",
                );
            }
        }
    }

    handleRouteChange = () => {
        if (this.props.location.pathname === "/pages/profile") {
            this.setState({
                collapsedContent: true,
            });
        } else {
            this.setState({
                sidebarState: false,
                collapsedContent: false,
            });
        }
    };

    toggleSidebarMenu = () => {
        this.setState({
            sidebarState: !this.state.sidebarState,
            collapsedContent: !this.state.collapsedContent,
        });
    };

    sidebarMenuHover = () => {
        this.setState({
            sidebarState: !this.state.sidebarState,
        });
    };

    handleSidebarVisibility = () => {
        if (this.mounted) {
            if (window !== undefined) {
                window.addEventListener("resize", () => {
                    if (this.state.sidebarHidden) {
                        this.setState({
                            sidebarHidden: !this.state.sidebarHidden,
                        });
                    }
                });
            }

            this.setState({
                sidebarHidden: !this.state.sidebarHidden,
            });
        }
    };

    handleCurrentLanguage = (lang) => {
        this.setState({
            currentLang: lang,
        });
    };

    handleAppOverlay = (value) => {
        if (value.length > 0) {
            changeCustomizerState(true);
            changeOverlayState(true);
            // this.setState({
            // 	appOverlay: true,

            // },()=>{

            // });
        } else if (value.length < 0 || value === "") {
            // this.setState({
            // 	appOverlay: false
            // }, ()=>{
            // 	changeCustomizerState(false)
            // });
            changeCustomizerState(false);
            changeOverlayState(false);
        }
    };

    handleAppOverlayClick = (bool) => {
        this.setState({
            appOverlay: bool,
        });
        changeCustomizerState(false);
    };

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const appProps = this.props.customizer;

        const menuThemeArr = [
            "primary",
            "success",
            "danger",
            "info",
            "warning",
            "dark",
        ];

        const sidebarProps = {
            toggleSidebarMenu: this.props.collapseSidebar,
            toggle: this.toggleSidebarMenu,
            sidebarState: this.state.sidebarState,
            sidebarHover: this.sidebarMenuHover,
            sidebarVisibility: this.handleSidebarVisibility,
            visibilityState: this.state.sidebarHidden,
            activePath: this.props.match.path,
            collapsedMenuPaths: this.handleCollapsedMenuPaths,
            currentLang: this.state.currentLang,
            activeTheme: appProps.menuTheme,
            collapsed: this.state.collapsedContent,
            permission: this.props.permission,
            deviceWidth: this.state.width,
            logo: appProps.logo,
        };

        const navbarProps = {
            toggleSidebarMenu: this.toggleSidebarMenu,
            sidebarState: this.state.sidebarState,
            sidebarVisibility: this.handleSidebarVisibility,
            currentLang: this.state.currentLang,
            changeCurrentLang: this.handleCurrentLanguage,
            handleAppOverlay: this.handleAppOverlay,
            appOverlayState: appProps.appOverlay,
            navbarColor: appProps.navbarColor,
            navbarType: appProps.navbarType,
            logoExtended: appProps.logoExtended,
            horizontal: true,
        };

        const footerProps = {
            footerType: appProps.footerType,
            hideScrollToTop: appProps.hideScrollToTop,
        };

        const customizerProps = {
            customizerState: appProps.isOpenCustomizer,
            handleCustomizer: this.handleCustomizer,
            changeMode: this.props.changeMode,
            changeNavbar: this.props.changeNavbarColor,
            changeNavbarType: this.props.changeNavbarType,
            changeFooterType: this.props.changeFooterType,
            changeNavbarColor: this.props.changeNavbarColor,
            changeMenuTheme: this.props.changeMenuColor,
            changeAccentColor: this.props.changeAccentColor, // add accent color function
            collapseSidebar: this.props.collapseSidebar,
            hideScrollToTop: this.props.hideScrollToTop,
            activeMode: appProps.theme,
            activeNavbar: appProps.navbarColor,
            navbarType: appProps.navbarType,
            footerType: appProps.footerType,
            menuTheme: appProps.menuTheme,
            accentColor: appProps.accentColor,
            scrollToTop: appProps.hideScrollToTop,
            sidebarState: appProps.collapsedSidebar,
            changeDirection: this.props.changeDirection,
            changeMenuLayout: this.props.changeMenuLayout,
            changeLayout: this.props.changeLayout,
            // handleAppOverlayClick: this.handleAppOverlayClick,
            changeOverlayState: this.props.changeOverlayState,
            changeCustomizerState: this.props.changeCustomizerState,
        };

        return (
            <div
                className={classnames(
                    `wrapper horizontal-layout theme-${appProps.menuTheme}`,
                    {
                        "menu-collapsed":
                            this.state.collapsedContent === true &&
                            this.state.width >= 1200,
                        "fixed-footer": appProps.footerType === "sticky",
                        "navbar-static": appProps.navbarType === "static",
                        "navbar-sticky": appProps.navbarType === "sticky",
                        "navbar-floating": appProps.navbarType === "floating",
                        "navbar-hidden": appProps.navbarType === "hidden",
                        "theme-primary": !menuThemeArr.includes(
                            appProps.menuTheme,
                        ),
                    },
                )}
            >
                <Navbar {...navbarProps} />
                <Sidebar {...sidebarProps} />
                <div
                    className={classnames("app-content content", {
                        "show-overlay": appProps.appOverlay === true,
                    })}
                    // style={{paddingTop:
                    // 	appProps.navbarType === "sticky"? this.state.width < 1355? "11.5rem": "9.5rem":
                    // 	appProps.navbarType === "floating"? this.state.width < 1340? "12rem":"9.75rem":
                    // 	""}}
                    onClick={this.handleAppOverlayClick}
                >
                    <Navbar
                        horizontal
                        scrolling={this.state.scroll > 50}
                        toggleSidebarMenu={this.toggleSidebarMenu}
                        sidebarState={this.state.sidebarState}
                        sidebarVisibility={this.handleSidebarVisibility}
                        currentLang={this.state.currentLang}
                        changeCurrentLang={this.handleCurrentLanguage}
                        handleAppOverlay={this.handleAppOverlay}
                        appOverlayState={this.state.appOverlay}
                        navbarColor={appProps.navbarColor}
                        navbarType={appProps.navbarType}
                    />
                    <div className="content-wrapper">{this.props.children}</div>
                </div>
                <Footer {...footerProps} />
                {!appProps.disableCustomizer && (
                    <CustomizerSidebar {...customizerProps} />
                )}
                <div
                    className="sidenav-overlay"
                    onClick={this.handleSidebarVisibility}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        customizer: state.customizer.customizer,
    };
};

export default connect(mapStateToProps, {
    changeMode,
    collapseSidebar,
    changeNavbarColor,
    changeNavbarType,
    changeFooterType,
    changeMenuColor,
    changeAccentColor,
    hideScrollToTop,
    disableCustomizer,
    enableCustomizer,
    changeDirection,
    changeMenuLayout,
    changeLayout,
    changeCustomizerState,
    changeOverlayState,
})(HorizontalLayout);
