/* eslint-disable max-len */
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Media,
    Badge,
    Spinner,
    Col,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Icon from "react-feather";
import { useHexCode } from "hooks/useHexCode";
import useSelector from "hooks/useSelector";
import { DetailsContext, texts } from "./Context";
import { userDetails } from "../../configs/config";

import "assets/scss/components/notifications-dropdown.scss";
import "react-perfect-scrollbar/dist/css/styles.css";

let hasScrolled = false;

const NotificationsDropdown = () => {
    const {
        notifications,
        count,
        getIcon,
        loadNextPage,
        onPreviewed,
        onReadAll,
        pageNum,
        countUnPreviewed,
    } = useContext(DetailsContext);
    const { accentColor } = useSelector((state) => state.customizer.customizer);

    useEffect(() => {
        if (Object.keys(userDetails()).length > 0) {
            let locale = userDetails()?.LanguageDetails?.TwoLetterCode;

            if (locale === "gb") locale = "en-gb";
            if (locale === "br") locale = "pt-br";
            if (locale === "cn") locale = "zh-cn";

            // set moment.js locale
            moment.locale(locale);
        }
    }, []);

    return (
        <UncontrolledDropdown
            tag="li"
            className="dropdown-notification nav-item"
        >
            <DropdownToggle tag="a" className="nav-link nav-link-label">
                <Icon.Bell size={21} />
                <Badge
                    pill
                    style={{
                        backgroundColor: useHexCode(accentColor),
                    }}
                    className="badge-up"
                >
                    {countUnPreviewed}
                </Badge>
            </DropdownToggle>
            <DropdownMenu tag="div" right className="dropdown-menu-media">
                {/* 
                <li className="dropdown-menu-header">
                    <div className="dropdown-header mt-0">
                        <span className="notification-title mt-2">
                            {"Notifications"}
                        </span>
                    </div>
                </li>
                */}
                <PerfectScrollbar
                    className="media-list overflow-hidden position-relative"
                    options={{
                        wheelPropagation: false,
                    }}
                    onScrollY={() => {
                        hasScrolled = true;
                    }}
                    onYReachEnd={() => {
                        if (
                            notifications &&
                            notifications.length &&
                            hasScrolled &&
                            notifications.length < count
                        ) {
                            hasScrolled = false;

                            loadNextPage();
                        }
                    }}
                >
                    {notifications &&
                        notifications.map((notification) => (
                            <Link
                                className={`d-flex justify-content-between lnk-notification ${
                                    !notification.IsPreviewed ? "highlight" : ""
                                }`}
                                key={`${pageNum}-${notification.UserNotificationID}`}
                                to={notification.URL || null}
                                onMouseEnter={(e) => {
                                    const thisTag = e.currentTarget;

                                    if (
                                        thisTag.classList.contains(
                                            "highlight",
                                        ) &&
                                        !thisTag.classList.contains("previewed")
                                    ) {
                                        thisTag.classList.add("previewed");

                                        onPreviewed(
                                            `${notification.RID}.IsPreviewed`,
                                            (response) => {
                                                if (
                                                    response.data?.Successs
                                                        ?.IsSuccess
                                                ) {
                                                    thisTag.classList.remove(
                                                        "highlight",
                                                    );
                                                }
                                            },
                                        );
                                    }
                                }}
                            >
                                <Media
                                    className="d-flex align-items-start"
                                    tag="span"
                                >
                                    <Media left tag="span">
                                        {getIcon(
                                            notification.NotificationTypeID,
                                        )}
                                    </Media>
                                    <Media body tag="span">
                                        <Media
                                            heading
                                            className="media-heading d-flex justify-content-between"
                                            tag="row"
                                        >
                                            <Col className="p-0">
                                                <h6>{notification.Subject}</h6>
                                            </Col>
                                            <Col className="p-0 ml-50 col-4 d-flex justify-content-end">
                                                <small className="tm-fn">
                                                    <time
                                                        className="media-meta"
                                                        dateTime={
                                                            notification.DT_Created
                                                        }
                                                    >
                                                        <span className="mail-date">
                                                            {moment
                                                                .utc(
                                                                    notification.DT_Created,
                                                                )
                                                                .local()
                                                                .fromNow()}
                                                        </span>
                                                    </time>
                                                </small>
                                            </Col>
                                        </Media>
                                        <p className="notification-text mt-1">
                                            {notification.Message}
                                        </p>
                                    </Media>
                                    {/* <Media right tag="span">
                                        <small className="tm-fn">
                                            <time
                                                className="media-meta"
                                                dateTime={
                                                    notification.DT_Created
                                                }
                                            >
                                                <span className="mail-date">
                                                    {moment
                                                        .utc(
                                                            notification.DT_Created,
                                                        )
                                                        .local()
                                                        .fromNow()}
                                                </span>
                                            </time>
                                        </small>
                                    </Media> */}
                                </Media>
                            </Link>
                        ))}
                    {notifications?.length && notifications.length < count ? (
                        <div className="text-center spinner-container">
                            <Spinner color="primary" size="sm" />
                        </div>
                    ) : null}
                </PerfectScrollbar>
                <div className="dropdown-menu-footer">
                    <DropdownItem
                        tag="a"
                        className="p-1 text-center lnk-readall"
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            onReadAll();
                        }}
                    >
                        <span className="align-middle">
                            {texts.BellNotificationsReadAll}
                        </span>
                    </DropdownItem>
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default NotificationsDropdown;
