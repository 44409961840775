export const changeMode = (mode) => {
  return (dispatch) => dispatch({ type: "CHANGE_MODE", mode });
};

export const collapseSidebar = (value) => {
  return (dispatch) => dispatch({ type: "COLLAPSE_SIDEBAR", value });
};

export const changeNavbarColor = (color) => {
  return (dispatch) => dispatch({ type: "CHANGE_NAVBAR_COLOR", color });
};

export const changeNavbarType = (style) => {
  return (dispatch) => dispatch({ type: "CHANGE_NAVBAR_TYPE", style });
};

export const changeDirection = (direction) => {
  return (dispatch) => dispatch({ type: "CHANGE_DIRECTION", direction });
};

export const changeMenuLayout = (menuLayout) => {
  return (dispatch) => dispatch({ type: "CHANGE_MENU_LAYOUT", menuLayout });
};

export const changeLayout = (layout, layoutChanged = false) => {
  return (dispatch) =>
    dispatch({ type: "CHANGE_LAYOUT", layout, layoutChanged });
};

export const changeFooterType = (style) => {
  return (dispatch) => dispatch({ type: "CHANGE_FOOTER_TYPE", style });
};

export const changeMenuColor = (style) => {
  return (dispatch) => dispatch({ type: "CHANGE_MENU_COLOR", style });
};

export const changeAccentColor = (style) => {
  return (dispatch) => dispatch({ type: "CHANGE_ACCENT_COLOR", style });
};

export const hideScrollToTop = (value) => {
  return (dispatch) => dispatch({ type: "HIDE_SCROLL_TO_TOP", value });
};

export const disableCustomizer = () => {
  return (dispatch) => dispatch({ type: "DISABLE_CUSTOMIZER" });
};

export const enableCustomizer = () => {
  return (dispatch) => dispatch({ type: "ENABLE_CUSTOMIZER" });
};

export const changeCustomizerState = (bool) => {
  return (dispatch) =>
    dispatch({ type: "CHANGE_CUSTOMIZER_STATE", isOpenCustomizer: bool });
};

export const changeOverlayState = (bool) => {
  return (dispatch) =>
    dispatch({ type: "CHANGE_OVERLAY_STATE", appOverlay: bool });
};

export const setCustomzier = (data) => {
  return (dispatch) => dispatch({ type: "SET_CUSTOMIZER", payload: data });
};
