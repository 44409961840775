import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import React from "react";
import { connect } from "react-redux";
import { getCardModeColor, getHexColorFromVuexyColor } from "utility/helpers";
import StaticLabel from "components/customs/static-label-wrapper";
import {
    changeJoyrideStatus,
    setJoyrideSteps,
    setTutorialID,
} from "./redux/actions";

interface Props {
    breakpoint: string;
    children: any;
    joyRide: any;
    changeJoyrideStatus: any;
    setTutorialID: any;
    setJoyrideSteps: any;
    customizer: any;
}

interface State {
    run: boolean;
    steps: Step[];
}

const locale = {
    back: <StaticLabel id="ButtonBack" />,
    close: <StaticLabel id="ButtonClose" />,
    last: <StaticLabel id="ButtonLast" />,
    next: <StaticLabel id="ButtonNext" />,
    open: <StaticLabel id="VirtualTourOpen" />,
    skip: <StaticLabel id="VirtualTourSkip" />,
};

class TutorialJoyride extends React.Component<Props, State> {
    private handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            this.props.changeJoyrideStatus(false);
            this.props.setJoyrideSteps([]);

            if (status === STATUS.SKIPPED) {
                this.props.setTutorialID(null);
            }
        }
    };

    render() {
        const { run, steps } = this.props?.joyRide;
        const { theme } = this.props?.customizer;

        return (
            <>
                <Joyride
                    callback={this.handleJoyrideCallback}
                    continuous
                    run={run}
                    scrollToFirstStep
                    showProgress
                    showSkipButton
                    steps={steps}
                    styles={{
                        buttonClose: {
                            display: "none",
                        },
                        tooltip: {
                            backgroundColor: getCardModeColor(
                                this.props.customizer.theme,
                            ),
                        },
                        options: {
                            backgroundColor: "#131624",
                            primaryColor: getHexColorFromVuexyColor(
                                this.props.customizer.accentColor,
                            ),
                            textColor: (theme === "dark" && "white") || "black",
                            zIndex: 1200,
                        },
                        buttonNext: {
                            color: "white !important",
                        },
                    }}
                />
                {this.props.children}
            </>
        );
    }
}

export default connect(
    (state: any) => {
        return {
            joyRide: state.joyRide,
            customizer: state.customizer.customizer,
        };
    },
    {
        changeJoyrideStatus,
        setJoyrideSteps,
        setTutorialID,
    },
)(TutorialJoyride);
