/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
export const responseCallbackConstant = (callBack) => ({
	ResponseSuccessCallback:
		callBack ||
		((responseJSON) => {
			console.log(responseJSON);
		}),
	ResponseFailCallback:
		callBack ||
		((responseJSON) => {
			console.log(responseJSON);
		}),
});
