import React from "react";
import { MEDIA_LIBRARY } from "../configs/config";

const isDefaultClass = (value) =>
    ["primary", "danger", "info", "warning", "dark", "success"].includes(value);

function ensureArray(value) {
    return Array.isArray(value) ? value : [];
}

function ensureObject(value) {
    if (value && typeof value === "object" && !Array.isArray(value)) {
        return value;
    }

    return {};
}

const getInitials = (string) => {
    if (string) {
        const names = string?.split(" ");
        let initials = names?.[0]?.substring(0, 1)?.toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }

        return initials;
    }
};

const getImagePath = ({ type, value }) => {
    if (value) {
        const path = `${MEDIA_LIBRARY}/?${type}=${value}`;

        return path;
    }
};

const RawHTMLComponent = ({ children, className = "" }) => (
    <div
        className={className}
        dangerouslySetInnerHTML={{ __html: children?.replace(/\n/g, "<br />") }}
    />
);

function stripedHtml(html) {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText || "";
}

export {
    isDefaultClass,
    ensureArray,
    ensureObject,
    getInitials,
    getImagePath,
    RawHTMLComponent,
    stripedHtml,
};
