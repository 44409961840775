import StaticLabel from "components/customs/static-label-wrapper";
import useSelector from "hooks/useSelector";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { changeLayout } from "redux/actions/customizer";
import { ContextLayout } from "utility/context/Layout";
import Radio from "../radio/RadioVuexy";

export default function ThemeLayout() {
    const { isFranchiseeSelected } = useSelector(
        (state) => state.customizer.customizer,
    );
    const { state, switchLayout } = useContext(ContextLayout);
    const { activeLayout } = state;
    const dispatch = useDispatch();

    return (
        <div id="customizer-theme-layout" className="theme-layout">
            <h5 className="mt-1">
                <StaticLabel id="ThemeLayoutTitle" />
            </h5>
            <div className="d-inline-block mr-1">
                <Radio
                    label={
                        // "Vertical"
                        <StaticLabel id="RadioVerticalLayout" />
                    }
                    color="primary"
                    checked={
                        activeLayout === "vertical" ||
                        !["vertical", "horizontal"].includes(activeLayout)
                    }
                    name="themeMode"
                    onChange={() => {
                        switchLayout("vertical");

                        dispatch(changeLayout("vertical", true));
                    }}
                />
            </div>
            <div className="d-inline-block">
                <Radio
                    label={
                        // "Horizontal"
                        <StaticLabel id="RadioHorizontalLayout" />
                    }
                    color="primary"
                    checked={activeLayout === "horizontal"}
                    name="themeMode"
                    onChange={() => {
                        switchLayout("horizontal");

                        dispatch(changeLayout("horizontal", true));
                    }}
                />
            </div>
        </div>
    );
}
