import React from "react";
import ScrollToTop from "react-scroll-up";
import { Button } from "reactstrap";
import { ArrowUp } from "react-feather";
import classnames from "classnames";
import Logo from "assets/img/logo/logo.svg";
import { useSelector } from "react-redux";

const Footer = (props) => {
    const footerTypeArr = ["sticky", "static", "hidden"];
    const { logo } = useSelector((state) => state.customizer.customizer);

    return (
        <footer
            className={classnames("footer footer-light", {
                "footer-static":
                    props.footerType === "static" ||
                    !footerTypeArr.includes(props.footerType),
                "d-none": props.footerType === "hidden",
            })}
        >
            {logo && (
                <div
                    className="poweredByContainer"
                    style={{ marginBottom: 10 }}
                >
                    <img
                        title="Powered by Bundr"
                        src={Logo}
                        style={{ height: 25, marginRight: 5 }}
                        className="mb-0"
                        alt=""
                    />
                    <p>{"Powered by Bundr"}</p>
                </div>
            )}
            {props.hideScrollToTop === false ? (
                <ScrollToTop showUnder={160}>
                    <Button color="primary" className="btn-icon scroll-top">
                        <ArrowUp size={15} />
                    </Button>
                </ScrollToTop>
            ) : null}
        </footer>
    );
};

export default Footer;
