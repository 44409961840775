import React, { createContext, useReducer } from "react";

export const CustomizerContext = createContext({});

const defaultState = {
    colorPickerOpened: {
        menuColor: false,
        accentColor: false,
        navbarColor: false,
    },
};

const stateDispatchReducer = (state, action) => {
    switch (action.type) {
        case "SET_MENU_COLOR_PCIKER_OPENED": {
            return {
                colorPickerOpened: {
                    ...state.colorPickerOpened,
                    menuColor: action.payload,
                },
            };
        }

        case "SET_ACCENT_COLOR_PCIKER_OPENED": {
            return {
                colorPickerOpened: {
                    ...state.colorPickerOpened,
                    accentColor: action.payload,
                },
            };
        }

        case "SET_NAVBAR_COLOR_PCIKER_OPENED": {
            return {
                colorPickerOpened: {
                    ...state.colorPickerOpened,
                    navbarColor: action.payload,
                },
            };
        }

        case "SET_COLOR_PICKER_OPENED": {
            return {
                colorPickerOpened: action.payload,
            };
        }

        default: {
            if (action.key) {
                return {
                    ...state,
                    [action.key]: action.payload,
                };
            }

            return state;
        }
    }
};

export default function CustomizerProvider({ children }) {
    const [state, stateDispatch] = useReducer(
        stateDispatchReducer,
        JSON.parse(JSON.stringify(defaultState)),
    );

    const payload = {
        state,
        stateDispatch,
    };

    return (
        <CustomizerContext.Provider value={payload}>
            {children}
        </CustomizerContext.Provider>
    );
}
