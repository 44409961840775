import {
    CustomBasicInput,
    CustomButtonRipple,
    CustomSpinner,
} from "components/customs";
import CustomAvatarComponent from "components/customs/avatar/CustomAvatarComponent";
import StaticLabel from "components/customs/static-label-wrapper";
import {
    accessToken,
    parentAccessToken,
    parentRefreshToken,
    partnerDetails,
    refreshToken,
    userDetails,
    userMenu,
    userRoles,
} from "configs/config";
import React, { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    Badge,
    Card,
    CardBody,
    Col,
    Media,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import { genericQuery, Toast } from "utility/helpers";
import { getInitials } from "utility/utilities";
import { LoginContextProps } from "views/pages/authentication/LoginTypes";
import { LoginContext } from "views/pages/authentication/Login";
import CustomPagination from "components/tables/CustomMaterialPagination";
import { CustomMaterialPaginationProps } from "components/tables/types/CustomMaterialPagination";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { setParentTokens } from "redux/mixin/global";
import useSelector from "hooks/useSelector";
import i18nTexts from "i18n/en-us.json";
import {
    AccountLoginParams,
    MultiAccountModalProps,
    MultiAccountProps,
} from "./MultiAccountTypes";

export default function MultiAccount({
    showOnlyAccounts = false,
    token = parentAccessToken(),
    refresh = parentRefreshToken(),
    overrideSameAccountLogin = false,
}: MultiAccountProps) {
    const [data, setData] = useState([]);
    const [isLoading, setIsloading] = useState(true);

    const intl = useIntl();

    const [searchValue, setSearchValue] = useState<undefined | string>("");
    const dispatch = useDispatch();

    const accentColor = useSelector(
        (state) => state.customizer.customizer.accentColor,
    );

    const { ParentRefreshToken, ParentAccessToken } = useSelector(
        ({ globalConfig }) => globalConfig,
    );

    const { loggedInUser } = useContext(LoginContext) as LoginContextProps;

    const [tableOption, setTableOption] = useState({
        rowsPerPage: 10,
        rowCount: 0,
        currentPage: 1,
    });

    const paginationProps: CustomMaterialPaginationProps = {
        ...tableOption,
        disableRowsPerPage: true,
        disableRecordCount: true,
        onChangePage: (page: number) => {
            setTableOption({
                ...tableOption,
                currentPage: page,
            });
        },
        onChangeRowsPerPage: (rowCount: number) => {
            setTableOption({
                ...tableOption,
                rowsPerPage: rowCount,
            });
        },
    };

    const handleAccountLogin = ({ UserID }: AccountLoginParams) => {
        const formData = new FormData();
        formData.append("ChildID", UserID);

        const config = {
            Url: "/login_as",
            Method: "POST",
            Token: ParentAccessToken || token,
            Refresh: ParentRefreshToken || refresh,
            Data: {
                requestjson: formData,
            },
            ResponseSuccessCallback: (responseJSON: any) => {
                const { Data, Status } = responseJSON.data;
                const { IsSuccess, Message } = Status;

                if (IsSuccess) {
                    const {
                        UserDetails,
                        PartnerDetails,
                        Menu,
                        Roles,
                        ChildAccessToken,
                        ChildRefreshToken,
                    } = Data;

                    if (
                        (loggedInUser?.UserID || userDetails()?.LoginID) ===
                            UserDetails.LoginID &&
                        !overrideSameAccountLogin
                    ) {
                        Toast({
                            type: 4,
                            content: (
                                <StaticLabel id="MultiAccountForbiddenSameLoginAlert" />
                            ),
                        });
                    } else {
                        dispatch(
                            setParentTokens({
                                AccessToken: {
                                    Token:
                                        ParentAccessToken ||
                                        parentRefreshToken() ||
                                        accessToken(),
                                    UpdateLocalStorage: true,
                                },
                                RefreshToken: {
                                    Token:
                                        ParentRefreshToken ||
                                        parentRefreshToken() ||
                                        refreshToken(),
                                    UpdateLocalStorage: true,
                                },
                            }),
                        );
                        userDetails(UserDetails);

                        partnerDetails(PartnerDetails);
                        userMenu(Menu);
                        userRoles(Roles);
                        accessToken(ChildAccessToken);
                        refreshToken(ChildRefreshToken);
                        window.location.href = "/";
                    }
                } else {
                    Toast({ type: 4, content: Message });
                }
            },
            ResponseFailCallback: (responseJSON: any) => {
                console.log(responseJSON);
            },
        };

        genericQuery(config);
    };

    const getData = () => {
        const config = {
            Token: ParentAccessToken || token,
            Refresh: ParentRefreshToken || refresh,
            Method: "GET",
            Data: {
                requestjson: {
                    Module: "system_user_partnerslist",
                    Parameters: {
                        PageNumber: tableOption.currentPage,
                        SearchValue: searchValue,
                        CurrentRowsPerPage: tableOption.rowsPerPage,
                    },
                },
            },
            ResponseSuccessCallback: (responseJSON: any) => {
                const { Data, Status } = responseJSON.data;
                const { IsSuccess, Message } = Status;

                if (IsSuccess) {
                    const { List, RecordCount } = Data;

                    setData(List);
                    setTableOption({ ...tableOption, rowCount: RecordCount });
                    setIsloading(false);
                } else {
                    Toast({ type: 4, content: Message });
                }
            },
            ResponseFailCallback: (responseJSON: any) => {
                console.log(responseJSON);
            },
        };

        genericQuery(config);
    };

    let changeSearchTimeoutID: any;

    const handleOnChangeSearch = (e: any) => {
        const eventValue = e?.target?.value;

        if (changeSearchTimeoutID) {
            clearTimeout(changeSearchTimeoutID);
        }

        changeSearchTimeoutID = setTimeout(() => {
            setSearchValue(eventValue.trim());
        }, 1000);
    };

    const isElementHidden = (elementName: string) => {
        const el = document.querySelector<any>(`.${elementName}`)!;

        if (el) {
            return el?.offsetParent === null;
        }

        return false;
    };

    useEffect(() => {
        if (searchValue || searchValue === "") {
            setIsloading(true);
            getData();
        }
    }, [searchValue, tableOption.currentPage]);

    if (showOnlyAccounts) {
        return (
            <>
                <CustomBasicInput
                    placeholder={intl.formatMessage({
                        id: "SearchAccountPlaceholder",
                        defaultMessage: i18nTexts.SearchAccountPlaceholder,
                    })}
                    className="mb-1"
                    onChange={handleOnChangeSearch}
                />
                {isLoading ? (
                    <div className="spinner-container">
                        <CustomSpinner />
                    </div>
                ) : (
                    <div className="multi-account-list custom-scrollbar">
                        {/* <PerfectScrollbar className="multi-account-list"> */}
                        {data?.map((account: any) => (
                            <Media
                                className={classNames(
                                    "multiaccount_account-item",
                                    {
                                        disabled:
                                            account?.UserID ===
                                            userDetails()?.LoginID,
                                    },
                                )}
                                key={account.UserID}
                                onClick={() => {
                                    handleAccountLogin({
                                        UserID: account.UserID,
                                    });
                                }}
                            >
                                <CustomAvatarComponent
                                    className="avatar-rounded"
                                    content={getInitials(
                                        account?.Partner?.Name,
                                    )}
                                />
                                <Media body>
                                    <Row>
                                        <Col>
                                            <h6 className="transaction-title">
                                                {account?.Partner?.Name}
                                            </h6>
                                            <small>
                                                {account?.Partner?.Role?.Name}
                                            </small>
                                        </Col>
                                        <Col
                                            xs="auto"
                                            className="d-flex align-items-center"
                                        >
                                            {account?.Partner
                                                ?.NotificationCount > 0 &&
                                                account?.UserID !==
                                                    userDetails()?.LoginID && (
                                                    <Badge
                                                        pill
                                                        color={accentColor}
                                                    >
                                                        {
                                                            account?.Partner
                                                                ?.NotificationCount
                                                        }
                                                    </Badge>
                                                )}
                                        </Col>
                                    </Row>
                                </Media>
                                <div className="multi-account-user-status">
                                    {account?.UserID ===
                                        userDetails()?.LoginID && (
                                        <StaticLabel id="CurrentlyLoggedInText" />
                                    )}
                                </div>
                            </Media>
                        ))}
                        {/* </PerfectScrollbar> */}
                    </div>
                )}

                <Row className="d-flex justify-content-between align-items-center custom-pagination-container">
                    <Col xs="3" className="multi-account--total-account">
                        <StaticLabel
                            id="MultiAccountTotalLabel"
                            values={{
                                accounts: tableOption.rowCount,
                            }}
                        />
                    </Col>
                    <Col
                        xs={
                            isElementHidden("multi-account--total-account")
                                ? "12"
                                : "9"
                        }
                        className=""
                    >
                        <CustomPagination {...paginationProps} />
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <div className="mt-5 multi-account--container">
            <h2 className="card-title font-weight-bold mb-1">
                <StaticLabel id="LoginMultiAccountHeader" />
            </h2>
            <p className="card-text mb-2">
                <StaticLabel id="LoginMultiAccountSubHeader" />
            </p>
            <Card className="multi-account--card">
                <CardBody className="w-100 multi-account--card-body">
                    <Row className="d-flex row-column justify-content-center multi-account--row">
                        <CustomBasicInput
                            placeholder={intl.formatMessage({
                                id: "SearchAccountPlaceholder",
                                defaultMessage:
                                    i18nTexts.SearchAccountPlaceholder,
                            })}
                            className="mb-1"
                            onChange={handleOnChangeSearch}
                        />
                        {isLoading ? (
                            <div className="spinner-container w-100">
                                <CustomSpinner />
                            </div>
                        ) : (
                            <div className="multi-account-list custom-scrollbar">
                                {/* // <PerfectScrollbar className="multi-account-list"> */}
                                {data?.map((account: any) => (
                                    <Media
                                        className="multiaccount_account-item"
                                        key={account.UserID}
                                        onClick={() => {
                                            handleAccountLogin({
                                                UserID: account.UserID,
                                            });
                                        }}
                                    >
                                        <CustomAvatarComponent
                                            className="avatar-rounded"
                                            content={getInitials(
                                                account?.Partner?.Name,
                                            )}
                                        />
                                        <Media body>
                                            <Row>
                                                <Col>
                                                    <h6>
                                                        {account?.Partner?.Name}
                                                    </h6>
                                                    <small>
                                                        {
                                                            account?.Partner
                                                                ?.Role?.Name
                                                        }
                                                    </small>
                                                </Col>
                                                <Col
                                                    xs="auto"
                                                    className="d-flex align-items-center"
                                                >
                                                    {account?.Partner
                                                        ?.NotificationCount >
                                                        0 && (
                                                        <Badge
                                                            pill
                                                            color={accentColor}
                                                        >
                                                            {
                                                                account?.Partner
                                                                    ?.NotificationCount
                                                            }
                                                        </Badge>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Media>
                                    </Media>
                                ))}
                            </div>
                            //  </PerfectScrollbar>
                        )}

                        <Row className="d-flex justify-content-between align-items-center w-100 custom-pagination-container">
                            <Col
                                xs="3"
                                className="p-0 multi-account--total-account"
                            >
                                <StaticLabel
                                    id="MultiAccountTotalLabel"
                                    values={{
                                        accounts: tableOption.rowCount,
                                    }}
                                />
                            </Col>
                            <Col
                                xs={
                                    isElementHidden(
                                        "multi-account--total-account",
                                    )
                                        ? "12"
                                        : "9"
                                }
                                className="p-0"
                            >
                                <CustomPagination {...paginationProps} />
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-center align-items-center">
                            <CustomButtonRipple
                                className="mr-1 mt-1"
                                onClick={() => {
                                    window.location.href = "/logout";
                                }}
                            >
                                <StaticLabel id="AccountDropdownLogout" />
                            </CustomButtonRipple>
                        </div>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}

export function MultiAccountModal({
    isModalOpen,
    setIsModalOpen,
}: MultiAccountModalProps) {
    return (
        <div>
            {createPortal(
                <Modal
                    className="multi-account-modal"
                    isOpen={isModalOpen}
                    toggle={() => setIsModalOpen(false)}
                >
                    <ModalHeader toggle={() => setIsModalOpen(false)}>
                        <StaticLabel id="MultiAccountModalHeader" />
                    </ModalHeader>
                    <ModalBody>
                        <MultiAccount showOnlyAccounts />
                    </ModalBody>
                </Modal>,
                document.getElementById("root")!,
            )}
        </div>
    );
}
