import { genericQuery } from "utility/helpers";
import { responseCallbackConstant } from "constants/responseCallbackConstant";
import { MODULE } from "constants/urlConstant";
const partnerService = {
    createSupplierBrand: (params) => {
        const config = {
            ...responseCallbackConstant(params.callback),
            Method: "POST",
            Data: {
                requestjson: {
                    Module: "partner_create_supplierbrand",
                    Parameters: params.data,
                },
            },
        };
        const formData = new FormData();

        formData.set("requestjson", JSON.stringify(config.Data.requestjson));
        config.Data = { requestjson: formData };

        genericQuery(config);
    }, getDetails: (params = {}) => {
        const { PartnerID, callback } = params;

        const config = {
            ...responseCallbackConstant(callback),
            Method: "GET",
            Data: {
                requestjson: {
                    Module: MODULE.GET_PARTNER_DETAILS,
                    Parameters: {
                        PartnerID,
                        PartnerTypeID: 2,
                    },
                },
            },
        };

        genericQuery(config);
    }, getUserList: (params = {}) => {
        const { PartnerID, tableOption, callback } = params;

        const config = {
            ...responseCallbackConstant(callback),
            Method: "GET",
            Data: {
                requestjson: {
                    Module: MODULE.GET_PARTNER_USER_LIST,
                    Parameters: {
                        PartnerID,
                        PageNumber: tableOption.page,
                        SearchValue: tableOption.searchValue,
                        CurrentRowsPerPage: tableOption.currentRowsPerPage,
                        Sort: {
                            Field: tableOption.sort.field,
                            Direction: tableOption.sort.direction,
                        },
                    },
                },
            },
        };

        genericQuery(config);
    }, getPriceAgreementDetails: (params = {}) => {
        const { parameter, callback } = params;

        const config = {
            ...responseCallbackConstant(callback),
            Method: "GET",
            Data: {
                requestjson: {
                    Module: "partner_list_agreement",
                    Parameters: parameter,
                },
            },
        };

        genericQuery(config);
    }, createPriceAgreement: (params = {}) => {
        const { parameter, callback } = params;

        const config = {
            ...responseCallbackConstant(callback),
            Method: "GET",
            Data: {
                requestjson: {
                    Module: "partner_create_agreement",
                    Parameters: parameter,
                },
            },
        };

        genericQuery(config);
    }, getPartnerLocation: (params = {}) => {
        const { PartnerID, callback } = params;

        const config = {
            ...responseCallbackConstant(callback),
            Method: "GET",
            Data: {
                requestjson: {
                    Module: "system_form_updatemultiple",
                    Parameters: {
                        ApplicationFunctionID: 39,
                        Permission: "Update",
                        Type: "PartnerLocation",
                        PartnerID,
                    },
                },
            },
        };

        genericQuery(config);
    },
};

export default partnerService;
