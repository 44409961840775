export const API_URL = "/single_api";

export const MODULE = {
    GET_PARTNER_DETAILS: "partner_partners_details",
    UPDATE_PARTNER_DETAILS: "partner_update_partner",
    GET_PARTNER_USER_LIST: "partner_list_partneruser",

    GET_DETAILS_BRAND: "partner_details_brand",
    UPDATE_BRAND_DETAILS: "partner_update_partner",

    GET_NOTIFICATION_MESSAGE_LIST: "notification_listof_messages",
    GET_NOTIFICATION_USER_LIST: "notification_listof_users",
    CREATE_NOTIFICATION_MESSAGE: "notification_create_message",

    MARK_AS_READ_MESSAGES: "notification_markasread_messages",

    // GROUPS
    CREATE_NOTIFICATION_GROUP: "notification_create_group",
    EDIT_NOTIFICATION_GROUP: "notification_update_group",
    CREATE_NOTIFICATION_PARTNER_GROUP: "notification_create_partnergroup",
    GET_NOTIFICATION_GROUP_DETAILS: "notification_notifications_groupdetail",
    GET_ALL_NOTIFICATION_GROUPS: "notification_listof_notificationgroups",

    GET_NOTIFICATION_PARTNER_LIST: "notification_partner_list",
    GET_NOTIFICATIONS_SUMMARY: "notification_notifications_summary",

    GET_NOTIFICATION_FORM: "notification_create_messageform",

    NOTIFICATION_CREATE_RECIPIENT: "notification_create_messagerecipient",
    NOTIFICATION_DELETE_RECIPIENT: "notification_delete_messagerecipient",

    NOTIFICATION_TRASH_MESSAGES: "notification_trash_messages",
    NOTIFICATION_DELETE_MESSAGES: "notification_delete_messages",

    // ACCOUNT GROUPS
    UPDATE_ACCOUNT_GROUP_DETAILS: "partner_update_group",
    CREATE_ACCOUNT_GROUP: "partner_create_group",
    ATTACH_PARTNER_TO_ACCOUNT_GROUP: "partner_create_groupmember",
};
