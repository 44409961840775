import React from "react";
import i18n from "i18n/en-us.json";
import { FormattedMessage } from "react-intl";
import { LooseObject } from "utility/custom-interfaces";

interface Props {
    id: string;
    values?: Object;
    className?: string;
}

function StaticLabel(props: Props) {
    const { id, values, className } = props;

    return (
        <span className={className}>
            <FormattedMessage
                id={id}
                values={values}
                defaultMessage={(i18n as LooseObject)[id]}
            />
        </span>
    );
}

export default StaticLabel;
